<h1 class="text-center mt-2">Gestion des contacts</h1>
<div class="user-table mt-4">
  <div class="d-flex justify-content-between align-items-baseline">
    <form [formGroup]="filterFormGroup" class="p-3">
      <div>
        <mat-form-field class="view-filter mr-2" appearance="fill">
          <mat-label>Rechercher</mat-label>
          <input matInput placeholder="Nom" [(ngModel)]="search" [ngModelOptions]="{standalone: true}" (keyup)="applyFilter($event)"/>
        </mat-form-field>

        <mat-form-field class="mr-2" appearance="fill">
          <mat-label>Fonction</mat-label>
          <mat-select placeholder="Fonction" [formControl]="fonctionFormControl" multiple (selectionChange)="contactFilter()">
            <mat-select-filter [array]="fonctionsList" (filteredReturn)="filteredFonction = $event"></mat-select-filter>
            <mat-option *ngFor="let fonction of filteredFonction" [value]="fonction">
              {{fonction}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="mr-2" appearance="fill">
          <mat-label>Groupe</mat-label>
          <mat-select placeholder="Groupe" [formControl]="groupeFormControl" multiple (selectionChange)="contactFilter()">
            <mat-select-filter [array]="groupsList" (filteredReturn)="filteredGroup = $event"></mat-select-filter>
            <mat-option *ngFor="let group of filteredGroup" [value]="group">
              {{group}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="mr-2" appearance="fill">
          <mat-label>Concession</mat-label>
          <mat-select placeholder="Concession" [formControl]="concessionFormControl" multiple (selectionChange)="contactFilter()">
            <mat-select-filter [array]="dealershipList" (filteredReturn)="filteredDealership = $event"></mat-select-filter>
            <mat-option *ngFor="let dealership of filteredDealership" [value]="dealership">
              {{dealership}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </form>
    <div class="d-flex">
      <div class="btn1">
        <button class="btn btn-dark d-flex" (click)="exportExcel()">
          <mat-icon class="mr-1">file_copy</mat-icon> <span>Export</span>
        </button>
      </div>
      <div class="btn1">
        <button id="btn-add-concession" class="mr-3 btn btn-dark" (click)="SelectChangeHandlerCreer()">
          <mat-icon>add_circle</mat-icon> Contact
        </button>
      </div>
    </div>
  </div>
</div>
<div class="example-container">
  <table mat-table [dataSource]="dataSource" class="user-table mat-elevation-z8">

    <ng-container matColumnDef="nom">
      <th mat-header-cell *matHeaderCellDef style="width: 50px" class="pointer" (click)="sortName()"> Nom </th>
      <td mat-cell *matCellDef="let element"> {{element.nom}} </td>
    </ng-container>

    <ng-container matColumnDef="prenom">
      <th mat-header-cell *matHeaderCellDef> Prénom </th>
      <td mat-cell *matCellDef="let element"> {{element.prenom}} </td>
    </ng-container>

    <ng-container matColumnDef="fonction">
      <th mat-header-cell *matHeaderCellDef> Fonction </th>
      <td mat-cell *matCellDef="let element"> {{element.fonction}} </td>
    </ng-container>

    <ng-container matColumnDef="telephone_fixe">
      <th mat-header-cell *matHeaderCellDef  style="width: 150px"> Ligne directe </th>
      <td mat-cell *matCellDef="let element"> {{element.telephone_fixe}} </td>
    </ng-container>

    <ng-container matColumnDef="telephone_portable">
      <th mat-header-cell *matHeaderCellDef> Téléphone portable </th>
      <td mat-cell *matCellDef="let element"> {{element.telephone_portable}} </td>
    </ng-container>

    <ng-container matColumnDef="mail">
      <th mat-header-cell *matHeaderCellDef> E-mail </th>
      <td mat-cell *matCellDef="let element"> {{element.mail}} </td>
    </ng-container>

    <ng-container matColumnDef="commentaire">
      <th mat-header-cell *matHeaderCellDef> Commentaires </th>
      <td mat-cell *matCellDef="let element">
        <mat-icon *ngIf="element.commentaire" class="concession-icon pointer" (click)="onClickComment(element.commentaire)">comment</mat-icon>
        <mat-icon *ngIf="!element.commentaire" class="concession-icon">close</mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="concession">
      <th mat-header-cell *matHeaderCellDef> Concessions </th>
      <td mat-cell *matCellDef="let element">
        <div *ngIf="element.concessions">
          <mat-icon [matTooltip]="element.concessions | tooltipList" matTooltipClass="tooltip-list" class="concession-icon">public</mat-icon>
        </div>
        <mat-icon *ngIf="!element.concessions" class="concession-icon">close</mat-icon>
      </td>
    </ng-container>


    <ng-container matColumnDef="modifier">
      <th mat-header-cell *matHeaderCellDef> Modifier </th>
      <td mat-cell *matCellDef="let element">
        <button class="m-2 btn btn-round-40 btn-dark" (click)="SelectChangeHandlerModifier(element.id_contact)">
          <mat-icon>edit</mat-icon>
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="supprimer">
      <th mat-header-cell *matHeaderCellDef> Supprimer </th>
      <td mat-cell *matCellDef="let element">
        <button class="m-2 btn btn-round-40 btn-dark" (click)="deleteContact(element.id_contact)">
          <mat-icon>delete</mat-icon>
        </button>
      </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <mat-paginator [pageSizeOptions]="getPageSizeOptions()"
                 (click)="displayPaginatorAllOption()"
                 (page)="pageChange()"
                 showFirstLastButtons
                 aria-label="Sélectionner la page">
  </mat-paginator>
</div>
<app-spinner *ngIf="this.appComponent.isLoading"></app-spinner>
