<div class="page-content">
  <h1 class="text-center mt-2">Gestion des concessions (DORA)</h1>

  <div class="mt-4 p-3">
    <!-- Filters -->
    <div class="d-flex justify-content-between align-items-center px-3 py-2">
      <div>
        <mat-form-field style="width: 250px">
          <mat-label>Cherchez une concession</mat-label>
          <input [(ngModel)]="search" matInput (keyup)="applyFilter($event)">
        </mat-form-field>
        <mat-form-field class="view-filter ml-3 w-auto" style="height: 45px">
          <mat-label>Groupe</mat-label>
          <mat-select [(ngModel)]="selectedGroup" (selectionChange)="filterData()"  appearance="fill">
            <input type="text" matInput placeholder="Chercher un groupe" class="form-control p-3" [(ngModel)]="inputSearchGroup" (keyup)="onFilterSearchGroup($event)">
            <mat-option value="all">Tous</mat-option>
            <mat-option *ngFor="let group of filteredGroups" [value]="group">{{ group }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="view-filter ml-3 w-auto" style="height: 45px">
          <mat-label>Marque</mat-label>
          <mat-select [(ngModel)]="selectedBrand" (selectionChange)="filterData()"  appearance="fill">
            <mat-option value="all">Toutes</mat-option>
            <mat-option *ngFor="let brand of brands" [value]="brand">{{ brand }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="view-filter ml-3 w-auto" style="height: 45px">
          <mat-label>Active</mat-label>
          <mat-select [(ngModel)]="selectedActive" (selectionChange)="filterData()" appearance="fill">
            <mat-option value="all">Toutes</mat-option>
            <mat-option [value]="true">Oui</mat-option>
            <mat-option [value]="false">Non</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div (click)="getData()" class="d-flex align-items-center"><mat-icon matTooltip="Rafraichir la donnée">refresh</mat-icon></div>
    </div>
    <!-- Table -->
    <table mat-table [dataSource]="dataSource" [hidden]="dataSource.filteredData.length == 0" matSort class="mat-elevation-z8">
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 80px;"> ID </th>
        <td mat-cell *matCellDef="let element"> {{element.id}} </td>
      </ng-container>
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Nom </th>
        <td mat-cell *matCellDef="let element"> <i class="fas fa-building mr-3"></i>{{element.name}} </td>
      </ng-container>
      <ng-container matColumnDef="group">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Groupe </th>
        <td mat-cell *matCellDef="let element"> {{element.group}} </td>
      </ng-container>
      <ng-container matColumnDef="city">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Ville </th>
        <td mat-cell *matCellDef="let element"> {{element.city}} </td>
      </ng-container>
      <ng-container matColumnDef="codeSite">
        <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 100px;"> Code Site </th>
        <td mat-cell *matCellDef="let element"> {{element.codeSite}} </td>
      </ng-container>
      <ng-container matColumnDef="active">
        <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 80px;"> Active </th>
        <td mat-cell *matCellDef="let element">
          <mat-icon *ngIf="element.active" class="text-success">check</mat-icon>
          <mat-icon *ngIf="!element.active" class="text-danger">close</mat-icon>
        </td>
      </ng-container>
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef style="width: 120px;"> Actions </th>
        <td mat-cell *matCellDef="let element">
          <button class="m-2 btn btn-round-40 btn-dark" (click)="onEdit(element.id)"><mat-icon>edit</mat-icon></button>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator [hidden]="dataSource.filteredData.length == 0" [pageSizeOptions]="getPageSizeOptions()" (click)="displayPaginatorAllOption()" (page)="pageChange()" showFirstLastButtons aria-label="Sélectionner la page"></mat-paginator>

    <div class="w-100 font-weight-bold d-flex align-items-center justify-content-center mt-5" *ngIf="dataSource.filteredData.length == 0">
      <mat-icon class="mr-2">info</mat-icon>
      Aucun résultat à afficher
    </div>
  </div>

</div>
