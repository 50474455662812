import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {DataConfigurationConcessionService} from '../utils/data-services/data-configuration-concession.service';
import {MatTableDataSource} from '@angular/material/table';
import {Router} from '@angular/router';
import {animate, state, style, transition, trigger} from '@angular/animations';
import moment from 'moment';
import {MatPaginator} from '@angular/material/paginator';
import {DataConcessionService} from '../utils/data-services/dataConcession.service';
import {DataCreationConcessionService} from '../utils/data-services/dataCreation-concession.service';
import {MatSort, Sort} from '@angular/material/sort';
import {LiveAnnouncer} from '@angular/cdk/a11y';

@Component({
  selector: 'app-gestion-configuration-concession',
  templateUrl: './gestion-configuration-concession.component.html',
  styleUrls: ['./gestion-configuration-concession.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class GestionConfigurationConcessionComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  dataSource = new MatTableDataSource();
  // tslint:disable-next-line:variable-name
  constructor(public dataService: DataConfigurationConcessionService, public dataConcessionService: DataConcessionService, private dataGroupService: DataCreationConcessionService, private route: Router, private _liveAnnouncer: LiveAnnouncer) {  }
  displayedColumns: string[] = ['concessionId', 'nomConcession', 'Version ISPI Admin Client', 'Version IPS', 'Version KAI', 'Version DCOM Core', 'Version DCOM ISPA 4', 'Version DCOM Aftersales', 'Version DCOM Appointment', 'Version DCOM Sales', 'Version APAS', 'Version soft admin', 'Date limite de licence', 'update', 'expand'];
  expandedElement: any | null;
  configurationArray = [];
  displayedConfiguration = ['Version ISPI Admin Client', 'Version IPS', 'Version KAI', 'Version DCOM Core', 'Version DCOM ISPA 4', 'Version DCOM Aftersales', 'Version DCOM Appointment', 'Version DCOM Sales', 'Version APAS', 'Version soft admin', 'Date limite de licence'];
  expandedConfiguration = [];
  releaseArray = [];
  configurationTypeArray = [];
  originalDataSource = null;
  selectedGroup = null;
  groupsArray = {
    filtered: null,
    default: null
  };
  typesArray = [];
  selectedType = null;
  search: string;
  activeDealership = [];

  ngOnInit() {
    this.selectedGroup = localStorage.getItem('filterDealershipConfigurationGroup') != null ? localStorage.getItem('filterDealershipConfigurationGroup') : null;
    this.selectedType = localStorage.getItem('filterDealershipConfigurationType') != null ? localStorage.getItem('filterDealershipConfigurationType') : null;
    this.activeDealership = JSON.parse(localStorage.getItem('filterDealershipConfigurationActive'));
    this.search = localStorage.getItem('dealershipConfigurationFilter') != null ? localStorage.getItem('dealershipConfigurationFilter') : '';
    this.getConfiguration();
    this.getCurrentRelease();
    this.getConfigurationType();
    this.getGroup();
    this.getDealershipType();
    this.paginator.pageIndex = localStorage.getItem('pageIndexDealershipConfiguration') != null ? parseInt(localStorage.getItem('pageIndexDealershipConfiguration')) : null;
    this.paginator.pageSize = localStorage.getItem('pageSizeDealershipConfiguration') != null ? parseInt(localStorage.getItem('pageSizeDealershipConfiguration')) : null;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  getDealershipConfiguration(): void {
    this.dataService.getDealershipConfiguration().subscribe(response => {
      this.dataSource.data = Object.values(response);
      this.originalDataSource = Object.values(response);
      this.filterDealershipConfiguration();
      this.dataSource.filter = this.search.trim().toLowerCase();
    });
  }
  getCurrentRelease(): void {
    this.dataService.getCurrentRelease().subscribe(response => {
      this.getDealershipConfiguration();
      this.releaseArray = response.sort((a, b) => a.id > b.id ? -1 : 1);
    });
  }
  getConfiguration(): void {
    this.dataService.getConfiguration().subscribe(response => {
      this.configurationArray = response;
      for (const configuration of response) {
        if (!configuration.name.includes('Version')) {
          this.expandedConfiguration.push(configuration);
        }
      }
    });
  }
  getConfigurationType() {
    this.dataService.getConfigurationType().subscribe(response => {
      this.configurationTypeArray = response;
    });
  }
  onUpdate(idConcession) {
    this.route.navigate(['../gestion-configuration-concession/' + idConcession]);
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    localStorage.setItem('dealershipConfigurationFilter', filterValue);
    this.dataSource.filter = filterValue.trim().toLowerCase();
    this.filterDealershipConfiguration();
  }
  objectToArray(object: object): any {
    const array = [];
    let i = 0;
    for (const value of Object.values(object)) {
      array[Object.keys(object)[i]] = value;
      i++;
    }
    return array;
  }
  objectToValues(object) {
    return Object.values(object);
  }
  objectToKey(object) {
    return Object.keys(object);
  }
  camelCaseToTitleCase(text: string): string {
    const result = text.replace('Version', '');
    return result.charAt(0).toUpperCase() + result.slice(1);
  }
  filterConfiguration(array, text) {
    let filterArray = array.filter(conf => (conf.type == text  && conf.displayForm));
    // filterArray.push(array.dms);
    return filterArray;
  }
  isUpToDate(element, column) {
    let upToDate = 'norelease';
    if (column === 'Date limite de licence') {
      const dateFromNow = moment().diff(element[column], 'days');
      if (dateFromNow > 0) { return 'outdated'; }
      if (dateFromNow === 0) { return 'orange'; }
      if (dateFromNow >= -7) { return 'yellow'; }
      if (dateFromNow < -7) { return 'uptodate'; }
    }
    for (const release of this.releaseArray) {
      if ((element.dealershipType === release.type || release.type === 'soft-nrg') && (Object.keys(release.versions).includes(column) && element[column])) {
        const releaseVersion = Number('0.' + release.versions[column][0].replaceAll('.', ''));
        const dealershipVersion = Number('0.' + element[column].replaceAll('.', ''));
        if (releaseVersion === dealershipVersion) {
          upToDate = 'uptodate';
        }
        if (releaseVersion < dealershipVersion) { upToDate = 'updated'; }
        if (releaseVersion > dealershipVersion) { upToDate = 'outdated'; }
      }
    }
    return upToDate;
  }
  filterDealershipConfiguration(): void {
    localStorage.setItem('filterDealershipConfigurationType', this.selectedType);
    localStorage.setItem('filterDealershipConfigurationGroup', this.selectedGroup);
    localStorage.setItem('filterDealershipConfigurationActive', JSON.stringify(this.activeDealership));
    if ((this.selectedType == 'all' || this.selectedType == 'null') && (this.selectedGroup == 'all' || this.selectedGroup == 'null')) {
      this.dataSource.data = this.originalDataSource;
    } else if ((this.selectedType != 'all' || this.selectedType != 'null') && (this.selectedGroup == 'all' || this.selectedGroup == 'null')) {
      this.dataSource.data = this.originalDataSource.filter((e: any) => e.dealership_type_id == this.selectedType);
    } else if ((this.selectedType == 'all' || this.selectedType == 'null') && (this.selectedGroup != 'all' && this.selectedGroup != 'null)')) {
      this.dataSource.data = this.originalDataSource.filter((e: any) => e.groupe_id == this.selectedGroup);
    } else {
      this.dataSource.data = this.originalDataSource.filter((e: any) => e.groupe_id == this.selectedGroup && e.dealership_type_id == this.selectedType);
    }
    if (this.activeDealership.length > 0) {
      this.dataSource.data = this.dataSource.data.filter((e: any) => this.activeDealership.includes(e.is_active));
    }
    this.countRelease();
  }
  canDisplayHeaderRelease(release): any {
    if ([1, 2].includes(release.typeId) && ['1', '2'].includes(this.selectedType.toString())) {
      return release.typeId != this.selectedType;
    } else {
      return false;
    }
  }
  countRelease(): any {
    for (const release of this.releaseArray) {
      if ([1, 2].includes(release.typeId)) {
        const typeCount = this.dataSource.filteredData.filter(x => x['dealership_type_id'] == release.typeId).length;
        for (const product of this.objectToKey(release.versions)) {
          const releaseCount = this.dataSource.filteredData.filter(x => (x[product] ? Number('0.' + x[product].replaceAll('.', '')) : -1) >= Number('0.' + release.versions[product][0].replaceAll('.', '')) && x['dealership_type_id'] == release.typeId).length;
          release.versions[product][1] = {
            count: releaseCount,
            total: typeCount
          };
        }
      } else if (release.typeId == 3) {
        const typeCount = this.dataSource.filteredData.filter(x => x['Version APAS'] != null && x['Version APAS'] != '').length;
        const releaseCount = this.dataSource.filteredData.filter(x => (x['Version APAS'] ? Number('0.' + x['Version APAS'].replaceAll('.', '')) : -1) >= Number('0.' + release.versions['Version APAS'][0].replaceAll('.', ''))).length;
        release.versions['Version APAS'][1] = {
          count: releaseCount,
          total: typeCount
        };
      }
    }

  }
  filterGroup(event): void {
    const filterValue = (event.target as HTMLInputElement).value.toLowerCase();
    this.groupsArray.filtered = this.groupsArray.default.filter((e: any) => e.nom.toLowerCase().includes(filterValue));
  }
  getDealershipType() {
    this.dataConcessionService.getDealershipType().subscribe(result => {
      this.typesArray = result;
    });
  }
  getGroup() {
    this.dataGroupService.getGroups().subscribe(groups => {
      this.groupsArray.default = groups.records;
      this.groupsArray.filtered = groups.records;
    });
  }
  focusSearch(type: string): void {
    setTimeout(() => {
      if (document.getElementById('select-search-' + type) != null) {
        // @ts-ignore
        document.getElementById('select-search-' + type).focus();
      }
    });
  }
  getPageSizeOptions(): number[] {
    if (this.dataSource && this.dataSource.data.length > 100 && this.dataSource.paginator) {
      return [20, 50, 100,  this.dataSource.paginator.length];
    } else {
      return [20, 50, 100];
    }
  }
  displayPaginatorAllOption() {
    const matOptions = document.getElementsByClassName('mat-option-text');
    // @ts-ignore
    for (const options of matOptions) {
      options.innerHTML = options.innerHTML == this.dataSource.data.length ? 'Tous' : options.innerHTML;
    }
  }
  pageChange() {
    localStorage.setItem('pageIndexDealershipConfiguration', String(this.paginator.pageIndex));
    localStorage.setItem('pageSizeDealershipConfiguration', String(this.paginator.pageSize));
  }
  comparer(o1: any, o2: any): boolean {
    // if possible compare by object's name, and not by reference.
    return o1 && o2 ? o1 == o2 : o2 == o2;
  }
  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }
  displayDmsEditor(array, field) {
    if (array.dms != null) {
      switch (field) {
        case 'DMS':
          for (const dms of array.dms) {
            if (dms.endDate == null) {return dms.dms; }
          }
          break;
        case 'Editeur DMS':
          for (const dms of array.dms) {
            if (dms.endDate == null) {return dms.editor; }
          }
          break;
      }
    }
  }
}
