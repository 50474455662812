import {Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material';
import swal from "sweetalert2";
import {Router} from '@angular/router';
import {DataNotificationService} from '../utils/data-services/dataNotification.service';
import {not} from 'rxjs/internal-compatibility';
import {MatPaginator} from "@angular/material/paginator";

@Component({
  selector: 'app-gestion-notification',
  templateUrl: './gestion-notification.component.html',
  styleUrls: ['./gestion-notification.component.scss']
})
export class GestionNotificationComponent implements OnInit {
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  displayedColumns: string[] = [
    'action',
    'title',
    'text',
    'type',
    'target',
    'isActive',
    'edit',
    'delete'
  ];
  targets = [];
  types = [
    {label: 'Problème', name: 'danger', color: 'red'},
    {label: 'Divers', name: 'warning', color: 'orange'},
    {label: 'Information', name: 'info', color: 'blue'},
    {label: 'Résolution', name: 'success', color: 'green'},
  ];
  isDataSourceExists = false;
  allData = [];
  dataSource = new MatTableDataSource<any>();
  isActiveOnlyVisible = true;
  selectedItems = [];
  search: any;

  constructor(
    private route: Router,
    private dataNotificationService: DataNotificationService,
  ) { }

  ngOnInit() {
    this.dataNotificationService.getNotificationTargets().subscribe(res => {
      this.targets = res;
      this.dataNotificationService.getNotification().subscribe(notifications => {
        for (const notification of notifications.records) {
          const target = this.targets.find(x => x.slug === notification.target);
          const type = this.types.find(x => x.name === notification.type);
          notification.color = type.color;
          notification.type = type ? type.label : '-';
          notification.target = target ? target.label : '-';
        }
        this.allData = notifications.records;
        this.dataSource.data = this.allData.filter(x => x.is_active);
        this.search = localStorage.getItem('notificationFilter') != null ? localStorage.getItem('notificationFilter') : '';
        this.dataSource.filter = this.search.trim().toLowerCase();
        this.paginator.pageIndex = localStorage.getItem('pageIndexNotification') != null ? parseInt(localStorage.getItem('pageIndexNotification')) : null;
        this.paginator.pageSize = localStorage.getItem('pageSizeNotification') != null ? parseInt(localStorage.getItem('pageSizeNotification')) : null;
        this.dataSource.paginator = this.paginator;
      });
    });
  }
  onSelectItem(id): any {
    if (this.selectedItems.includes(id)) {
      this.selectedItems.splice(this.selectedItems.indexOf(id));
    } else {
      this.selectedItems.push(id);
    }
  }
  onMultipleDelete(): any {
    swal.fire({
      title: 'Attention!',
      text: 'Êtes-vous sûr de vouloir supprimer?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'Annuler'
    }).then((result) => {
      if (result.value) {
        let i = 1;
        for (const item of this.selectedItems) {
          this.dataNotificationService.deleteNotification(item).subscribe(data => {
            i++;
            if (i == this.selectedItems.length) {
              swal.fire('Les notifications ont bien été supprimée!', '', 'success').then(res => {
                window.location.reload();
              });
            }
          }, error => {
            swal.fire({
              title: 'Erreur',
              text: 'Un problème est survenu lors de la suppression.',
              type: 'error',
              confirmButtonText: 'Ok'
            });
          });
        }

      }
    });
  }
  onMultipleDeactivate(): any {
    swal.fire({
      title: 'Attention!',
      text: 'Êtes-vous sûr de vouloir désactiver?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'Annuler'
    }).then((result) => {
      if (result.value) {
        let i = 1;
        for (const item of this.selectedItems) {
          const body = {is_active: 0};
          this.dataNotificationService.updateNotification(body, item).subscribe(data => {
            i++;
            if (i == this.selectedItems.length) {
              swal.fire('Les notifications ont bien été désactivées!', '', 'success').then(res => {
                window.location.reload();
              });
            }
          }, error => {
            swal.fire({
              title: 'Erreur',
              text: 'Un problème est survenu lors de la désactivation.',
              type: 'error',
              confirmButtonText: 'Ok'
            });
          });
        }

      }
    });
  }
  displayActives(): any {
    this.isActiveOnlyVisible = !this.isActiveOnlyVisible
    if (this.isActiveOnlyVisible) {
      this.dataSource.data = this.allData.filter(x => x.is_active);
    } else {
      this.dataSource.data = this.allData;
    }
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    localStorage.setItem('notificationFilter', filterValue);
    this.dataSource.filter = filterValue.trim();
  }
  editNotification(id) {
    this.route.navigate(['/modification-notification/' + id]);
  }
  onDelete(id) {
    swal.fire({
      title: 'Attention!',
      text: 'Êtes-vous sûr de vouloir supprimer?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'Annuler'
    }).then((result) => {
      if (result.value) {
        this.dataNotificationService.deleteNotification(id).subscribe(data => {
          swal.fire('La notification a bien été supprimée!', '', 'success').then(res => {
            window.location.reload();
          });
        }, error => {
          swal.fire({
            title: 'Erreur',
            text: 'Un problème est survenu lors de la suppression.',
            type: 'error',
            confirmButtonText: 'Ok'
          });
        });
      }
    });
  }
  getPageSizeOptions(): number[] {
    if (this.dataSource && this.dataSource.data.length > 100 && this.dataSource.paginator) {
      return [20, 50, 100,  this.dataSource.paginator.length];
    } else {
      return [20, 50, 100];
    }
  }
  displayPaginatorAllOption() {
    const matOptions = document.getElementsByClassName('mat-option-text');
    // @ts-ignore
    for (const options of matOptions) {
      options.innerHTML = options.innerHTML == this.dataSource.data.length ? 'Tous' : options.innerHTML;
    }
  }
  pageChange() {
    localStorage.setItem('pageIndexNotification', String(this.paginator.pageIndex));
    localStorage.setItem('pageSizeNotification', String(this.paginator.pageSize));
  }
}
