import { Component, OnInit } from '@angular/core';
import {DataInteractionService} from '../../utils/data-services/dataInteraction.service';
import {Router} from '@angular/router';
import {DataCreationConcessionService} from '../../utils/data-services/dataCreation-concession.service';
import swal from 'sweetalert2';
import {DataContactService} from '../../utils/data-services/dataContact.service';
import {DatePipe} from '@angular/common';
import {DataConcessionService} from "../../utils/data-services/dataConcession.service";

@Component({
  selector: 'app-creation-concession',
  templateUrl: './creation-concession.component.html',
  styleUrls: ['./creation-concession.component.css']
})
export class CreationConcessionComponent implements OnInit {
  concession = {
    concession_id: sessionStorage.getItem('autoIncrement'),
    nom_concession: '',
    ville: '',
    ville_principale: '',
    rue: '',
    code_postal: null,
    fax: 'NC',
    tel: '',
    email: 'NC',
    pays: '',
    slug: '',
    site_web: 'NC',
    raison_sociale: '',
    code_ej: null,
    code_site: null,
    outlet: null,
    district: null,
    groupe_id: null,
    longitude: null,
    latitude: null,
    has_kpi_kidi: false,
    has_soft_screen: false,
    has_auto_treatment: false,
    nb_days_min_auto_treatment: 4,
    certification_start_at : '01-15',
    last_user_certification_at : this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss'),
    dealership_type_id: null
  };
  brand: any;
  variableProduit: any;
  n = -1;
  dropdownGroupSettings = {};
  dropdownGroupList = [];
  groupSelected = [];
  dropdownBrandSettings = {};
  dropdownBrandList = [];
  brandSelected = [];
  typesArray = [];
  formError = false;

  // tslint:disable-next-line:max-line-length
  constructor(
    private dataContactService: DataContactService,
    private dataCreationConcessionService: DataCreationConcessionService,
    private dataInteractionService: DataInteractionService,
    private dataConcessionService: DataConcessionService,
    private route: Router,
    private datePipe: DatePipe
  ) { }

  ngOnInit() {
    this.dropdownGroupSettings = {
      singleSelection: true,
      idField: 'groupe_id',
      textField: 'nom',
      itemsShowLimit: 1,
      allowSearchFilter: true,
      enableCheckAll: false
    };
    this.dropdownBrandSettings = {
      singleSelection: false,
      idField: 'marque_id',
      textField: 'nom',
      itemsShowLimit: 1,
      allowSearchFilter: true,
      enableCheckAll: false
    };
    this.getGroups();
    this.getBrands();
    this.getDealershipType();
  }

  SelectChangeHandlerAnnuler() {
    this.route.navigate(['../gestion-concession']);
  }

  getGroups(callback?): void {
    this.dataCreationConcessionService.getGroups().subscribe(groups => {
      this.dropdownGroupList = groups.records;
      this.groupSelected = [this.dropdownGroupList[0]];
      this.concession.groupe_id = this.dropdownGroupList[0] ? this.dropdownGroupList[0].groupe_id : null;
      if (callback)
        callback();
    });
  }
  getBrands() {
    this.dataCreationConcessionService.getBrands().subscribe(brands => {
      this.dropdownBrandList = brands.records.filter(x => x.marque_id != 1);
    });
  }
  getDealershipType() {
    this.dataConcessionService.getDealershipType().subscribe(result => {
      this.typesArray = result;
    });
  }
  checkForm(): any {
    this.formError = false;
    const notRequiredFields = ['latitude', 'longitude', 'district', 'fax', 'has_kpi_kidi', 'has_soft_screen', 'has_auto_treatment'];
    for (const item of Object.entries(this.concession)) {
      if (!notRequiredFields.includes(item[0]) && (item[1] == '' || item[1] == null)) {
        this.formError = true;
      }
    }
    if (this.groupSelected.length == 0) {
      this.formError = true;
    }
    return !this.formError;
  }
  /***
   * Create a new concession
   ***/
  SelectChangeHandlerCreer() {
    this.formError = false;
    this.concession.groupe_id = parseInt(this.concession.groupe_id);
    const body = JSON.stringify(this.concession);
    if (!this.formError) {
      this.dataCreationConcessionService.createConcession(body).subscribe( concessionId => {
        // Sauron
        this.dataCreationConcessionService.callSauron(concessionId).subscribe(res => {
          // Set error message
          let errorMessage = '<strong>Rapport d\'erreur Sauron</strong>';
          if (res.ispacc.error) {errorMessage += '<div><strong>ispacc</strong> : ' + res.ispacc.message + '</div>'}
          if (res.altibaseV2.error) {errorMessage += '<div><strong>altibaseV2</strong> : ' + res.altibaseV2.message + '</div>'}
          if (res.softPlanning.error) {errorMessage += '<div><strong>softPlanning</strong> : ' + res.softPlanning.message + '</div>'}
          if (res.dora.error) {errorMessage += '<div><strong>dora</strong> : ' + res.dora.message + '</div>'}
          if (res.soPlanning.error) {errorMessage += '<div><strong>soPlanning</strong> : ' + res.soPlanning.message + '</div>'}
          if (res.bmwScoringDistant.error) {errorMessage += '<div><strong>bmwScoringDistant</strong> : ' + res.bmwScoringDistant.message + '</div>'}
          // check error
          if (errorMessage != '<strong>Rapport d\'erreur Sauron</strong>') {
            swal.fire({
              title: 'Un problème est survenu lors du dispatch sauron!',
              html: errorMessage,
              confirmButtonText: 'Ok'
            });
          }
        });
        swal.fire({
          title: 'Success!',
          text: 'La concession a bien été créée',
          type: 'success',
          confirmButtonText: 'Ok'
        });
        for (const brand of this.brandSelected) {
          const bodyMarque = {concession_id: concessionId, marque_id: parseInt(brand)};
          this.dataCreationConcessionService.addConcessionMarque(bodyMarque).subscribe(res => {
          }, error => {
            swal.fire({
              title: 'Erreur!',
              text: 'Un problème est survenu lors du mapping pngo',
              type: 'error',
              confirmButtonText: 'Ok'
            });
          });
        }
        this.route.navigate(['../gestion-concession']);
      }, error => {
        swal.fire({
          title: 'Erreur!',
          text: 'Un problème est survenu lors de la création de la concession',
          type: 'error',
          confirmButtonText: 'Ok'
        });
      });
    }
  }
  onGroupSelect(item: any) {
    this.groupSelected = [item];
    this.concession.groupe_id = item.groupe_id;
  }

  onGroupDeselect(item: any) {
    this.groupSelected = [this.dropdownGroupList[0]];
    this.concession.groupe_id = 1;
  }
  onBrandSelect(item: any) {
    this.brandSelected.push(item.marque_id);
  }
  onBrandDeselect(item: any) {
    for (let i = 0; i < this.brandSelected.length; i++) {
      if (this.brandSelected[i] === item.marque_id) {
        this.brandSelected.splice(i, 1);
      }
    }
  }
  onClickCreateGroup() {
    swal.fire({
      title: 'Entrer le nom du nouveau groupe',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'valider',
      showLoaderOnConfirm: true,
      preConfirm: (groupName) => {
        const body = {nom: groupName};
        this.dataCreationConcessionService.createGroup(body).subscribe(res => {
          this.getGroups(() => {
            this.groupSelected = this.dropdownGroupList.filter(x => x.groupe_id === res);
          });
          this.concession.groupe_id = res;
          swal.fire({
            title: 'Success!',
            text: 'Le groupe ' + groupName + ' a bien été créé',
            type: 'success',
            confirmButtonText: 'Ok'
          });
        }, error => {
          swal.fire({
            title: 'Erreur!',
            text: 'Un problème est survenu lors de la création du groupe',
            type: 'error',
            confirmButtonText: 'Ok'
          });
        });
      },
      allowOutsideClick: () => !swal.isLoading()
    });
  }
}
