<h2>Configuration {{dealershipConfiguration.nomConcession}}</h2>
<div class="wrapper-config">
  <form>
    <div *ngFor="let configurationType of configurationTypeArray">
      <div *ngIf="configurationType.name != 'DMS'">
        <h4 class="mt-3">{{configurationType.name}}</h4>
        <div class="form-row mt-3">
          <div *ngFor="let configuration of filterConfiguration(configurationArray, configurationType.name)" class="form-group" [ngClass]="{'col-md-3': configuration.field == 'input' || configuration.field == 'select' || configuration.field == 'date', 'col-md-6': configuration.field == 'textarea'}" style="width: fit-content">
            <label for="{{configuration.name}}">{{camelCaseToTitleCase(configuration.name)}}</label>
            <input *ngIf="configuration.field == 'input'" [(ngModel)]="dealershipConfiguration[configuration.name]" class="form-control" id="{{configuration.name}}" name="{{configuration.name}}">
            <textarea *ngIf="configuration.field == 'textarea'" [(ngModel)]="dealershipConfiguration[configuration.name]" class="form-control" id="{{configuration.name}}" name="{{configuration.name}}"></textarea>
            <select *ngIf="configuration.field == 'select'" [(ngModel)]="dealershipConfiguration[configuration.name]" class="form-control" id="{{configuration.name}}" name="{{configuration.name}}">
              <option *ngFor="let option of getSelectOptions(configuration.id)" [value]="option.name">{{option.name}}</option>
            </select>
            <input *ngIf="configuration.field == 'date'" [(ngModel)]="dealershipConfiguration[configuration.name]" class="form-control" id="{{configuration.name}}" name="{{configuration.name}}" type="date">
          </div>
        </div>
      </div>
      <div *ngIf="configurationType.name == 'DMS'">
        <h4 class="mt-3">{{configurationType.name}}</h4>
        <div class="form-row mt-3" *ngFor="let dms of dealershipConfiguration.dms; let index = index">
          <div class="form-group mr-2">
            <label *ngIf="index === 0" for="editor{{index}}">Éditeur</label>
            <select [(ngModel)]="dealershipConfiguration.dms[index].editor" class="form-control" id="editor{{index}}" name="editor{{index}}" (change)="changeSelectedValue(index)" [compareWith]="comparer">
              <option *ngFor="let option of getSelectOptions(29, index)" [value]="option.name">{{option.name}}</option>
            </select>
          </div>
          <div class="form-group mr-2">
            <label *ngIf="index === 0" for="dms{{index}}">DMS</label>
            <select [(ngModel)]="dealershipConfiguration.dms[index].dms" class="form-control" id="dms{{index}}" name="dms{{index}}">
              <option *ngFor="let option of getSelectOptions(21, index)" [value]="option.name">{{option.name}}</option>
            </select>
          </div>
          <div class="form-group mr-2">
            <label *ngIf="index === 0" for="startDate{{index}}">Date de début</label>
            <input [(ngModel)]="dealershipConfiguration.dms[index].startDate" class="form-control" type="date" id="startDate{{index}}" name="startDate{{index}}">
          </div>
          <div class="form-group mr-2">
            <label *ngIf="index === 0" for="endDate{{index}}">Date de fin</label>
            <input [(ngModel)]="dealershipConfiguration.dms[index].endDate" class="form-control" type="date" id="endDate{{index}}" name="endDate{{index}}">
          </div>
          <div class="pointer text-danger d-flex align-items-center" [ngClass]="{'mt-3': index === 0, 'mb-3': index !== 0}" (click)="deleteDms(index)" matTooltip="Supprimer"><mat-icon >delete</mat-icon></div>
        </div>
        <button class="form-group btn btn-dark" (click)="addDms()" id="btn-add-concession">
          <mat-icon>add_circle</mat-icon>
        </button>
      </div>
    </div>
    <div class="btn-group mt-3 mb-5">
      <div>
        <button (click)="this.selectChangeHandlerCreer()" class="btn btn100px btn-dark p-2 mr-2">Modifier</button>
      </div>
      <div>
        <button (click)="this.selectHandlerCancel()" class="btn btn-light btn100px border-dark p-2">Annuler</button>
      </div>
    </div>
  </form>
</div>
