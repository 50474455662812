import { Injectable } from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {catchError, retry} from 'rxjs/operators';
import { Observable } from 'rxjs';
import {HandleErrorService} from '../handleError.service';

@Injectable({
  providedIn: 'root'
})
export class DataModificationConcessionService extends HandleErrorService{


  // tslint:disable-next-line:max-line-length
  updateConcession(body, concessionId): Observable<HttpErrorResponse> {
    // const api = environment.globalUrlDomaine + '/records/concession/' + concessionId;
    const api = environment.globalApiUrl + 'api/concession/update/' + concessionId;
    // tslint:disable-next-line:max-line-length
    return this.http.put<any>(api, body, environment.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  setConcessionInactive(concessionId): Observable<HttpErrorResponse> {
    const api = environment.globalApiUrl + 'api/concession/set-inactive/' + concessionId;
    return this.http.get<any>(api, environment.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  setConcessionActive(concessionId): Observable<HttpErrorResponse> {
    const api = environment.globalApiUrl + 'api/concession/set-active/' + concessionId;
    return this.http.get<any>(api, environment.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getConcessionById(concessionId) {
    const url = environment.globalApiUrl + 'api/concession/get-by-id/' + concessionId;
    return this.http.get<any>(url, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  getProduit() {
    const apiProduit = environment.globalUrlDomaine + '/records/produit';
    return this.http.get<any>(apiProduit, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  getProduitPourConcession(arg: any) {
    // tslint:disable-next-line:max-line-length
    const api = environment.globalUrlDomaine + '/records/concession_produit?filter=concession_id,eq,' + arg + '&include=produit_id';
    return this.http.get<any>(api, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  getConcessionProduit(arg: any) {
    const apiProduit = environment.globalUrlDomaine + '/records/produit_concession?filter=concession_id,eq,' + arg;
    return this.http.get<any>(apiProduit, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  getIdConcessionProduit(arg1: any, arg2: any) {
    // tslint:disable-next-line:max-line-length
    const api = environment.globalUrlDomaine + '/records/concession_produit?filter=concession_id,eq,' + arg1 + '&filter=produit_id,eq,' + arg2 + '&include=concession_produit_id';
    return this.http.get<any>(api, environment.httpOptions).pipe(
      catchError(this.handleError)
    );

  }

  deleteConcessionProduit(arg: any) {
    const api = environment.globalUrlDomaine + '/records/concession_produit/' + arg;
    return this.http.delete(api, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  addConcessionProduit(arg1: any, arg2: any) {
    const api = environment.globalUrlDomaine + '/records/concession_produit';
    const body = { concession_id: arg1,
      produit_id: arg2,
      version: '0000'
    };
    return this.http.post<any>(api, body, environment.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getConcessionMarque(idConcession) {
    const api = environment.globalUrlDomaine + '/records/concession_marque?filter=concession_id,eq,' + idConcession;
    return this.http.get<any>(api, environment.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  updateVersion(id: any) {
    const api = environment.globalUrlDomaine + '/records/concession_produit/' + id;
    const body = {version: '0000'};
    return this.http.put<any>(api, body, environment.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getValeurPMAJ(arg: any) {
    const api = environment.globalUrlDomaine + '/records/concession_produit_parametre_valeur?filter=concession_id,eq,' + arg + '&filter=produit_id,eq,5&include=nom_parametre,valeur';
    return this.http.get<any>(api, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  callSauron(concessionId) {
    const sauron = environment.globalApiUrl + 'api/sauron/dispatch/concession/' + concessionId;
    return this.http.get<any>(sauron, environment.httpOptionsSGApi).pipe(
      catchError(this.handleError)
    );
  }

  postAutoTreatmentRulesConcession(body) {
    const apiConcession = environment.globalApiUrl + 'api/concession_auto_treatment_rules';
    return this.http.post<any>(apiConcession, body, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  getAutoTreatmentRulesConcession(concessionId) {
    const apiConcession = environment.globalUrlDomaine + '/records/concession_auto_treatment_rules?filter=concession_id,eq,' + concessionId;
    return this.http.get<any>(apiConcession, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  deleteAutoTreatmentRulesConcession(body) {
    const apiConcession = environment.globalApiUrl + 'api/delete_concession_auto_treatment_rules';
    return this.http.post<any>(apiConcession, body, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
}
