import { Component, OnInit } from '@angular/core';
import {DataUtilisateurService} from '../../utils/data-services/dataUtilisateurService';
import {DataConcessionService} from '../../utils/data-services/dataConcession.service';
import {FormBuilder} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import swal from "sweetalert2";
import {errorObject} from 'rxjs/internal-compatibility';

@Component({
  selector: 'app-modification-utilisateur',
  templateUrl: './modification-utilisateur.component.html',
  styleUrls: ['./modification-utilisateur.component.scss']
})
export class ModificationUtilisateurComponent implements OnInit {

  $data =[];

  $concessionArray = [];


  userCreationForm: any;
  currentUserId: number;
  currentUser: any;

  errors = {
    role: false,
    concessions: false,
  };
  concessions = [];
  dropdownConcessionList = [];
  selectedConcession = [];
  dropdownRolesList = [];
  selectedRoles = [];
  userRoles = [];
  dropdownSettings = {};
  roleOptionsString = '';
  allRoles = [];
  grantedRoles =[];
  concessionProductArray = [];


  constructor(
    private dataUtilisateurService: DataUtilisateurService,
    private dataConcessionService: DataConcessionService,
    private formBuilder: FormBuilder,
    private route: Router,
    private activeRoute: ActivatedRoute
  ) {
    this.userCreationForm = this.formBuilder.group({
      id: null,
      username: '',
      lastname: '',
      email: '',
      roles: '',
      password: '',
      concessions: '',
      ispacc_right_level: 0
    });
  }

  ngOnInit() {
    this.currentUserId = Number(this.activeRoute.snapshot.paramMap.get('id'));
    this.getRoles(() => {
      this.dataUtilisateurService.getUserById(this.currentUserId).subscribe(data => {
        this.currentUser = data;
        this.userCreationForm = this.formBuilder.group({
          id: null,
          username: '',
          lastname: '',
          email: '',
          roles: '',
          password: '',
          concessions: '',
          ispacc_right_level: data.ispacc_right_level
        });
        this.roleOptionsString = data.roles.replace(/\"+|\[|\]| /g, '') + ',';
        this.getUserRoles(data.roles);
      });
    });
    this.getDataConcession();
    this.getUserConcessions(this.currentUserId);

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      itemsShowLimit: 1,
      allowSearchFilter: true,
      enableCheckAll: false
    };

  }


  getRoles(callback?): any {
    this.dataUtilisateurService.getRoles().subscribe(res => {
      this.dropdownRolesList = [];
      this.allRoles = res.records;
      this.allRoles.sort((a, b) => a.role_order - b.role_order);
      for (const role of res.records) {
        //this.dropdownRolesList.push({ item_id: role.id, item_text: role.label});
      }
      if (callback) {
        callback();
      }
    });
  }

  getDataConcession(): void {
    this.dataConcessionService.getConcession().subscribe(data => {
      const concessionList = [];
      for (const concession of data.records) {
        concessionList.push({ item_id: concession.concession_id, item_text: concession.nom_concession });
      }
      this.dropdownConcessionList = concessionList;
    });
  }

  hasRoleIspacc(): boolean {
    const roleArray = this.roleOptionsString.split(',');
    return roleArray.includes('ISPACC');
  }

  onRoleSelect(item: any) {
    const role = this.allRoles.filter(x => x.id == item.item_id);
    this.roleOptionsString += (role[0].name + ',');
  }

  onRoleDeselect(item: any) {
    const role = this.allRoles.filter(x => x.id == item.item_id);
    this.roleOptionsString = this.roleOptionsString.replace(role[0].name + ',', '');
  }

  onConcessionSelect(item: any) {
    this.concessions.push(item.item_id);
    this.manageRoleSelection(() => {
      const tempSelectedRole = [];

      for (const role of this.userRoles) {
        if (this.dropdownRolesList.some(x => x.item_id == role.item_id)) {
          tempSelectedRole.push(role);
        }
      }
      this.selectedRoles = tempSelectedRole;
    });
  }

  onConcessionDeselect(item: any) {
    for (let i = 0; i < this.concessions.length; i++) {
      if (this.concessions[i] === item.item_id) {
        this.concessions.splice(i, 1);
      }
    }
    this.manageRoleSelection(() => {

    const tempSelectedRole = [];

    for (const role of this.selectedRoles) {
      if (this.dropdownRolesList.some(x => x.item_id == role.item_id)) {
        tempSelectedRole.push(role)
      }
    }
    this.selectedRoles = tempSelectedRole;
    });
  }

  manageRoleSelection(callback?) {
    const grantedRoles = [];
    const body = {
      concessionArray: this.concessions
    };
    this.dataConcessionService.concessionProducts(body).subscribe(res => {
      this.concessionProductArray = res;
      const tempGrantedRoles = [];
      for (const concession of this.concessionProductArray) {
        for (const product of concession.productLinked) {
          for (const role of product.rolesLinked) {
            tempGrantedRoles.push(role);
          }
        }
      }
      for (const role of this.allRoles) {
        if (tempGrantedRoles.some(x => x.roleId == role.id) || role.id == 7 || role.id == 8 || role.id == 11) {
          grantedRoles.push({
            item_id: role.id,
            item_text: role.label,
          });
        }
      }
      this.dropdownRolesList = this.grantedRoles = grantedRoles;
      if (callback) {
        callback();
      }
    });
  }
  resetOtpSecret(): any {
    const data = {id: this.currentUserId};
    this.dataUtilisateurService.resetSecret(data).subscribe(res => {
      this.currentUser.auth_two_factor_secret = null;
    });
  }

  onSubmit(data) {
    this.errors = {
      role: false,
      concessions: false,
    };
    const capitalize = (s) => {
      if (typeof s !== 'string') return '';
      return s.charAt(0).toUpperCase() + s.slice(1);
    };
    this.roleOptionsString = '';
    for (const role of this.selectedRoles) {
      const currentRole = this.allRoles.find(x => x.id == role.item_id);
      this.roleOptionsString += (currentRole.name + ',');
    }
    data.id = this.currentUserId;
    data.username = capitalize(data.username !== '' ? data.username : this.currentUser.username);
    data.lastname = capitalize(data.lastname !== '' ? data.lastname : this.currentUser.lastname);
    data.email = data.email !== '' ? data.email : this.currentUser.email;
    data.roles = this.roleOptionsString.substring(0, (this.roleOptionsString.length - 1));
    data.concessions = this.concessions;
    if (this.concessions.length === 0) {
      this.errors.concessions = true;
    }
    if (this.roleOptionsString === '') {
      this.errors.role = true;
    }

    if (this.errors.concessions || this.errors.role) {
      swal.fire({
        title: 'Oops...!',
        text: 'Certains champs sont requis',
        type: 'error',
        confirmButtonText: 'Ok'
      });
    } else {
      let adminRole = this.roleOptionsString.includes('ADMIN') ? 'Admin Soft-nrg France' : this.roleOptionsString.includes('CSADMIN') ? 'Admin BMW France' : this.roleOptionsString.includes('ADMINSITE') ? 'Admin local concession' : '';
      if (adminRole !== '') {
        swal.fire({
          title: ' Attention !',
          text: 'Vous venez d\'ajouter le rôle d\'administration ' + adminRole + '. Attention, ces rôles doivent êtres ajoutés avec précaution et uniquement aux utilisateurs autorisés !',
          type: 'error',
          confirmButtonText: 'Ok',
          cancelButtonText: 'Annuler',
          showCancelButton: true,
          showConfirmButton: true,
        }).then((result) => {
          if (!result.dismiss) {
            this.dataUtilisateurService.updateUser(data).subscribe(data);
            setTimeout(() => {
              this.route.navigate(['../gestion-utilisateur']);
            }, 2000);
          }
        });
      }
    }
  }

  getUserRoles(param) {
    const roles = param.replace(/\"+|\[|\]| /g, '').split(',');
    const selectedRoles = [];

    for (const roleUser of roles) {
      const role = this.allRoles.filter(x => x.name == roleUser);
      selectedRoles.push({ item_id: role[0].id, item_text: role[0].label });
    }
    this.userRoles = selectedRoles;
    this.selectedRoles = selectedRoles;
  }

  getUserConcessions(userId) {
    this.concessions = [];
    this.dataConcessionService.getConcessionByUserId(userId).subscribe(data => {
      const selectedConcessions = [];
      const concessions = [];
      let i = 1;

      for (const concession of data.records ) {
        this.concessions.push(concession.concession_id);
        this.dataConcessionService.getConcessionById(concession.concession_id).subscribe(concessionData => {
          if (i < data.records.length) {
            const userConcession = { item_id: concessionData.concession_id, item_text: concessionData.nom_concession };
            selectedConcessions.push(userConcession);
            concessions.push(concessionData.concession_id);
            i++;
          } else {
            const userConcession = { item_id: concessionData.concession_id, item_text: concessionData.nom_concession };
            selectedConcessions.push(userConcession);
            concessions.push(concessionData.concession_id);
            this.selectedConcession = selectedConcessions;
          }
        });
      }
      this.manageRoleSelection();
    });
  }
}
