<h2>Créer une release</h2>
<div class="form-page">
  <form style="width: fit-content">
    <div class="form-row mt-4">
      <div class="form-group col-md-3">
        <label for="name">Titre :</label>
        <input [(ngModel)]="release.name" [ngModelOptions]="{standalone: true}" class="form-control" id="name" name="name" required>
      </div>
      <div class="form-group col-md-5">
        <label for="type">Type :</label>
        <select [(ngModel)]="release.typeId" [ngModelOptions]="{standalone: true}" class="form-control" id="type" name="type" [compareWith]="comparer">
          <option *ngFor="let type of typeArray" [value]="type.id">{{type.name}}</option>
        </select>
      </div>
      <div class="form-group col-md-3">
        <label for="date">Date :</label>
        <input [(ngModel)]="release.date" [ngModelOptions]="{standalone: true}" class="form-control" id="date" name="date" type="date" required>
      </div>
      <div class="form-group col-md-3">
        <label for="currentRelease">Release actuel :</label>
        <mat-checkbox [(ngModel)]="release.currentRelease" [ngModelOptions]="{standalone: true}" id="currentRelease" name="currentRelease" class="ml-2 mt-3"></mat-checkbox>
      </div>
    </div>
    <div class="form-row mt-4">
      <div class="form-group col-md-5">
        <label for="configuration">Application :</label>
        <select [(ngModel)]="releaseVersion.application" [ngModelOptions]="{standalone: true}" class="form-control" id="configuration" name="configuration">
          <option *ngFor="let configuration of configurationArray" [value]="configuration.name">{{configuration.name}}</option>
        </select>
      </div>
      <div class="form-group col-md-3">
        <label for="value">Version :</label>
        <input [(ngModel)]="releaseVersion.value" [ngModelOptions]="{standalone: true}" class="form-control" id="value" name="value" required>
      </div>
      <button [disabled]="!releaseVersion.application || !releaseVersion.value || !release.id" (click)="addVersion()" id="btn-add-concession" class="btn btn-dark" style="height: fit-content; margin-top: 32px">
        <mat-icon>add_circle</mat-icon> Ajouter une version
      </button>
    </div>
    <div *ngFor="let version of release.versions" class="form-row mt-4">
      <div class="form-group col-md-5">
        <select [(ngModel)]="version.application" [ngModelOptions]="{standalone: true}" class="form-control" id="application" name="application" disabled>
          <option *ngFor="let configuration of configurationArray" [value]="configuration.name">{{configuration.name}}</option>
        </select>
      </div>
      <div class="form-group col-md-3">
        <input [(ngModel)]="version.value" [ngModelOptions]="{standalone: true}" class="form-control" id="version" name="version" required>
      </div>

      <mat-checkbox class="ml-1 mt-2 font-weight-bold" [(ngModel)]="version.actif" [ngModelOptions]="{standalone: true}">Suivre la version</mat-checkbox>
      <mat-icon (click)="deleteVersion(version.id)" class="ml-3 mt-2 pointer">delete</mat-icon>

    </div>

    <div class="btn-group mt-3 mb-5">
      <div *ngIf="!release.id">
        <button [disabled]="!release.date || !release.name || !release.typeId" (click)="onCreate()" class="btn btn100px btn-dark p-2 mr-2">Créer</button>
      </div>
      <div *ngIf="release.id">
        <button [disabled]="!release.date || !release.name || !release.typeId" (click)="onOk()" class="btn btn100px btn-dark p-2 mr-2">OK</button>
      </div>
      <div>
        <button (click)="onCancel()" class="btn btn-light btn100px border-dark p-2">Retour</button>
      </div>
    </div>
  </form>
</div>
