import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from "@angular/material/paginator";
import {Router} from "@angular/router";
import {DataCreationConcessionService} from "../utils/data-services/dataCreation-concession.service";
import {DataContactService} from "../utils/data-services/dataContact.service";
import {MatTableDataSource} from "@angular/material/table";
import {DataConfigurationConcessionService} from "../utils/data-services/data-configuration-concession.service";
import swal from "sweetalert2";
import {MatSort, Sort} from "@angular/material/sort";
import {LiveAnnouncer} from "@angular/cdk/a11y";

@Component({
  selector: 'app-gestion-configuration',
  templateUrl: './gestion-configuration.component.html',
  styleUrls: ['./gestion-configuration.component.scss']
})
export class GestionConfigurationComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  variable: any;
  /* Variable list for the Textarea components */
  nom: any;
  selectedSection: any;
  sectionArray = {
    default: null,
    filtered: null
  };
  dataSource = new MatTableDataSource();
  originalDataSource = null;
  // tslint:disable-next-line:variable-name
  constructor( private _liveAnnouncer: LiveAnnouncer, private dataConfiguration: DataConfigurationConcessionService, private route: Router) {  }
  displayedColumns: string[] = ['type', 'name', 'field', 'order', 'displayForm', 'update', 'delete'];
  search: any;

  ngOnInit() {
    this.getConfiguration();
    this.getSelection();
    this.paginator.pageIndex = localStorage.getItem('pageIndexConfiguration') != null ? parseInt(localStorage.getItem('pageIndexConfiguration')) : null;
    this.paginator.pageSize = localStorage.getItem('pageSizeConfiguration') != null ? parseInt(localStorage.getItem('pageSizeConfiguration')) : null;
    this.selectedSection = localStorage.getItem('filterGsetionConfigurationSection')
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  getConfiguration() {
    this.dataConfiguration.getConfiguration().subscribe(result => {
      this.dataSource.data = result;
      this.originalDataSource = result;
      this.search = localStorage.getItem('configurationFilter') != null ? localStorage.getItem('configurationFilter') : '';
      this.dataSource.filter = this.search.trim().toLowerCase();
      this.filterDealershipSection(this.selectedSection);
    });
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    localStorage.setItem('configurationFilter', filterValue);
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  focusSearch(type: string): void {
    setTimeout(() => {
      if (document.getElementById('select-search-' + type) != null) {
        // @ts-ignore
        document.getElementById('select-search-' + type).focus();
      }
    });
  }
  filterDealershipSection(section): void {
    localStorage.setItem('filterGsetionConfigurationSection', section);
    if (section == 'all') {
      this.dataSource.data = this.originalDataSource;
    } else {
      this.dataSource.data = this.originalDataSource.filter((e: any) => e.type == section);
    }
  }
  filterSection(event): void {
    const filterValue = (event.target as HTMLInputElement).value.toLowerCase();
    this.sectionArray.filtered = this.sectionArray.default.filter((e: any) => e.name.toLowerCase().includes(filterValue));
  }
  getSelection(): void {
    this.dataConfiguration.getConfigurationType().subscribe(result => {
      this.sectionArray.default = result;
      this.sectionArray.filtered = result;
    });
  }
  onUpdate(id) {
    this.route.navigate(['/update-configuration/' + id]);
  }
  onCreate() {
    this.route.navigate(['/create-configuration']);
  }
  onDelete(id: any) {
    swal.fire({
      title: 'Supprimer',
      text: 'Voulez-vous supprimer cette configuration',
      confirmButtonText: 'Ok',
      showCancelButton: true,
      cancelButtonText: 'Annuler'
    }).then((result) => {
      if (!result.dismiss) {
        this.dataConfiguration.deleteConfiguration(id).subscribe(() => {
          this.getConfiguration();
        });
      }
    });
  }
  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }
  getPageSizeOptions(): number[] {
    if (this.dataSource && this.dataSource.data.length > 100 && this.dataSource.paginator) {
      return [20, 50, 100,  this.dataSource.paginator.length];
    } else {
      return [20, 50, 100];
    }
  }
  displayPaginatorAllOption() {
    const matOptions = document.getElementsByClassName('mat-option-text');
    // @ts-ignore
    for (const options of matOptions) {
      options.innerHTML = options.innerHTML == this.dataSource.data.length ? 'Tous' : options.innerHTML;
    }
  }
  pageChange() {
    localStorage.setItem('pageIndexConfiguration', String(this.paginator.pageIndex));
    localStorage.setItem('pageSizeConfiguration', String(this.paginator.pageSize));
  }
}
