import { Component, OnInit } from '@angular/core';
import {DataConfigurationConcessionService} from "../../utils/data-services/data-configuration-concession.service";
import {ActivatedRoute, Router} from "@angular/router";
import moment from "moment";

@Component({
  selector: 'app-update-default-configuration',
  templateUrl: './update-default-configuration.component.html',
  styleUrls: ['./update-default-configuration.component.scss']
})
export class UpdateDefaultConfigurationComponent implements OnInit {
  release = {
    id: null,
    date: null,
    name: null,
    typeId: null,
    currentRelease: null,
    versions: []
  };
  releaseVersion = {
    application: null,
    value: null,
    type: 'dealership_configuration',
    releaseId: null,
    actif: false
  };
  configurationArray = [];
  typeArray = [];
  constructor( private dataService: DataConfigurationConcessionService, private router: Router, private activeRoute: ActivatedRoute ) { }

  ngOnInit() {
    this.release.id = Number(this.activeRoute.snapshot.paramMap.get('id'));
    this.getRelease(this.release.id);
    this.getConfiguration();
    this.getType();
  }
  getRelease(releaseId) {
    this.dataService.getReleaseById(releaseId).subscribe(result => {
      this.release = result;
      this.release.date = moment(this.release.date.date).format('yyyy-MM-DD');
    });
  }
  getType() {
    this.dataService.getReleaseType().subscribe(result => {
      this.typeArray = result;
    });
  }
  addVersion() {
    this.releaseVersion.releaseId = this.release.id;
    this.dataService.createDefaultConfiguration(this.releaseVersion).subscribe(result => {
      this.getRelease(this.release.id);
    });
  }
  getConfiguration() {
    this.dataService.getConfiguration().subscribe(result => {
      this.configurationArray = result;
    });
  }
  deleteVersion(id) {
    this.dataService.deleteDefaultConfiguration(id).subscribe(result => {
      this.getRelease(this.release.id);
    });
  }
  comparer(o1: any, o2: any): boolean {
    // if possible compare by object's name, and not by reference.
    return o1 && o2 ? o1 === o2 : o2 === o2;
  }
  onUpdate() {
    this.dataService.updateRelease(this.release).subscribe(result => {
      for (const version of this.release.versions) {
        this.dataService.updateDefaultConfiguration(version, version.id).subscribe();
      }
      this.router.navigate(['/gestion-release']);
    });
  }
  onCancel() {
    this.router.navigate(['/gestion-release']);
  }
}
