import {Component, OnInit, ViewChild} from '@angular/core';
import {DataSmsSubscriptionService} from "../utils/data-services/data-sms-subscription.service";
import {MatTableDataSource} from "@angular/material/table";
import {Router} from "@angular/router";
import {MatPaginator} from "@angular/material/paginator";

@Component({
  selector: 'app-gestion-subscription',
  templateUrl: './gestion-subscription.component.html',
  styleUrls: ['./gestion-subscription.component.scss']
})
export class GestionSubscriptionComponent implements OnInit {
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor(private dataService: DataSmsSubscriptionService, private route: Router) { }
  dataSource: any;
  columnsToDisplay: string[] = ['id', 'color', 'label', 'monthlyCost', 'smsCost', 'update'];
  expandedElement: any | null;
  isLoading = true;
  search: any;
  ngOnInit() {
    this.getSubscriptions();
  }
  getSubscriptions() {
    this.dataService.getSubscriptionsSms().subscribe(result => {
      this.dataSource = new MatTableDataSource(result);
      this.isLoading = false;
      this.search = localStorage.getItem('subscriptionFilter') != null ? localStorage.getItem('subscriptionFilter') : '';
      this.dataSource.filter = this.search.trim().toLowerCase();
      this.paginator.pageIndex = localStorage.getItem('pageIndexSubscription') != null ? parseInt(localStorage.getItem('pageIndexSubscription')) : null;
      this.paginator.pageSize = localStorage.getItem('pageSizeSubscription') != null ? parseInt(localStorage.getItem('pageSizeSubscription')) : null;
      this.dataSource.paginator = this.paginator;
    });
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    localStorage.setItem('subscriptionFilter', filterValue);
    this.dataSource.filter = filterValue.trim();
  }
  SelectChangeHandlerCreer() {
    this.route.navigate(['../create-subscription']);
  }
  SelectChangeHandlerModifier(id) {
    this.route.navigate(['../update-subscription/' + id]);
  }
  getPageSizeOptions(): number[] {
    if (this.dataSource && this.dataSource.data.length > 100 && this.dataSource.paginator) {
      return [20, 50, 100,  this.dataSource.paginator.length];
    } else {
      return [20, 50, 100];
    }
  }
  displayPaginatorAllOption() {
    const matOptions = document.getElementsByClassName('mat-option-text');
    // @ts-ignore
    for (const options of matOptions) {
      options.innerHTML = options.innerHTML == this.dataSource.data.length ? 'Tous' : options.innerHTML;
    }
  }
  pageChange() {
    localStorage.setItem('pageIndexSubscription', String(this.paginator.pageIndex));
    localStorage.setItem('pageSizeSubscription', String(this.paginator.pageSize));
  }
}
