import {Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {Router} from '@angular/router';
import {DataReceptionOptionsMappingService} from '../utils/data-services/data-reception-options-mapping.service';
import {MatPaginator} from "@angular/material/paginator";

@Component({
  selector: 'app-gestion-reception-options-mapping',
  templateUrl: './gestion-reception-options-mapping.component.html',
  styleUrls: ['./gestion-reception-options-mapping.component.scss']
})
export class GestionReceptionOptionsMappingComponent implements OnInit {
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  dataSource = new MatTableDataSource();

  constructor( private dataService: DataReceptionOptionsMappingService, private route: Router ) {}
  displayedColumns: string[] = ['concession', 'uniq_concession_id', 'reception_expires_at_delay', 'reception_not_before_delay', 'restitution_expires_at_delay', 'service', 'modifier'];
  search: any;

  ngOnInit() {
    this.dataService.getReceptionOptionsMapping().subscribe(receptionOptionsMapping => {
      this.dataSource.data = receptionOptionsMapping;
      this.search = localStorage.getItem('receptionMappingFilter') != null ? localStorage.getItem('receptionMappingFilter') : '';
      this.dataSource.filter = this.search.trim().toLowerCase();
      this.paginator.pageIndex = localStorage.getItem('pageIndexOptionMapping') != null ? parseInt(localStorage.getItem('pageIndexOptionMapping')) : null;
      this.paginator.pageSize = localStorage.getItem('pageSizeOptionMapping') != null ? parseInt(localStorage.getItem('pageSizeOptionMapping')) : null;
      this.dataSource.paginator = this.paginator;
    });
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    localStorage.setItem('receptionMappingFilter', filterValue);
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  SelectChangeHandlerCreer() {
    this.route.navigate(['../create-reception-options-mapping']);
  }
  SelectChangeHandlerModifier(receptionOptionsMappingId) {
    this.route.navigate(['../update-reception-options-mapping/' + receptionOptionsMappingId]);
  }
  getPageSizeOptions(): number[] {
    if (this.dataSource && this.dataSource.data.length > 100 && this.dataSource.paginator) {
      return [20, 50, 100,  this.dataSource.paginator.length];
    } else {
      return [20, 50, 100];
    }
  }
  displayPaginatorAllOption() {
    const matOptions = document.getElementsByClassName('mat-option-text');
    // @ts-ignore
    for (const options of matOptions) {
      options.innerHTML = options.innerHTML == this.dataSource.data.length ? 'Tous' : options.innerHTML;
    }
  }
  pageChange() {
    localStorage.setItem('pageIndexOptionMapping', String(this.paginator.pageIndex));
    localStorage.setItem('pageSizeOptionMapping', String(this.paginator.pageSize));
  }
}
