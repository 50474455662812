<div>
  <div><strong>Ajouter un projet</strong></div>

  <div class="w-100 px-3 mt-3">
    <mat-form-field class="w-100">
      <mat-label>Projets</mat-label>
      <mat-select [(ngModel)]="selectedItem"  appearance="fill">
        <input type="text" matInput placeholder="Rechercher" class="form-control p-3" [(ngModel)]="inputSearch" (keyup)="onFilterSearch($event)">
        <mat-option *ngFor="let item of items" [value]="item.id">{{ item.name }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="mt-4 w-100 d-flex justify-content-end">
    <button type="button" class="btn btn-outline-dark mr-2" (click)="onNoClick()">Annuler</button>
    <button type="button" class="btn btn-dark" [disabled]="isLoading" (click)="onAddItem()">Ajouter</button>
  </div>

</div>

<app-spinner *ngIf="isLoading"></app-spinner>
