<div class="main-content-inner mt-2">
  <h1 class="text-center">Suivi des demandes Support</h1>
  <div class="text-center font-weight-bold mb-4" style="font-size: 22px" *ngIf="nbUntreatedDemand > 0"><span class="text-danger">{{nbUntreatedDemand}}</span> demande(s) non-traitée(s)</div>


  <div class="user-table">
    <div class="w-100 d-flex justify-content-between align-items-baseline">
      <div class="mt-3 ml-3 d-flex">
        <label class="switch ml-3 mr-2" matTooltip="Changer le suivi" matTooltipPosition="above">
          <input type="checkbox" [checked]="isUntreatedDemandOnly" (click)="onToggleDemands()">
          <span class="slider round"></span>
        </label>
        <span *ngIf="isUntreatedDemandOnly" class="suivi-psms ml-2">Les demandes traitées sont masquées</span>
        <span *ngIf="!isUntreatedDemandOnly" class="suivi-psms ml-2">Les demandes traitées sont affichées</span>
      </div>
      <div>
        <mat-form-field style="width: 250px; padding: 0 15px" appearance="outline" (click)="focusSearch('dealership')">
          <mat-label><i class="fas fa-search" style="padding: 0 5px"></i> Concession</mat-label>
          <mat-select id="concession-filter" [value]="currentConcession">
            <mat-option value="-1" (click)="onSelectConcession(-1)">Toutes les concessions</mat-option>
            <input id="select-search-dealership" autocomplete="off" placeholder="Rechercher" class="input-search-select form-control custom-search-input" (keyup)="onFilterConcession($event)">
            <mat-option *ngFor="let concession of this.concessionsFilterData" (click)="onSelectConcession(concession.concession_id)" [value]="concession.concession_id">{{concession.nom_concession}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field style="width: 250px; padding: 0 15px" appearance="outline">
          <mat-label><i class="fas fa-search" style="padding: 0 5px"></i> Recherche</mat-label>
          <input matInput (keyup)="applyFilter($event)" autocomplete="off">
        </mat-form-field>
      </div>
    </div>
  </div>

  <div class="m-auto user-table">
    <table mat-table [dataSource]="getDataSource()" class="w-100" matSort multiTemplateDataRows>
      <ng-container matColumnDef="open">
        <th mat-header-cell *matHeaderCellDef style="width: 50px"></th>
        <td mat-cell *matCellDef="let element"><mat-icon class="mr-3 chevron-table" id="chevron-{{element.id}}">keyboard_arrow_down</mat-icon></td>
      </ng-container>
      <ng-container matColumnDef="created_at">
        <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 165px">Date d'envoi </th>
        <td mat-cell *matCellDef="let element"> {{element.created_at | date: 'dd/MM/yyyy à HH:mm'}} </td>
      </ng-container>
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 115px">Type de demande </th>
        <td mat-cell *matCellDef="let element"> {{element.type}} </td>
      </ng-container>
      <ng-container matColumnDef="concession">
        <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 135px">Concession </th>
        <td mat-cell *matCellDef="let element"> {{getConcessionName(element.id_concession)}} </td>
      </ng-container>
      <ng-container matColumnDef="object">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Objet </th>
        <td mat-cell *matCellDef="let element"><div class="pr-3"> {{element.object}} </div></td>
      </ng-container>
      <ng-container matColumnDef="has_phone">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Tel </th>
        <td mat-cell *matCellDef="let element">
          <div class="pr-3" style="color: green" *ngIf="element.has_phone"><mat-icon>done</mat-icon></div>
          <div class="pr-3" style="color: darkred" *ngIf="!element.has_phone"><mat-icon>clear</mat-icon></div>
        </td>
      </ng-container>
      <ng-container matColumnDef="sender">
        <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 135px">Expéditeur </th>
        <td mat-cell *matCellDef="let element"> {{element.sender}} </td>
      </ng-container>
      <ng-container matColumnDef="treated">
        <th mat-header-cell *matHeaderCellDef style="width: 65px"> Traitée </th>
        <td mat-cell *matCellDef="let element">
          <span *ngIf="element.status == 2" style="color: green" matTooltipPosition="above" matTooltip="Traitée le {{element.treated_at | date: 'dd/MM/yyyy'}} par {{element.treated_by}}"><mat-icon>done</mat-icon></span>
          <span *ngIf="element.status == 1" style="color: darkblue" matTooltipPosition="above" matTooltip="En attente"><mat-icon>access_time</mat-icon></span>
          <span *ngIf="element.status == 0" style="color: darkred" matTooltipPosition="above" matTooltip="Non-traitée"><mat-icon>clear</mat-icon></span>
        </td>
      </ng-container>

      <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
      <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length" style="background: #fbfbfb;">
          <div class="example-element-detail"
               [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
            <div class="p-3 d-flex w-100 justify-content-between align-items-end">
              <div class="w-50">
                <div class="ml-4 mb-2">Message :</div>
                <div class="demand-msg w-100 ml-4">{{element.message}}</div>
                <div *ngIf="element.has_phone">
                  <div class="ml-4 mb-2 mt-2 text-danger">L'expéditeur souhaite être recontacté par téléphone :</div>
                  <div class="demand-msg w-100 ml-4 font-weight-bold">{{element.phone | phone}}</div>
                </div>
              </div>
              <div *ngIf="element.status != 2">
                <button [class.status-active]="element.status == 0" [disabled]="element.status == 0" type="button" class="btn btn-dark mr-2" (click)="onTreatDemand(element, 0)">
                  Ouverte
                </button>
                <button [class.status-active]="element.status == 1" [disabled]="element.status == 1" type="button" class="btn btn-dark" (click)="onTreatDemand(element, 1)">
                  En attente
                </button>
              </div>
              <div>
                <button type="button" id="btn-add-param" class="btn btn-dark" *ngIf="element.status != 2" (click)="onTreatDemand(element, 2)">
                  Demande traitée
                </button>
                <div *ngIf="element.status == 2" class="font-weight-bold">
                  Demande traitée le {{element.treated_at | date: 'dd/MM/yyyy'}} <br> par {{element.treated_by}}
                </div>
              </div>
            </div>
            <hr>
            <div class="pl-3 pr-3 pb-3 d-flex">
              <div class="w-50 pl-4" *ngIf="commentList.length > 0">
                <div class="mb-2">Liste des commentaires : </div>
                <ul>
                  <li *ngFor="let comment of commentList">{{comment.created_at | date: 'dd/MM/yyyy'}} - {{comment.comment}}</li>
                </ul>
              </div>
              <div class="w-50 pl-4" *ngIf="commentList.length == 0"></div>
              <div style="width: 100px"></div>
              <div class="w-50 pl-4 d-flex align-items-end justify-content-end" *ngIf="!isCommentOpen">
                <button type="button" class="btn btn-dark mt-3" (click)="isCommentOpen = !isCommentOpen">
                  Rediger un commentaire
                </button>
              </div>
              <div class="w-50 pl-4" *ngIf="isCommentOpen">
                <div class="mb-2">Rédiger un commentaire interne : </div>
                <textarea name="comment" id="comment" class="form-control" cols="20" rows="3" [(ngModel)]="comment"></textarea>
                <div class="w-100">
                  <button type="button" class="btn btn-light mt-3 mr-auto" (click)="isCommentOpen = !isCommentOpen">
                    Annuler
                  </button>
                  <button type="button" class="btn btn-dark mt-3 ml-3 mr-auto" (click)="postComment(element)">
                    Envoyer le commentaire
                  </button>
                </div>
              </div>
            </div>
            <small class="m-2 text-right" *ngIf="element.updated_at" style="color: grey">Dernière modification le : {{element.updated_at | date: 'dd/MM/yyyy à HH:mm'}}</small>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let element; columns: displayedColumns;"
          class="example-element-row" id="row-demand-{{element.id}}"
          [class.example-expanded-row]="expandedElement === element"
          (click)="expandedElement = expandedElement === element ? null : element; onOpenRow(element.id)">
      </tr>
      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
    </table>
    <mat-paginator [pageSizeOptions]="getPageSizeOptions()"
                   (click)="displayPaginatorAllOption()"
                   (page)="pageChange()"
                   showFirstLastButtons
                   aria-label="Sélectionner la page">
    </mat-paginator>
  </div>
  <div *ngIf="getDataSource()?.filteredData.length == 0" style="padding: 15px; text-align: center;"><i class="fas fa-info-circle mr-3"></i>Aucun résultat trouvé.</div>
</div>
