<div class="main-content-inner gestion-statut mt-2">
  <h1>Communications KIDI</h1>
  <div class="header-rules-action d-flex flex-row-reverse w-100 mx-auto">
    <div class="col-12 text-right d-flex flex-column align-items-start">
      <h3 class="pointer d-flex align-items-end" (click)="onClickGeneral()">Communications communes 	<mat-icon class="ml-2">keyboard_arrow_right</mat-icon></h3>
      <small class="ml-5">Communications communes à toutes les concessions</small>
      <div class="w-100 general-communication" [class.h-auto]="isOpenGeneral" [hidden]="!isOpenGeneral">
        <div class="m-3 d-flex justify-content-between w-100">
          <button type="submit" style="height: 40px;" class="btn btn-dark btn100px btn-create p-2 mr-2" [routerLink]="['/creation-communication']">Créer</button>
          <mat-form-field style="width: 150px;margin-right: 20px;">
            <mat-label>Cibles</mat-label>
            <mat-select (valueChange)="onFilterSelectChange()" [formControl]="targetFilter" multiple>
              <mat-option *ngFor="let target of targets" [value]="target">{{target}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <table class="mat-elevation-z8 w-100">
          <thead>
          <th scope="col" class="status-th col-2">Sujet</th>
          <th scope="col" class="status-th col-8">Titre</th>
          <th scope="col" class="status-th col-1">Cible</th>
          <th scope="col" class="status-th col-1">Active</th>
          <th scope="col" class="text-center col-1">Action</th>
          </thead>
          <tbody>
          <tr *ngFor="let comment of generalComments">
            <td>{{comment.subject}}</td>
            <td>{{comment.title}}</td>
            <td>{{comment.targetFr}}</td>
            <td *ngIf="comment.is_active"><mat-icon>done</mat-icon></td>
            <td *ngIf="!comment.is_active"><mat-icon>clear</mat-icon></td>
            <td class="d-flex justify-content-around">
              <div class="pointer" [routerLink]="['/modification-communication/' + comment.id]" matTooltip="Modifier"><mat-icon>edit</mat-icon></div>
              <div class="pointer text-danger" (click)="onDeleteGeneralComment(comment.id)" matTooltip="Supprimer"><mat-icon>delete</mat-icon></div>
              <div class="pointer" (click)="onDuplicateComment(comment)" matTooltip="Dupliquer"><mat-icon>filter_none</mat-icon></div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>

  </div>
  <hr>
  <div class="header-rules-action d-flex flex-row-reverse w-100 mx-auto">
    <div class="col-12 text-right d-flex justify-content-between align-items-center">
      <h3 class="pointer d-flex align-items-end" (click)="onClickPerso()">Communications Personnalisées 	<mat-icon class="ml-2">keyboard_arrow_right</mat-icon></h3>
      <div *ngIf="isOpenPerso">
        <mat-form-field style="width: 150px;margin-right: 20px;">
          <mat-label>Cibles</mat-label>
          <mat-select (valueChange)="onFilterSelectChange()" [formControl]="targetFilter" multiple>
            <mat-option *ngFor="let target of targets" [value]="target">{{target}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field style="width: 250px">
          <mat-label>Recherche</mat-label>
          <input matInput (keyup)="applyFilter($event)">
        </mat-form-field>
      </div>
    </div>
  </div>
  <small class="ml-5">Communications propres à une concession</small>
  <div class="p-4 concession-com" [class.h-auto]="isOpenPerso" [hidden]="!isOpenPerso">
    <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="mat-elevation-z8">
      <ng-container matColumnDef="nom_concession">
        <th mat-header-cell *matHeaderCellDef> Nom de la concession</th>
        <td mat-cell *matCellDef="let element"> <strong>{{element.nom_concession}}</strong> </td>
      </ng-container>
      <ng-container matColumnDef="ville">
        <th mat-header-cell *matHeaderCellDef> Ville </th>
        <td mat-cell *matCellDef="let element"> {{element.ville}} </td>
      </ng-container>
      <ng-container matColumnDef="rue">
        <th mat-header-cell *matHeaderCellDef> Rue </th>
        <td mat-cell *matCellDef="let element"> {{element.rue}} </td>
      </ng-container>
      <ng-container matColumnDef="code_postal">
        <th mat-header-cell *matHeaderCellDef> Code postal </th>
        <td mat-cell *matCellDef="let element"> {{element.code_postal}} </td>
      </ng-container>
      <ng-container matColumnDef="tel">
        <th mat-header-cell *matHeaderCellDef> Téléphone </th>
        <td mat-cell *matCellDef="let element"> {{element.tel}} </td>
      </ng-container>
      <ng-container matColumnDef="open">
        <th mat-header-cell *matHeaderCellDef style="width: 50px"></th>
        <td mat-cell *matCellDef="let element"><mat-icon id="chevron-{{element.concession_id}}">keyboard_arrow_down</mat-icon></td>
      </ng-container>
      <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
      <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
          <div class="example-element-detail flex-column" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
            <div>
              <button type="submit" class="btn btn-dark btn100px btn-create p-2 mr-2" [routerLink]="['/creation-communication-concession/'+element.concession_id]">Créer</button>
            </div>
            <div class="d-flex justify-content-center m-3">
<!--              <div>-->
<!--                <button class="btn btn-light btn100px border-dark btn-create p-2" type="button" routerLink="/gestion-regle">Annuler</button>-->
<!--              </div>-->
              <table class="mat-elevation-z8 w-100" *ngIf="concessionComments.length > 0">
                <thead>
                <th scope="col" class="status-th col-2">Sujet</th>
                <th scope="col" class="status-th col-8">Titre</th>
                <th scope="col" class="status-th col-1">Cible</th>
                <th scope="col" class="status-th col-1">Active</th>
                <th scope="col" class="text-center col-1">Action</th>
                </thead>
                <tbody>
                <tr *ngFor="let comment of concessionComments">
                  <td>{{comment.subject}}</td>
                  <td>{{comment.title}}</td>
                  <td>{{comment.targetFr}}</td>
                  <td *ngIf="comment.is_active"><mat-icon>done</mat-icon></td>
                  <td *ngIf="!comment.is_active"><mat-icon>clear</mat-icon></td>
                  <td class="d-flex justify-content-around">
                    <div class="pointer" [routerLink]="['/modification-communication/' + comment.id]" matTooltip="Modifier"><mat-icon>edit</mat-icon></div>
                    <div class="pointer text-danger" (click)="onDeleteGeneralComment(comment.id)" matTooltip="Supprimer"><mat-icon>delete</mat-icon></div>
                    <div class="pointer" (click)="onDuplicateComment(comment)" matTooltip="Dupliquer"><mat-icon>filter_none</mat-icon></div>
                  </td>
                </tr>
                </tbody>
              </table>
              <div class="text-center" *ngIf="concessionComments.length == 0">Aucun résultat.</div>
            </div>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
      <tr mat-row *matRowDef="let element; columns: columnsToDisplay;"
          class="example-element-row pointer"
          [class.example-expanded-row]="expandedElement === element"
          (click)="expandedElement = expandedElement === element ? null : element; onOpenRow(element.concession_id);">
      </tr>
      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
    </table>
    <mat-paginator [pageSizeOptions]="[15, 25, 50, 100]" showFirstLastButtons class="mb-2"></mat-paginator>

  </div>
  <hr>
  <div class="header-rules-action d-flex flex-row-reverse w-100 mx-auto">
    <div class="col-12 text-right d-flex justify-content-between align-items-center">
      <h3 class="pointer d-flex align-items-end" (click)="onClickAll()">Voir toutes les communications 	<mat-icon class="ml-2">keyboard_arrow_right</mat-icon></h3>


      <div>
        <mat-form-field style="width: 150px;margin-right: 20px;">
          <mat-label>Cibles</mat-label>
          <mat-select (valueChange)="onFilterSelectChange()" [formControl]="targetFilter" multiple>
            <mat-option *ngFor="let target of targets" [value]="target">{{target}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field style="width: 250px" *ngIf="isOpenAll">
          <mat-label>Recherche</mat-label>
          <input matInput (keyup)="applyFilterAll($event)">
        </mat-form-field>
      </div>

    </div>
  </div>
  <small class="ml-5">Toutes les communications</small>
  <div class="header-rules-action d-flex flex-row-reverse w-100 mx-auto">
    <div class="col-12 text-right d-flex flex-column align-items-start">
      <div class="w-100 general-communication" [class.h-auto]="isOpenAll" [hidden]="!isOpenAll">
        <table class="mat-elevation-z8 w-100">
          <thead>
          <th scope="col" class="status-th col-2">Sujet</th>
          <th scope="col" class="status-th col-5">Titre</th>
          <th scope="col" class="status-th col-3">Concession</th>
          <th scope="col" class="status-th col-1">Cible</th>
          <th scope="col" class="status-th col-1">Active</th>
          <th scope="col" class="text-center col-1">Action</th>
          </thead>
          <tbody>
          <tr *ngFor="let comment of allComments.filteredData">
            <td>{{comment.subject}}</td>
            <td>{{comment.title}}</td>
            <td [innerHTML]="comment.concessionName"></td>
            <td>{{comment.targetFr}}</td>
            <td *ngIf="comment.is_active"><mat-icon>done</mat-icon></td>
            <td *ngIf="!comment.is_active"><mat-icon>clear</mat-icon></td>
            <td class="d-flex justify-content-around">
              <div class="pointer" [routerLink]="['/modification-communication/' + comment.id]" matTooltip="Modifier"><mat-icon>edit</mat-icon></div>
              <div class="pointer text-danger" (click)="onDeleteGeneralComment(comment.id)" matTooltip="Supprimer"><mat-icon>delete</mat-icon></div>
              <div class="pointer" (click)="onDuplicateComment(comment)" matTooltip="Dupliquer"><mat-icon>filter_none</mat-icon></div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
