import { Component, OnInit } from '@angular/core';
import {DataConfigurationConcessionService} from "../../utils/data-services/data-configuration-concession.service";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-update-configuration',
  templateUrl: './update-configuration.component.html',
  styleUrls: ['./update-configuration.component.scss']
})
export class UpdateConfigurationComponent implements OnInit {
  id: any;
  configuration = {
    name: null,
    type: null,
    field: null,
    order: null,
    displayForm: null
  };
  configurationValueArray = [];
  valueToUpddate = [];
  configurationValue = '';
  fieldTypeArray = [];
  typeArray = [];
  constructor( private dataService: DataConfigurationConcessionService, private router: Router, private activeRoute: ActivatedRoute ) { }

  ngOnInit() {
    this.id = Number(this.activeRoute.snapshot.paramMap.get('id'));
    this.getConfiguration();
    this.getType();
    this.getFieldType();
    this.getValue();
  }
  getType() {
    this.dataService.getConfigurationType().subscribe(result => {
      this.typeArray = result;
    });
  }
  getConfiguration() {
    this.dataService.getConfigurationById(this.id).subscribe(result => {
      this.configuration = result;
    });
  }
  onUpdate() {
    this.dataService.updateConfiguration(this.configuration, this.id).subscribe(result => {
      this.router.navigate(['/gestion-configuration']);
    });
  }
  onCancel() {
    this.router.navigate(['/gestion-configuration']);
  }
  getFieldType() {
    this.dataService.getFieldType().subscribe(result => {
      this.fieldTypeArray = result;
    });
  }
  comparer(o1: any, o2: any): boolean {
    // if possible compare by object's name, and not by reference.
    return o1 && o2 ? o1 === o2.id : o2 === o2;
  }
  addValue() {
    this.dataService.createConfigurationValue({configuration: this.id, name: this.configurationValue}).subscribe(result => {
      this.getValue();
    });
  }
  updateValue(id, index) {
    this.dataService.updateConfigurationValue({name: this.valueToUpddate[index].name}, id).subscribe(result => {
      this.getValue();
    });
  }
  getValue() {
    this.dataService.getConfigurationValue(this.id).subscribe(result => {
      this.valueToUpddate = result;
      this.configurationValueArray = result;
    });
  }
  deleteValue(id) {
    this.dataService.deleteConfigurationValue(id).subscribe(result => {
      this.getValue();
    });
  }
}
