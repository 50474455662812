import {Component, OnInit, ViewChild} from '@angular/core';
import {DataOperationService} from '../utils/data-services/data-operation.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MatTableDataSource} from '@angular/material/table';
import {DataConcessionService} from '../utils/data-services/dataConcession.service';
import swal from "sweetalert2";
import {MatPaginator} from "@angular/material/paginator";

@Component({
  selector: 'app-gestion-operation',
  templateUrl: './gestion-operation.component.html',
  styleUrls: ['./gestion-operation.component.scss']
})
export class GestionOperationComponent implements OnInit {
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  operationId: any;
  dataSource = new MatTableDataSource();
  concessionName: any;
  constructor(private dataService: DataOperationService, private activeRoute: ActivatedRoute, private route: Router, private dataServiceConcession: DataConcessionService) { }
  displayedColumns: string[] = ['iconOperation', 'bmw_id', 'sofNet_id', 'nom', 'minimum_delay', 'requiredStandartRangeService', 'dynamicTest', 'vt', 'actif', 'ordre', 'blocked', 'commentaireAdditionnel', 'aideAdditionnel', 'type', 'attente_autorise', 'modifier'];
  search: any;

  ngOnInit() {
    this.operationId = Number(this.activeRoute.snapshot.paramMap.get('idConcession'));
    this.getOperation();
    this.getConcessionById();
    this.paginator.pageIndex = localStorage.getItem('pageIndexOperation') != null ? parseInt(localStorage.getItem('pageIndexOperation')) : null;
    this.paginator.pageSize = localStorage.getItem('pageSizeOperation') != null ? parseInt(localStorage.getItem('pageSizeOperation')) : null;
    this.dataSource.paginator = this.paginator;
  }
  getOperation() {
    this.dataService.getOperationByConcession(this.operationId).subscribe(result => {
      this.search = localStorage.getItem('notificationFilter') != null ? localStorage.getItem('notificationFilter') : '';
      this.dataSource.filter = this.search.trim().toLowerCase();
      this.dataSource.data = result;
    });
  }
  getConcessionById() {
    this.dataServiceConcession.getConcessionById(this.operationId).subscribe(result => {
      this.concessionName = result.nom_concession;
    });
  }
  SelectChangeHandlerCreer() {
    this.route.navigate(['../creation-operation/' + this.operationId]);
  }
  SelectChangeHandlerModifier(idOperation) {
    this.route.navigate(['../modification-operation/' + idOperation]);
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    localStorage.setItem('operationFilter', filterValue);
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  onClickMessage(message) {
    swal.fire({
      title: 'Message',
      text: message,
      confirmButtonText: 'Ok'
    });
  }
  getPageSizeOptions(): number[] {
    if (this.dataSource && this.dataSource.data.length > 100 && this.dataSource.paginator) {
      return [20, 50, 100,  this.dataSource.paginator.length];
    } else {
      return [20, 50, 100];
    }
  }
  displayPaginatorAllOption() {
    const matOptions = document.getElementsByClassName('mat-option-text');
    // @ts-ignore
    for (const options of matOptions) {
      options.innerHTML = options.innerHTML == this.dataSource.data.length ? 'Tous' : options.innerHTML;
    }
  }
  pageChange() {
    localStorage.setItem('pageIndexOperation', String(this.paginator.pageIndex));
    localStorage.setItem('pageSizeOperation', String(this.paginator.pageSize));
  }
}
