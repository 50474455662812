import {Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {DataMobiliteService} from "../utils/data-services/data-mobilite.service";
import {ActivatedRoute, Router} from "@angular/router";
import {DataConcessionService} from "../utils/data-services/dataConcession.service";
import swal from "sweetalert2";
import {MatPaginator} from "@angular/material/paginator";

@Component({
  selector: 'app-gestion-mobilite',
  templateUrl: './gestion-mobilite.component.html',
  styleUrls: ['./gestion-mobilite.component.scss']
})
export class GestionMobiliteComponent implements OnInit {
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  mobiliteId: any;
  dataSource = new MatTableDataSource();
  concessionName: any;
  search: any;

  constructor(private dataService: DataMobiliteService, private activeRoute: ActivatedRoute, private route: Router, private dataServiceConcession: DataConcessionService) { }
  displayedColumns: string[] = ['icon', 'sofNet_id', 'nom', 'prix', 'ordre', 'brand_authorized', 'description', 'actif', 'is_external_fleet', 'is_waiting', 'modifier'];

  ngOnInit() {
    this.mobiliteId = Number(this.activeRoute.snapshot.paramMap.get('idConcession'));
    this.getMobilite();
    this.getConcessionById();
    this.paginator.pageIndex = localStorage.getItem('pageIndexMobilite') != null ? parseInt(localStorage.getItem('pageIndexMobilite')) : null;
    this.paginator.pageSize = localStorage.getItem('pageSizeMobilite') != null ? parseInt(localStorage.getItem('pageSizeMobilite')) : null;
    this.dataSource.paginator = this.paginator;
  }
  getMobilite() {
    this.dataService.getMobiliteByConcession(this.mobiliteId).subscribe(result => {
      this.dataSource.data = result;
      this.search = localStorage.getItem('mobiliteFilter') != null ? localStorage.getItem('mobiliteFilter') : '';
      this.dataSource.filter = this.search.trim().toLowerCase();
    });
  }
  getConcessionById() {
    this.dataServiceConcession.getConcessionById(this.mobiliteId).subscribe(result => {
      this.concessionName = result.nom_concession;
    });
  }
  SelectChangeHandlerCreer() {
    this.route.navigate(['../create-mobilite/' + this.mobiliteId]);
  }
  SelectChangeHandlerModifier(idMobilite) {
    this.route.navigate(['../update-mobilite/' + idMobilite]);
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    localStorage.setItem('mobiliteFilter', filterValue);
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  onClickMessage(message) {
    swal.fire({
      title: 'Message',
      text: message,
      confirmButtonText: 'Ok'
    });
  }
  getPageSizeOptions(): number[] {
    if (this.dataSource && this.dataSource.data.length > 100 && this.dataSource.paginator) {
      return [20, 50, 100,  this.dataSource.paginator.length];
    } else {
      return [20, 50, 100];
    }
  }
  displayPaginatorAllOption() {
    const matOptions = document.getElementsByClassName('mat-option-text');
    // @ts-ignore
    for (const options of matOptions) {
      options.innerHTML = options.innerHTML == this.dataSource.data.length ? 'Tous' : options.innerHTML;
    }
  }
  pageChange() {
    localStorage.setItem('pageIndexMobilite', String(this.paginator.pageIndex));
    localStorage.setItem('pageSizeMobilite', String(this.paginator.pageSize));
  }
}
