<div class="page-content" id="modification-utilisateur">
  <h2 class="text-center mt-2">Modification d'un utilisateur (DORA)</h2>

  <div class="mt-4 p-3 d-flex flex-wrap justify-content-center">
    <div class="list-container p-3 m-3">
      <div class="mb-4"><strong>Informations sur l'utilisateur</strong></div>
      <div class="w-100" *ngIf="data">
        <mat-form-field class="view-filter w-100">
          <mat-label>Nom *</mat-label>
          <input type="text" matInput [(ngModel)]="nameForm" placeholder="Nom *">
        </mat-form-field>
        <mat-form-field class="view-filter w-100">
          <mat-label>Login *</mat-label>
          <input type="text" matInput [(ngModel)]="loginForm" placeholder="Login *">
        </mat-form-field>
        <mat-form-field class="view-filter w-100">
          <span>
            <i class="fas fa-user-alt-slash ml-2" *ngIf="rightsForm == '0'" style="width: 30px"></i>
            <i class="fas fa-user ml-2" *ngIf="rightsForm == '1'" style="width: 30px"></i>
            <i class="fas fa-user-shield ml-2" *ngIf="rightsForm == '2'" style="width: 30px"></i>
          </span>
          <mat-label>Droit</mat-label>
          <mat-select [(ngModel)]="rightsForm" appearance="fill">
            <mat-option value="0"><i class="fas fa-user-alt-slash" style="width: 30px"></i>Aucun droit</mat-option>
            <mat-option value="1"><i class="fas fa-user" style="width: 30px"></i>Utillisateur</mat-option>
            <mat-option value="2"><i class="fas fa-user-shield" style="width: 30px"></i>Administrateur</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="mt-4"><button type="button" (click)="updateUser()" class="btn btn-dark">Modifier</button></div>
    </div>
    <div class="list-container p-3 m-3">
      <div class="mb-4"><strong>Liste des projets associés</strong></div>
      <div *ngFor="let project of this.data?.user.projects" class="w-100 mx-2 px-2 d-flex justify-content-between align-items-center project-line">
        <span>
          {{ project.name }}
          <span style="font-size: 13px;">
            <i class="fas fa-user-alt-slash ml-2" *ngIf="project.rights == 0" matTooltip="Aucun droit"></i>
            <i class="fas fa-user ml-2" *ngIf="project.rights == 1" matTooltip="droits utilisateur"></i>
            <i class="fas fa-user-shield ml-2" *ngIf="project.rights == 2" matTooltip="droits administrateur"></i>
            <i class="fas fa-desktop ml-2" *ngIf="project.isSeenScreenColumns != 0" matTooltip="droits screen"></i>
            <i class="fas fa-tools ml-2" *ngIf="project.isSeenActionsColumns != 0" matTooltip="droits actions"></i>
          </span>
        </span>
        <i class="far fa-trash-alt text-danger pointer" (click)="onRemoveProject(project)"></i>
      </div>
      <div class="mt-4"><button (click)="onOpenDialogSelect()" [disabled]="this.data?.projects.length == 0" type="button" class="btn btn-dark">Ajouter un projet</button></div>
    </div>
  </div>
</div>
