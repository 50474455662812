import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from "@angular/material/paginator";
import {MatTableDataSource} from "@angular/material/table";
import {DataConfigurationConcessionService} from "../utils/data-services/data-configuration-concession.service";
import {Router} from "@angular/router";
import swal from "sweetalert2";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {MatSort, Sort} from "@angular/material/sort";
import {LiveAnnouncer} from "@angular/cdk/a11y";

@Component({
  selector: 'app-gestion-default-configuration',
  templateUrl: './gestion-default-configuration.component.html',
  styleUrls: ['./gestion-default-configuration.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class GestionDefaultConfigurationComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  variable: any;
  /* Variable list for the Textarea components */
  nom: any;
  dataSource = new MatTableDataSource();
  // tslint:disable-next-line:variable-name
  constructor(private dataConfiguration: DataConfigurationConcessionService, private _liveAnnouncer: LiveAnnouncer, private route: Router) {  }
  displayedColumns: string[] = ['type', 'date', 'name', 'current', 'update', 'delete', 'expand'];
  expandedElement: any | null;
  typesArray: any;
  originalDataSource: any;
  selectedType = null;
  selectedActive = null;
  search: any;

  ngOnInit() {
    this.search = localStorage.getItem('defaultConfigurationFilter') != null ? localStorage.getItem('defaultConfigurationFilter') : '';
    this.selectedType = JSON.parse(localStorage.getItem('filterGestionReleaseType'));
    this.selectedActive = localStorage.getItem('filterGestionReleaseStatus');
    this.paginator.pageIndex = localStorage.getItem('pageIndexDefaultConfiguration') != null ? parseInt(localStorage.getItem('pageIndexDefaultConfiguration')) : null;
    this.paginator.pageSize = localStorage.getItem('pageSizeDefaultConfiguration') != null ? parseInt(localStorage.getItem('pageSizeDefaultConfiguration')) : null;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.getRelease();
    this.getType();
  }
  ngAfterViewInit(): void {
    this.dataSource.filter = this.search.trim().toLowerCase();
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  getRelease() {
    this.dataConfiguration.getRelease().subscribe(result => {
      this.dataSource.data = result;
      this.originalDataSource = result;
      this.filterRelease();
    });
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    localStorage.setItem('defaultConfigurationFilter', filterValue);
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  onUpdate(id) {
    this.route.navigate(['/update-release/' + id]);
  }
  onCreate() {
    this.route.navigate(['/create-release']);
  }
  onDelete(id: any) {
    swal.fire({
      title: 'Supprimer',
      text: 'Voulez-vous supprimer cette release',
      confirmButtonText: 'Ok',
      showCancelButton: true,
      cancelButtonText: 'Annuler'
    }).then((result) => {
      if (!result.dismiss) {
        this.dataConfiguration.deleteRelease(id).subscribe(() => {
          this.getRelease();
        });
      }
    });
  }
  focusSearch(type: string): void {
    setTimeout(() => {
      if (document.getElementById('select-search-' + type) != null) {
        // @ts-ignore
        document.getElementById('select-search-' + type).focus();
      }
    });
  }
  stopPropagation(event): any {
    event.stopPropagation();
  }
  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }
  getType() {
    this.dataConfiguration.getReleaseType().subscribe(result => {
      this.typesArray = result;
    });
  }
  filterRelease(): void {
    localStorage.setItem('filterGestionReleaseType', JSON.stringify(this.selectedType));
    localStorage.setItem('filterGestionReleaseStatus', this.selectedActive);
    if ((this.selectedType == null || this.selectedType.length === 0) && (this.selectedActive == null || this.selectedActive === 'all')) {
      this.dataSource.data = this.originalDataSource;
    } else if (this.selectedActive == null || this.selectedActive === 'all') {
      this.dataSource.data = this.originalDataSource.filter((e: any) => this.selectedType.includes(e.type));
    } else if (this.selectedType == null || this.selectedType.length === 0) {
      this.dataSource.data = this.originalDataSource.filter((e: any) => this.selectedActive.includes(e.currentRelease));
    } else {
      this.dataSource.data = this.originalDataSource.filter((e: any) => this.selectedActive.includes(e.currentRelease) && this.selectedType.includes(e.type));
    }
  }
  getPageSizeOptions(): number[] {
    if (this.dataSource && this.dataSource.data.length > 100 && this.dataSource.paginator) {
      return [20, 50, 100,  this.dataSource.paginator.length];
    } else {
      return [20, 50, 100];
    }
  }
  displayPaginatorAllOption() {
    const matOptions = document.getElementsByClassName('mat-option-text');
    // @ts-ignore
    for (const options of matOptions) {
      options.innerHTML = options.innerHTML == this.dataSource.data.length ? 'Tous' : options.innerHTML;
    }
  }
  pageChange() {
    localStorage.setItem('pageIndexDefaultConfiguration', String(this.paginator.pageIndex));
    localStorage.setItem('pageSizeDefaultConfiguration', String(this.paginator.pageSize));
  }
}
