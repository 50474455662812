import {Component, OnInit, ViewChild} from '@angular/core';
import {DataConcessionService} from '../utils/data-services/dataConcession.service';
import {Router} from '@angular/router';
import {DataCreationConcessionService} from '../utils/data-services/dataCreation-concession.service';
import {DataContactService} from '../utils/data-services/dataContact.service';
import {MatTableDataSource} from '@angular/material/table';
import {DataDealershipSynchronizationService} from "../utils/data-services/data-dealership-synchronization.service";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";

@Component({
  selector: 'app-gestion-mapping-planngo',
  templateUrl: './gestion-mapping-planngo.component.html',
  styleUrls: ['./gestion-mapping-planngo.component.scss']
})
export class GestionMappingPlanngoComponent implements OnInit {
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  variable: any;
  /* Variable list for the Textarea components */
  sort = null;
  concessionID: any;
  nom: any;
  ELEMENT_DATA: any;
  dataSource: any;
  groups = [];
  search: any;

  // tslint:disable-next-line:max-line-length
  constructor(private dataSynchro: DataDealershipSynchronizationService, private dataConcessionService: DataConcessionService, private route: Router, private dataService: DataCreationConcessionService, private  dataContactService: DataContactService) {  }
  displayedColumns: string[] = ['concession_id', 'nom_concession', 'brand', 'operation', 'mobilite', 'synchro'];

  ngOnInit() {
    this.dataConcessionService.getConcession().subscribe(data => {
      this.dataService.getGroups().subscribe(groups => {
        this.groups = groups.records;

        this.variable = [];
        for (const concession of data.records) {
          if (this.groups.filter(x => x.groupe_id == concession.groupe_id)[0]) {
            concession.group = this.groups.filter(x => x.groupe_id == concession.groupe_id)[0].nom;
          }
          this.variable.push(concession);
        }
        this.ELEMENT_DATA = this.variable;
        this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
        let bigestId = 0;
        for (const concession of data.records) {
          if (concession.concession_id > bigestId) {
            bigestId = concession.concession_id;
          }
        }
        sessionStorage.setItem('autoIncrement', (bigestId + 1).toString());
        this.search = localStorage.getItem('mappingPlanngoFilter') != null ? localStorage.getItem('mappingPlanngoFilter') : '';
        this.dataSource.filter = this.search.trim().toLowerCase();
      });
    });
    this.paginator.pageIndex = localStorage.getItem('pageIndexMapping') != null ? parseInt(localStorage.getItem('pageIndexMapping')) : null;
    this.paginator.pageSize = localStorage.getItem('pageSizeMapping') != null ? parseInt(localStorage.getItem('pageSizeMapping')) : null;
    this.dataSource.paginator = this.paginator;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    localStorage.setItem('mappingPlanngoFilter', filterValue
    )
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  sortName() {
    if (this.sort == 'nameAcs') {
      this.sort = 'nameDesc';
      this.dataSource.data = this.dataSource.data.sort((a, b) => {
        return a.nom_concession < b.nom_concession ? 1 : -1;
      });
    } else {
      this.sort = 'nameAcs';
      this.dataSource.data = this.dataSource.data.sort((a, b) => {
        return a.nom_concession > b.nom_concession ? 1 : -1;
      });
    }
  }
  sortId() {
    if (this.sort == 'idAcs') {
      this.sort = 'idDesc';
      this.dataSource.data = this.dataSource.data.sort((a, b) => {
        return a.concession_id < b.concession_id ? 1 : -1;
      });
    } else {
      this.sort = 'idAcs';
      this.dataSource.data = this.dataSource.data.sort((a, b) => {
        return a.concession_id > b.concession_id ? 1 : -1;
      });
    }
  }
  onClickBrand(idConcession) {
    this.route.navigate(['../gestion-marque-planngo/' + idConcession]);
  }
  onClickMobilite(idConcession) {
    this.route.navigate(['../gestion-mobilite/' + idConcession]);
  }
  onClickOperation(idConcession) {
    this.route.navigate(['../gestion-operation/' + idConcession]);
  }
  onClickSynchro(idConcession) {
    this.dataSynchro.dealershipSynchronization(idConcession).subscribe(result => {
    });
  }
  getPageSizeOptions(): number[] {
    if (this.dataSource && this.dataSource.data.length > 100 && this.dataSource.paginator) {
      return [20, 50, 100,  this.dataSource.paginator.length];
    } else {
      return [20, 50, 100];
    }
  }
  displayPaginatorAllOption() {
    const matOptions = document.getElementsByClassName('mat-option-text');
    // @ts-ignore
    for (const options of matOptions) {
      options.innerHTML = options.innerHTML == this.dataSource.data.length ? 'Tous' : options.innerHTML;
    }
  }
  pageChange() {
    localStorage.setItem('pageIndexMapping', String(this.paginator.pageIndex));
    localStorage.setItem('pageSizeMapping', String(this.paginator.pageSize));
  }
}
