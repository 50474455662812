<div class="page-content">
  <h1 class="text-center mt-2">Gestion des utilisateurs (DORA)</h1>

  <div class="mt-4 p-3">
    <!-- Filters -->
    <div class="d-flex justify-content-between align-items-center px-4 py-2">
     <div>
       <mat-form-field style="width: 250px">
         <mat-label>Cherchez un utilisateur</mat-label>
         <input [(ngModel)]="search" matInput (keyup)="applyFilter($event)">
       </mat-form-field>
       <mat-form-field class="view-filter ml-3 w-auto" style="height: 45px">
         <mat-label>Droit</mat-label>
         <mat-select [(ngModel)]="selectedRight" (selectionChange)="filterDealership()"  appearance="fill">
           <mat-option value="all"><i class="fas fa-infinity" style="width: 30px"></i>Tous</mat-option>
           <mat-option value="0"><i class="fas fa-user-alt-slash" style="width: 30px"></i>Aucun droit</mat-option>
           <mat-option value="1"><i class="fas fa-user" style="width: 30px"></i>Utillisateur</mat-option>
           <mat-option value="2"><i class="fas fa-user-shield" style="width: 30px"></i>Administrateur</mat-option>
         </mat-select>
       </mat-form-field>
     </div>
      <div (click)="getData()" class="d-flex align-items-center"><mat-icon matTooltip="Rafraichir la donnée">refresh</mat-icon></div>
    </div>
    <!-- Table -->
    <table mat-table [dataSource]="dataSource" [hidden]="dataSource.filteredData.length == 0" matSort class="mat-elevation-z8">
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Nom </th>
        <td mat-cell *matCellDef="let element">
          <i *ngIf="element.rights == 0" class="fas fa-user-alt-slash mr-3"></i>
          <i *ngIf="element.rights == 1" class="fas fa-user mr-3"></i>
          <i *ngIf="element.rights == 2" class="fas fa-user-shield mr-3"></i>
          {{element.name}}
        </td>
      </ng-container>
      <ng-container matColumnDef="login">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Login </th>
        <td mat-cell *matCellDef="let element"> {{element.login}} </td>
      </ng-container>
      <ng-container matColumnDef="password">
        <th mat-header-cell *matHeaderCellDef style="width: 200px;"> Mot de passe </th>
        <td mat-cell *matCellDef="let element"> {{element.isPwdVisible ? decryptAES(element.password) : '*******'}} </td>
      </ng-container>
      <ng-container matColumnDef="rights">
        <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 120px;"> Droit </th>
        <td mat-cell *matCellDef="let element">
          <span *ngIf="element.rights == 0">Aucun droit</span>
          <span *ngIf="element.rights == 1">Utilisateur</span>
          <span *ngIf="element.rights == 2">Administrateur</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef style="width: 150px;"> Action </th>
        <td mat-cell *matCellDef="let element">
          <div class="d-flex align-items-center">
            <mat-icon *ngIf="element.isPwdVisible" (click)="element.isPwdVisible = !element.isPwdVisible" class="pointer show-pswd mr-4">visibility</mat-icon>
            <mat-icon *ngIf="!element.isPwdVisible" (click)="element.isPwdVisible = !element.isPwdVisible"  class="pointer show-pswd mr-4">visibility_off</mat-icon>
            <button class="m-2 btn btn-round-40 btn-dark" (click)="onEdit(element.id)"><mat-icon>edit</mat-icon></button>
          </div>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator [hidden]="dataSource.filteredData.length == 0" [pageSizeOptions]="getPageSizeOptions()" (click)="displayPaginatorAllOption()" (page)="pageChange()" showFirstLastButtons aria-label="Sélectionner la page"></mat-paginator>

    <div class="w-100 font-weight-bold d-flex align-items-center mt-5 justify-content-center" *ngIf="dataSource.filteredData.length == 0">
      <mat-icon class="mr-2">info</mat-icon>
      Aucun résultat à afficher
    </div>
  </div>

</div>
