import {Component, Inject, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {DataSmsSubscriptionService} from "../../utils/data-services/data-sms-subscription.service";
import {resolve} from "@angular/compiler-cli/src/ngtsc/file_system";
import moment from "moment";
import swal from "sweetalert2";

@Component({
  selector: 'app-assign-dealership-subscription',
  templateUrl: './assign-dealership-subscription.component.html',
  styleUrls: ['./assign-dealership-subscription.component.scss']
})
export class AssignDealershipSubscriptionComponent implements OnInit {


  constructor(private activeRoute: ActivatedRoute, private dataService: DataSmsSubscriptionService, private route: Router) { }
  subscriptions = [];
  id: any;
  endDate = true;
  dateNow = new Date();
  subscription = {
    idClientPilotISC: null,
    idAboSMS: null,
    startDate: null,
    endDate: null,
    creationDate: moment(new Date())
  };
  currentMonth = this.dateNow.getMonth() + 1;
  // minimumDate = this.dateNow.getFullYear() + '-' + this.currentMonth + '-' + this.dateNow.getDate();
  minimumDate = moment(this.dateNow.setDate(this.dateNow.getDate() + 1)).format('YYYY-MM-DD');
  ngOnInit() {
    this.id = Number(this.activeRoute.snapshot.paramMap.get('id'));
    this.subscription.idClientPilotISC = this.id;
    this.getSubscriptions();
  }
  getSubscriptions() {
    this.dataService.getSubscriptionsSms().subscribe(result => {
      for (const subscription of result) {
        subscription.label = subscription.monthlyCost !== 0 ?
          'Abonnement à ' + subscription.monthlyCost + ' € (' + subscription.smsCost + ' € par SMS)' :
          'Acquisition (' + subscription.smsCost + ' € par SMS)';
      }
      result.sort((a, b) => { return a.label < b.label ? -1 : 1 });
      this.subscriptions = result;
    });
  }
  onAssign() {
    if (!this.endDate) {
      this.subscription.endDate = null;
    }
    this.dataService.assignSubscriptionDealership(this.subscription).subscribe(() => {
      this.route.navigate(['../gestion-sms-subscription']);
    });
  }
  onCancel() {
    this.route.navigate(['../gestion-sms-subscription']);
  }
}
