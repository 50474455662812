<nav class="navbar navbar-expand navbar-dark bg-dark" style="display: flex; justify-content: space-between; position: fixed; width: 100%; z-index: 99; top: 0;">
  <div class="navbar-nav">
    <a routerLink="/" class="nav-item nav-link mr-2 pr-3" style="color: white;border-right: solid 1px white;"><strong>Portail-SoftGate</strong></a>

    <button *ngIf="isConnected" class="nav-dropdown"  mat-button [matMenuTriggerFor]="menuSoftgate" style="color: rgba(255, 255, 255, 0.5);">Soft-Nrg<mat-icon class="ml-2">keyboard_arrow_down</mat-icon></button>
    <mat-menu #menuSoftgate="matMenu" class="mat-menu-kidi">
      <a *ngIf="isConnected" class="nav-item nav-link" routerLink="/consultation">Paramètres Talend<mat-icon class="ml-2">keyboard_arrow_right</mat-icon></a>
      <a *ngIf="isConnected" class="nav-item nav-link" routerLink="/gestion-marque">Marques<mat-icon class="ml-2">keyboard_arrow_right</mat-icon></a>
      <a *ngIf="isConnected" class="nav-item nav-link" routerLink="/gestion-produit">Produits<mat-icon class="ml-2">keyboard_arrow_right</mat-icon></a>
      <a *ngIf="isConnected" class="nav-item nav-link" routerLink="/gestion-parametre">Paramètres<mat-icon class="ml-2">keyboard_arrow_right</mat-icon></a>
      <a *ngIf="isConnected" class="nav-item nav-link" routerLink="/gestion-tag">Tag<mat-icon class="ml-2">keyboard_arrow_right</mat-icon></a>
      <a *ngIf="isConnected" class="nav-item nav-link" routerLink="/gestion-produit-concession">Produits par concession<mat-icon class="ml-2">keyboard_arrow_right</mat-icon></a>
      <a *ngIf="isConnected" class="nav-item nav-link" routerLink="/maintenances">Maintenances<mat-icon class="ml-2">keyboard_arrow_right</mat-icon></a>
    </mat-menu>
    <button *ngIf="isConnected" class="nav-dropdown"  mat-button [matMenuTriggerFor]="menuConcession" style="color: rgba(255, 255, 255, 0.5);">Concessions<mat-icon class="ml-2">keyboard_arrow_down</mat-icon></button>
    <mat-menu #menuConcession="matMenu" class="mat-menu-kidi">
      <a *ngIf="isConnected" class="nav-item nav-link" routerLink="/gestion-concession">Listes<mat-icon class="ml-2">keyboard_arrow_right</mat-icon></a>
      <a *ngIf="isConnected" class="nav-item nav-link" routerLink="/gestion-configuration-concession">Suivi des versions<mat-icon class="ml-2">keyboard_arrow_right</mat-icon></a>
      <a *ngIf="isConnected" class="nav-item nav-link" routerLink="/gestion-release">Releases<mat-icon class="ml-2">keyboard_arrow_right</mat-icon></a>
      <a *ngIf="isConnected" class="nav-item nav-link" routerLink="/gestion-section-configuration">Section formulaire<mat-icon class="ml-2">keyboard_arrow_right</mat-icon></a>
      <a *ngIf="isConnected" class="nav-item nav-link" routerLink="/gestion-configuration">Champs formulaire<mat-icon class="ml-2">keyboard_arrow_right</mat-icon></a>
      <a *ngIf="isConnected" class="nav-item nav-link" routerLink="/gestion-contact">Contacts<mat-icon class="ml-2">keyboard_arrow_right</mat-icon></a>
      <a *ngIf="isConnected" class="nav-item nav-link" routerLink="/gestion-fonction">Fonctions<mat-icon class="ml-2">keyboard_arrow_right</mat-icon></a>
      <a *ngIf="isConnected" class="nav-item nav-link" routerLink="/gestion-groupe">Groupes<mat-icon class="ml-2">keyboard_arrow_right</mat-icon></a>
    </mat-menu>
    <button *ngIf="isConnected" class="nav-dropdown"  mat-button [matMenuTriggerFor]="menuSoftgateApp" style="color: rgba(255, 255, 255, 0.5);">Soft-Gate<mat-icon class="ml-2">keyboard_arrow_down</mat-icon></button>
    <mat-menu #menuSoftgateApp="matMenu" class="mat-menu-kidi">
      <a *ngIf="isConnected" class="nav-item nav-link" routerLink="/gestion-utilisateur">Utilisateurs<mat-icon class="ml-2">keyboard_arrow_right</mat-icon></a>
      <a *ngIf="isConnected" class="nav-item nav-link" routerLink="/gestion-notification">Notifications<mat-icon class="ml-2">keyboard_arrow_right</mat-icon></a>
      <a *ngIf="isConnected" class="nav-item nav-link" routerLink="/gestion-formation">Formations<mat-icon class="ml-2">keyboard_arrow_right</mat-icon></a>
      <a *ngIf="isConnected" class="nav-item nav-link" routerLink="/gestion-demandes-support">Suivi Support<mat-icon class="ml-2">keyboard_arrow_right</mat-icon></a>
      <a *ngIf="isConnected" class="nav-item nav-link kidi-faq-nav nav-dropdown" (click)="stopPropagation($event)" [matMenuTriggerFor]="menuFaq" style="color: rgba(255, 255, 255, 0.5);">FAQ<mat-icon class="ml-2">keyboard_arrow_down</mat-icon></a>
      <mat-menu #menuFaq="matMenu" class="mat-menu-faq" xPosition="after" yPosition="below">
        <a *ngIf="isConnected" class="nav-item nav-link" routerLink="/gestion-faq-theme">Grands-Thèmes<mat-icon class="ml-2">keyboard_arrow_right</mat-icon></a>
        <a *ngIf="isConnected" class="nav-item nav-link" routerLink="/gestion-faq-question">Questions<mat-icon class="ml-2">keyboard_arrow_right</mat-icon></a>
      </mat-menu>
    </mat-menu>
    <button *ngIf="isConnected" class="nav-dropdown"  mat-button [matMenuTriggerFor]="menuKidi" style="color: rgba(255, 255, 255, 0.5);">KIDI<mat-icon class="ml-2">keyboard_arrow_down</mat-icon></button>
    <mat-menu #menuKidi="matMenu" class="mat-menu-kidi">
      <a *ngIf="isConnected" class="nav-item nav-link" routerLink="/gestion-statut">Mapping Statuts<mat-icon class="ml-2">keyboard_arrow_right</mat-icon></a>
      <a *ngIf="isConnected" class="nav-item nav-link" routerLink="/gestion-regle">Règles<mat-icon class="ml-2">keyboard_arrow_right</mat-icon></a>
      <a *ngIf="isConnected" class="nav-item nav-link" routerLink="/gestion-communication">Communications<mat-icon class="ml-2">keyboard_arrow_right</mat-icon></a>
    </mat-menu>
    <button *ngIf="isConnected" class="nav-dropdown"  mat-button [matMenuTriggerFor]="menuPsms" style="color: rgba(255, 255, 255, 0.5);">PSMS<mat-icon class="ml-2">keyboard_arrow_down</mat-icon></button>
    <mat-menu #menuPsms="matMenu" class="mat-menu-kidi">
      <a *ngIf="isConnected" class="nav-item nav-link" routerLink="/gestion-sms">SMS actifs<mat-icon class="ml-2">keyboard_arrow_right</mat-icon></a>
      <a *ngIf="isConnected" class="nav-item nav-link" routerLink="/gestion-sms-subscription">Abonnements actifs<mat-icon class="ml-2">keyboard_arrow_right</mat-icon></a>
      <a *ngIf="isConnected" class="nav-item nav-link" routerLink="/gestion-subscription">Abonnements<mat-icon class="ml-2">keyboard_arrow_right</mat-icon></a>
    </mat-menu>
    <button *ngIf="isConnected" class="nav-dropdown"  mat-button [matMenuTriggerFor]="menuApv" style="color: rgba(255, 255, 255, 0.5);">APV<mat-icon class="ml-2">keyboard_arrow_down</mat-icon></button>
    <mat-menu #menuApv="matMenu" class="mat-menu-kidi">
      <a *ngIf="isConnected" class="nav-item nav-link" routerLink="/gestion-mapping-planngo">Plan N'Go - Opérations et mobilités<mat-icon class="ml-2">keyboard_arrow_right</mat-icon></a>
      <a *ngIf="isConnected" class="nav-item nav-link" routerLink="/gestion-campagne-sms">My BMW App - Campagnes SMS<mat-icon class="ml-2">keyboard_arrow_right</mat-icon></a>
      <a *ngIf="isConnected" class="nav-item nav-link" routerLink="/gestion-snapshot-scenario">Délai de rendez-vous - Scénarios<mat-icon class="ml-2">keyboard_arrow_right</mat-icon></a>
      <a *ngIf="isConnected" class="nav-item nav-link" routerLink="/gestion-reception-options-mapping">Réception 24/7<mat-icon class="ml-2">keyboard_arrow_right</mat-icon></a>
      <a *ngIf="isConnected" class="nav-item nav-link" routerLink="/gestion-rent">BMW Rent<mat-icon class="ml-2">keyboard_arrow_right</mat-icon></a>
      <a *ngIf="isConnected" class="nav-item nav-link" routerLink="/gestion-backup-concession">Backup - Gestion des alertes<mat-icon class="ml-2">keyboard_arrow_right</mat-icon></a>
    </mat-menu>
<!--    <button *ngIf="isConnected" class="nav-dropdown"  mat-button [matMenuTriggerFor]="menuInfoProduit" style="color: rgba(255, 255, 255, 0.5);">Soft-Nrg<mat-icon class="ml-2">keyboard_arrow_down</mat-icon></button>-->
<!--    <mat-menu #menuInfoProduit="matMenu" class="mat-menu-kidi">-->
<!--      <a *ngIf="isConnected" class="nav-item nav-link" routerLink="/maintenances">Maintenances<mat-icon class="ml-2">keyboard_arrow_right</mat-icon></a>-->
<!--      <a *ngIf="isConnected" class="nav-item nav-link" routerLink="/gestion-informations-produit">Informations Produits<mat-icon class="ml-2">keyboard_arrow_right</mat-icon></a>-->
<!--    </mat-menu>-->
  </div>
  <div class="auth">
    <a *ngIf="!isConnected" class="nav-item nav-link" routerLink="/login" style="color: lightgray">Connexion</a>
    <button *ngIf="isConnected" mat-button [matMenuTriggerFor]="menuOption" style="color: rgba(255, 255, 255, 0.5);">	<mat-icon>settings</mat-icon><mat-icon class="ml-2">keyboard_arrow_down</mat-icon></button>
    <mat-menu #menuOption="matMenu" class="mat-menu-kidi">
      <a *ngIf="isConnected" (click)="onDisconnect()" class="nav-item nav-link" routerLink="/" style="color: lightgray">Déconnexion</a>
    </mat-menu>
  </div>
</nav>

<div class="page-content">
  <router-outlet></router-outlet>
</div>

<footer>
  <small>Version {{version}} - Copyright {{year}}. Tous droits réservés. Créé par <span class="text-danger">Soft-nrg</span>.</small>
</footer>
<app-spinner *ngIf="isLoading"></app-spinner>
