import {Component, Inject, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {MAT_DIALOG_DATA} from '@angular/material';
import {DataDoraService} from '../../../utils/data-services/data-dora.service';
import {AppComponent} from '../../../app.component';
import swal from 'sweetalert2';

@Component({
  selector: 'app-add-by-select-dialog',
  templateUrl: './add-by-select-dialog.component.html',
  styleUrls: ['./add-by-select-dialog.component.scss']
})
export class AddBySelectDialogComponent implements OnInit {

  type = 'Projets';
  selectedItem = null;
  items = [];
  inputSearch = '';
  isLoading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddBySelectDialogComponent>,
    private dataDoraService: DataDoraService,
  ) { }

  ngOnInit() {
    this.type = this.data.type;
    this.items = this.data.values;
  }
  onFilterSearch(event): any {
    event.stopPropagation();
    const regexp = new RegExp(this.inputSearch, 'gi');
    this.items = this.data.values.filter(x => regexp.test(x.name));
  }
  onAddItem(): any {
    this.isLoading = true;
    if (this.type == 'Projets') {
      this.dataDoraService.addDealershipProject(this.data.dealership, this.selectedItem).subscribe(res => {
        this.isLoading = false;
        this.dialogRef.close('success');
      }, error => {
        this.isLoading = false;
        swal.fire({
          title: 'Erreur',
          text: 'Un problème est survenu lors de l\'insertion.',
          type: 'error',
          confirmButtonText: 'Ok'
        });
      });
    } else {
      this.dataDoraService.addDealershipBrand(this.data.dealership, this.selectedItem).subscribe(res => {
        this.isLoading = false;
        this.dialogRef.close('success');
      }, error => {
        this.isLoading = false;
        swal.fire({
          title: 'Erreur',
          text: 'Un problème est survenu lors de l\'insertion.',
          type: 'error',
          confirmButtonText: 'Ok'
        });
      });
    }
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
}
