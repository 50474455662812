<h1 class="text-center mt-2">Gestion des agences de location</h1>
<div class="user-table mt-4">
  <div class="container-row d-flex justify-content-between top-field">
    <div class="btn1">
      <button (click)="SelectChangeHandlerCreer()" id="btn-add-concession" class="mr-5 btn btn-dark">
        <mat-icon>add_circle</mat-icon> Agence de location
      </button>
    </div>
    <mat-form-field class="m-3">
      <mat-label>Rechercher</mat-label>
      <input [(ngModel)]="search" matInput (keyup)="applyFilter($event)">
    </mat-form-field>
  </div>
</div>

<div class="example-container">
  <table mat-table [dataSource]="dataSource" class="user-table mat-elevation-z8 mb-3">

    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef> ID </th>
      <td mat-cell *matCellDef="let element"> {{element.id}} </td>
    </ng-container>


    <ng-container matColumnDef="concession">
      <th mat-header-cell *matHeaderCellDef> Concession </th>
      <td mat-cell *matCellDef="let element"> {{element.concession}} </td>
    </ng-container>

    <ng-container matColumnDef="marque">
      <th mat-header-cell *matHeaderCellDef> Marque </th>
      <td mat-cell *matCellDef="let element"> {{element.marque}} </td>
    </ng-container>

    <ng-container matColumnDef="ucar_agency_id">
      <th mat-header-cell *matHeaderCellDef> Identifiant de l'agence de location </th>
      <td mat-cell *matCellDef="let element"> {{element.ucar_agency_id}} </td>
    </ng-container>

    <ng-container matColumnDef="typologie_client">
      <th mat-header-cell *matHeaderCellDef> Typologie client </th>
      <td mat-cell *matCellDef="let element"> {{element.typologie_client}} </td>
    </ng-container>

    <ng-container matColumnDef="modifier">
      <th mat-header-cell *matHeaderCellDef> Modifier </th>
      <td mat-cell *matCellDef="let element">
        <button (click)="SelectChangeHandlerModifier(element.id)" class="m-2 btn btn-round-40 btn-dark">
          <mat-icon>edit</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <mat-paginator [pageSizeOptions]="getPageSizeOptions()"
                 (click)="displayPaginatorAllOption()"
                 (page)="pageChange()"
                 showFirstLastButtons
                 aria-label="Sélectionner la page">
  </mat-paginator>
</div>
