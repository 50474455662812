<div class="home-bgd-img"><img src="assets/Imgs/computer-supprt.jpg" class="w-100" alt="image d'arrière plan"></div>
<div class="list-group pt-5">
  <div class="reset-pwd-form-container mt-6">
    <h3 class="titre">Réinitialisation du mot de passe</h3>
    <div class="form-content">
      <form [formGroup]="resetPwdForm" (ngSubmit)="onSubmit(resetPwdForm.value)" style="margin-top: 50px; width: 100%">
        <div class="form-group position-relative">
          <label for="resetPassword">Entrez un mot de passe</label>
          <input [type]="isPwdVisible ? 'text' : 'password'" class="form-control" id="resetPassword" placeholder="Insérez votre Mot de passe"
                 name="password" (ngModelChange)="onCheckPwd()"
                 [formControl]="pwd">
          <mat-icon *ngIf="isPwdVisible" (click)="onViewPassword()" class="pointer show-pswd">visibility</mat-icon>
          <mat-icon *ngIf="!isPwdVisible" (click)="onViewPassword()"  class="pointer show-pswd">visibility_off</mat-icon>
        </div>
        <div class="form-group position-relative">
          <label for="confirmPassword">Répétez le mot de passe</label>
          <input [type]="isPwdVisible ? 'text' : 'password'" class="form-control" id="confirmPassword" placeholder="Répétez le Mot de passe"
                 name="password" (ngModelChange)="onCheckConfirmPwd()"
                 [formControl]="confirmPwd">
          <small *ngIf="!isPwdValid">12 caractères minimum dont une majuscule, une minuscule, un chiffre et un
            caratctère spécial. </small>
          <small *ngIf="isPwdValid && !isConfirmPwdValid">Les mots de passe doivent être identiques</small>
          <small *ngIf="isPwdValid && isConfirmPwdValid" style="color: green">Mot de passe valide</small>
          <mat-icon *ngIf="isPwdVisible" (click)="onViewPassword()" class="pointer show-pswd">visibility</mat-icon>
          <mat-icon *ngIf="!isPwdVisible" (click)="onViewPassword()"  class="pointer show-pswd">visibility_off</mat-icon>
        </div>
        <div class="btn-group row">
          <button type="submit" [disabled]="resetPwdForm.invalid" class="btn btn100px btn-dark p-2 mr-2">Réinitialiser
          </button>
          <button type="button" (click)="Annuler()" class="btn btn100px border-dark btn-light p-2">Annuler</button>
        </div>
      </form>
    </div>
  </div>
</div>
<!--Loader/Spinner-->
<app-spinner *ngIf="isSubmited"></app-spinner>


