import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthenticationService} from '../../utils/authentication.service';
import jwt_decode from 'jwt-decode';

@Component({
  selector: 'app-auth2fa',
  templateUrl: './auth2fa.component.html',
  styleUrls: ['./auth2fa.component.scss']
})
export class Auth2faComponent implements OnInit {

  form2fa: FormGroup;
  code2fa: FormControl;
  otpUrl = null;
  isQrOpen = false;
  sentByMail = false;

  // isSubmitted = false;
  // isPwdVisible = false;
  // errors: any;

  constructor(
    private route: Router,
    private fb: FormBuilder,
    private authenticationService: AuthenticationService,
  ) { }

  ngOnInit(): void {
    this.otpUrl = decodeURI(sessionStorage.qrCodeUrl);
    this.formLoginApplication();
    this.fieldCodeLogic();
    document.getElementById('code').focus();
  }

  public formLoginApplication(): void {
    if (sessionStorage.getItem('token') == null) {
      this.route.navigate(['../']);
    }
    this.code2fa = new FormControl('', Validators.required);
    this.form2fa = this.fb.group({
      user2faCode: this.code2fa,
    });
  }

  fieldCodeLogic(): any {
    let indexSelected = 0;
    const input = document.getElementById('code');
    input.addEventListener('click', event => {
      const target = event.target as HTMLInputElement;
      indexSelected = target.selectionStart;
      target.selectionEnd = indexSelected + 1;
      setValue(event);
    });
    input.addEventListener('keyup', (event) => {
      const target = event.target as HTMLInputElement;
      indexSelected = target.selectionStart;
      target.selectionEnd = indexSelected + 1;
      if (event.keyCode == 37) {
        indexSelected--;
        target.selectionStart = indexSelected;
        target.selectionEnd = indexSelected + 1;
      } else if (event.keyCode == 39) {
        target.selectionStart = indexSelected;
        target.selectionEnd = indexSelected + 1;
      } else if (event.key == 'Backspace' || event.key == 'Delete') {
        target.selectionEnd = indexSelected + 1;
      }
      setValue(event);
    });
    input.addEventListener('paste', (event) => {
      setValue(event);
    });

    function setValue(event): void {
      document.getElementById('1').innerHTML =  event.target.value.slice(0, 1);
      document.getElementById('2').innerHTML = event.target.value.slice(1, 2);
      document.getElementById('3').innerHTML = event.target.value.slice(2, 3);
      document.getElementById('4').innerHTML =  event.target.value.slice(3, 4);
      document.getElementById('5').innerHTML = event.target.value.slice(4, 5);
      document.getElementById('6').innerHTML = event.target.value.slice(5, 6);

      if (document.getElementsByClassName('selected')[0]) {
        document.getElementsByClassName('selected')[0].classList.remove('selected');
      }
      if (event.target.selectionStart < 6 && document.getElementsByClassName('number')[indexSelected]) {
        document.getElementsByClassName('number')[indexSelected].classList.add('selected');
      } else if (event.target.selectionStart == 6) {
        event.target.selectionStart = indexSelected - 1;
        event.target.selectionEnd = event.target.selectionStart + 1;
        if (document.getElementsByClassName('number')[indexSelected - 1]) {
          document.getElementsByClassName('number')[indexSelected - 1].classList.add('selected');
        }
      }
    }

  }
  sendByEmail(): any {
    this.authenticationService.requestSend2faCodeByEmail(sessionStorage.getItem('token'), {origin: 'portail-softgate'}).subscribe(res => {
      this.sentByMail = true;
    });
  }
  onSubmit(): any {
    this.authenticationService.request2faVerification(sessionStorage.getItem('token'), {user2faCode: this.form2fa.value.user2faCode, sentByMail: this.sentByMail}).subscribe(data => {
      this.authenticationService.loginSuccess(data);
      sessionStorage.removeItem('qrCodeUrl');
      if (this.authenticationService.getCookie('sg2FaValidity')) {
        this.authenticationService.eraseCookie('sg2FaValidity');
      }
      const jwtPayload = jwt_decode(data.token);
      this.authenticationService.setCookie('sg2FaValidity', jwtPayload.email, 2);
    }, error => {
      this.authenticationService.tokenError();
    });
  }
  goBack(): any {
    this.route.navigate(['../login']);
  }
}
