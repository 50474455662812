<h1 class="text-center mt-2">Gestion des releases</h1>
<div class="user-table mt-4">
  <div class="d-flex justify-content-between align-items-baseline">
    <div>
      <mat-form-field class="view-filter mr-2 w-auto" appearance="fill">
        <mat-label>Recherche</mat-label>
        <input matInput placeholder="Nom" (keyup)="applyFilter($event)"/>
      </mat-form-field>
      <mat-form-field class="view-filter mr-2 w-auto" style="height: 45px" appearance="fill">
        <mat-label>Type</mat-label>
        <mat-select [(ngModel)]="selectedType" (selectionChange)="filterRelease()" multiple appearance="fill" >
          <mat-option *ngFor="let type of typesArray"  [value]="type.name">
            {{type.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="view-filter mr-2 w-auto" style="height: 45px" appearance="fill">
        <mat-label>Statut</mat-label>
        <mat-select [(ngModel)]="selectedActive" (selectionChange)="filterRelease()"  appearance="fill" >
          <mat-option value="all">Tous</mat-option>
          <mat-option value="true">En cours</mat-option>
          <mat-option value="false">Terminée</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <button id="btn-add-concession" class="mr-3 btn btn-dark" (click)="onCreate()">
      <mat-icon>add_circle</mat-icon> Release
    </button>
  </div>
</div>
<div class="example-container">
  <table mat-table [dataSource]="dataSource" matSort (matSortChange)="announceSortChange($event)" matSortActive="type" matSortDirection="asc" multiTemplateDataRows class="user-table mat-elevation-z8">
    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef class="text-center" mat-sort-header>Type</th>
      <td mat-cell *matCellDef="let element" class="text-center">{{element.type}}</td>
    </ng-container>

    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef class="text-center" mat-sort-header>Date</th>
      <td mat-cell *matCellDef="let element" class="text-center">{{element.date | date: 'dd/MM/yyyy'}}</td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef class="text-center" mat-sort-header>Titre</th>
      <td mat-cell *matCellDef="let element" class="text-center">{{element.name}}</td>
    </ng-container>

    <ng-container matColumnDef="current">
      <th mat-header-cell *matHeaderCellDef class="text-center" mat-sort-header>En cours</th>
      <td mat-cell *matCellDef="let element" class="text-center">
        <mat-icon *ngIf="element.currentRelease">check</mat-icon>
        <mat-icon *ngIf="!element.currentRelease">cancel</mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="update">
      <th mat-header-cell *matHeaderCellDef class="text-center"></th>
      <td mat-cell *matCellDef="let element" class="text-center">
        <mat-icon (click)="onUpdate(element.id)" class="pointer" >edit</mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="delete">
      <th mat-header-cell *matHeaderCellDef class="text-center"></th>
      <td mat-cell *matCellDef="let element" class="text-center">
        <mat-icon class="pointer" (click)="onDelete(element.id);stopPropagation($event)">delete</mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="expand">
      <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
      <td mat-cell *matCellDef="let element">
        <button mat-icon-button aria-label="expand row" (click)="(expandedElement = expandedElement === element ? null : element); $event.stopPropagation()">
          <mat-icon *ngIf="expandedElement !== element">keyboard_arrow_down</mat-icon>
          <mat-icon *ngIf="expandedElement === element">keyboard_arrow_up</mat-icon>
        </button>
      </td>
    </ng-container>
    <ng-container matColumnDef="expandedDetail">
      <!--      <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>-->
      <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns?.length">
        <div class="example-element-detail d-inline-flex" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
          <div *ngFor="let version of element.versions" class="d-flex align-items-center flex-column mr-3">
            <span class="font-weight-bold">{{version.application}}</span>
            <span class="">{{version.value}}</span>
          </div>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let element; columns: displayedColumns;"
        class="example-element-row pointer"
        [class.example-expanded-row]="expandedElement === element"
        (click)="expandedElement = expandedElement === element ? null : element"
    >
    </tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
  </table>
  <mat-paginator [pageSizeOptions]="[20, 50]" class="user-table mb-3"></mat-paginator>
</div>
