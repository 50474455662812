import { Component, OnInit } from '@angular/core';
import { DataCreationNouvelleService } from '../../utils/data-services/dataCreationNouvelle.service';
import {DataInteractionService} from '../../utils/data-services/dataInteraction.service';
import {DatePipe} from '@angular/common';
import {Router} from '@angular/router';
import swal from 'sweetalert2';
import {Check} from './check';
import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {DataConsultationService} from '../../utils/data-services/dataConsultation.service';
import {FormBuilder} from '@angular/forms';

@Component({
  selector: 'app-historique',
  templateUrl: './creationNouvelle.component.html',
  styleUrls: ['./creationNouvelle.component.css'],
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'fr-FR'},
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
  ],
})
export class CreationNouvelleComponent implements OnInit {
  /* CreationNouvelle page display variables */
  numberIdConcession: any;
  numberIdProduit: any;
  numberIdParametre: any;
  parameterId: any;
  currentConcession = [];
  currentProduct = [];
  currentParameter = [];
  concessions: any;
  products: any;
  parameters = {
    default: [],
    filtered: [],
  };
  /* End */
  datepickerData2: any;
  datepickerData1: any;
  textId: any;
  variable: any;
  // variableCheck: Check[] = [];
  arrayMarque: any;
  selectedMarqueId: number = null;
  // selectedParametreId: number = null;
  form: any;

  // tslint:disable-next-line:max-line-length
  constructor(private dataCreationService: DataCreationNouvelleService,
              private dataInteractionService: DataInteractionService,
              private route: Router,
              private _adapter: DateAdapter<any>,
              private dataConsultationService: DataConsultationService,
              private formBuilder: FormBuilder
  ) {
    this.form = this.formBuilder.group({
      concession: sessionStorage.getItem('softgateConcessionIdVariable'),
      product: sessionStorage.getItem('softgateProduitIdVariable'),
      parameters: sessionStorage.getItem('softgateParametreList'),
      brand: 1,
      value: '',
      startDate: '',
      endDate: ''
    });
  }


  ngOnInit() {
    this.numberIdConcession = localStorage.getItem('softgateConcessionIdVariable');
    this.numberIdProduit = localStorage.getItem('softgateProduitIdVariable');
    this.numberIdParametre = JSON.parse(localStorage.getItem('softgateParametreList'));
    this.dataCreationService.getNomConcessionSelected(this.numberIdConcession).subscribe(data1 => { this.currentConcession = data1.records; });
    this.dataCreationService.getNomProduitSelected(this.numberIdProduit).subscribe(data2 => { this.currentProduct = data2.records; });
    // this.dataCreationService.getNomParametreSelected(this.numberIdParametre).subscribe(data3 => { this.currentParameter = data3.records; });
    /* Fill DropDownLists */
    this.dataConsultationService.getProduit().subscribe(dataProduit => {
      this.products = dataProduit.records;
    });

    this.dataConsultationService.getParametre().subscribe(dataParametre => {

    this.parameters.default = dataParametre.records;
    this.filterParameterByProduct();
    });
    this.dataConsultationService.getConcession().subscribe(dataConcession => {
      this.concessions = dataConcession.records;
    });
    this.datepickerData1 = new Date();
    this.dataCreationService.getMarque().subscribe(dataMarque => { this.arrayMarque = dataMarque.records; });
  }

  SelectChangeHandlerMarque($event: any) {
    this.selectedMarqueId = $event.target.value;
  }
  filterParameterByProduct(): void {
    this.parameters.filtered = this.parameters.default.filter((params) => params.produit_id == this.numberIdProduit);
    this.parameters.filtered = this.parameters.filtered.length === 0 ? this.parameters.default : this.parameters.filtered;
  }
  changeParameterId(pa): void {
    this.parameterId = pa;
  }
  /***
   * Create a new value
   * Create a new releation between value and brand
   ***/
  SelectChangeHandlerCreer(post) {
    if (this.datepickerData1 > this.datepickerData2) {
      swal.fire({
        title: 'Attention!',
        text: 'La date de fin doit être supérieure à la date de début.',
        type: 'warning',
        confirmButtonText: 'Ok'
      });
    } else {
      // tslint:disable-next-line:max-line-length
      this.parameterId = this.numberIdParametre.length == 1 ? this.numberIdParametre[0].parametre_id : this.parameterId;
      this.dataCreationService.createValeur(
        post.value,
        post.startDate,
        post.endDate,
        this.parameterId,
        this.numberIdConcession,
        this.selectedMarqueId
      ).then(
        response => {
          if (response) {
            swal.fire({
              title: 'Success!',
              text: 'La valeur a bien été Créée',
              type: 'success',
              confirmButtonText: 'Ok'
            });
            this.route.navigate(['../consultation']);
          }
        });
    }
  }

  SelectChangeHandlerAnnuler() {
    this.route.navigate(['../consultation']);
  }

  setInStorage(id: any, item: string): void {
    switch (item) {
      case 'concession':
        localStorage.setItem('softgateConcessionIdVariable', id);
        this.numberIdConcession = id ;
        this.dataCreationService.getNomConcessionSelected(this.numberIdConcession).subscribe(data1 => { this.currentConcession = data1.records; });
        break;
      case 'product':
        localStorage.setItem('softgateProduitIdVariable', id);
        this.numberIdProduit = id ;
        this.dataCreationService.getNomProduitSelected(this.numberIdProduit).subscribe(data2 => { this.currentProduct = data2.records; });
        break;
      case 'parameter':
        localStorage.setItem('softgateParametreList', id);
        this.numberIdParametre = id ;
        break;
    }
  }
}
