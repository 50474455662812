import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {HttpHeaders} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {catchError} from 'rxjs/operators';
import {HandleErrorService} from '../handleError.service';

@Injectable({
  providedIn: 'root'
})
export class DataMaintenancesService extends HandleErrorService {

  syncUcar() {
    const url = environment.syncUcarUrl;
    return this.http.get<any>(url).pipe(
      catchError(this.handleError)
    );
  }
  anonymizationPngo() {
    const url = environment.anonymizationPngoUrl;
    return this.http.get<any>(url).pipe(
      catchError(this.handleError)
    );
  }
  fixFirstDatePngo() {
    const url = environment.fixFirstDatePngoUrl;
    return this.http.get<any>(url).pipe(
      catchError(this.handleError)
    );
  }
  ipsMonitoringPngo() {
    const url = environment.ipsMonitoringPngoUrl;
    return this.http.get<any>(url).pipe(
      catchError(this.handleError)
    );
  }
  verifyScenarioPngo(id) {
    const url = environment.pngoApi + 'api/planning/snapshots/verify/scenario/' + id;
    return this.http.get<any>(url).pipe(
      catchError(this.handleError)
    );
  }
}
