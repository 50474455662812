<h1 class="text-center mt-2">Gestion des sms actifs</h1>
<div class="user-table">
  <div class="container-row top-field ml-4">
    <mat-form-field>
      <mat-label>Rechercher</mat-label>
      <input [(ngModel)]="search" matInput (keyup)="applyFilter($event)">
    </mat-form-field>
    <div class="btn1">
      <button class="btn btn-dark d-flex" (click)="exportExcel()">
        <mat-icon class="mr-1">file_copy</mat-icon> <span>Export</span>
      </button>
    </div>
  </div>
  <div class="d-flex justify-content-between ml-4">
    <div class="d-flex">
      <mat-form-field class="mr-2" appearance="fill" style="width: 100px">
        <mat-label>SMS</mat-label>
        <mat-select placeholder="SMS" [formControl]="smsFormControl" multiple (selectionChange)="filterBySms()">
          <mat-option *ngFor="let sms of smsArray" [value]="sms.nom">
            {{sms.nom}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="mr-2" appearance="fill" style="width: 150px">
        <mat-label>Marques</mat-label>
        <mat-select placeholder="Marques" [formControl]="brandFormControl" multiple (selectionChange)="filterBySms()">
          <mat-option *ngFor="let brand of brandArray" [value]="brand.nom">
            {{brand.nom}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="mr-2" appearance="fill" style="width: 100px">
        <mat-label>Concession Active</mat-label>
        <mat-select placeholder="Actif" [formControl]="activeFormControl" multiple (selectionChange)="filterBySms()">
          <mat-option [value]="true">Oui</mat-option>
          <mat-option [value]="false">Non</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-slide-toggle labelPosition="before" class="pt-3" (change)="changeOwnSms()" checked>SMS actifs</mat-slide-toggle>
    </div>
    <div class="d-flex">
      <span class="mr-3" style="color: #42A948; height: fit-content" matTooltip="{{this.dataSource.data.length}} concessions possèdent {{smsFormControl.value}}">{{this.dataSource.data.length}}</span>
      <span class="mr-3" style="color: #ca2222; height: fit-content" matTooltip="{{this.originalDataSource?.length-this.dataSource.data.length}} concessions ne possèdent pas {{smsFormControl.value}}">{{this.originalDataSource?.length-this.dataSource.data.length}}</span>
    </div>
  </div>
</div>

<div class="example-container">
  <table mat-table [dataSource]="dataSource" class="user-table mat-elevation-z8" matSort>

    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef> ID </th>
      <td mat-cell *matCellDef="let element" class="sms-row"> {{element.id}} </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> Nom </th>
      <td mat-cell *matCellDef="let element" class="sms-row"> {{element.name}} </td>
    </ng-container>

    <ng-container matColumnDef="town">
      <th mat-header-cell *matHeaderCellDef> Ville </th>
      <td mat-cell *matCellDef="let element" class="sms-row"> {{element.town}} </td>
    </ng-container>

    <ng-container matColumnDef="confirmation">
      <th mat-header-cell *matHeaderCellDef > Confirmation </th>
      <td mat-cell *matCellDef="let element" class="sms-row">
        <mat-icon *ngIf="dealershipHasSms(element.sms, 'Confirmation')" class="active-sms">check</mat-icon>
        <mat-icon *ngIf="!dealershipHasSms(element.sms, 'Confirmation')" class="inactive-sms">close</mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="onlineConfirmation">
      <th mat-header-cell *matHeaderCellDef > Confirmation online </th>
      <td mat-cell *matCellDef="let element" class="sms-row">
        <mat-icon *ngIf="dealershipHasSms(element.sms, 'Confirmation online')" class="active-sms">check</mat-icon>
        <mat-icon *ngIf="!dealershipHasSms(element.sms, 'Confirmation online')" class="inactive-sms">close</mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="reminder">
      <th mat-header-cell *matHeaderCellDef > Rappel </th>
      <td mat-cell *matCellDef="let element" class="sms-row">
        <mat-icon *ngIf="dealershipHasSms(element.sms, 'Rappel')" class="active-sms">check</mat-icon>
        <mat-icon *ngIf="!dealershipHasSms(element.sms, 'Rappel')" class="inactive-sms">close</mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="reception">
      <th mat-header-cell *matHeaderCellDef > Réception </th>
      <td mat-cell *matCellDef="let element">
        <mat-icon *ngIf="dealershipHasSms(element.sms, 'Réception')" class="active-sms">check</mat-icon>
        <mat-icon *ngIf="!dealershipHasSms(element.sms, 'Réception')" class="inactive-sms">close</mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="suspended">
      <th mat-header-cell *matHeaderCellDef > Suspendu </th>
      <td mat-cell *matCellDef="let element" class="sms-row">
        <mat-icon *ngIf="dealershipHasSms(element.sms, 'Suspendu')" class="active-sms">check</mat-icon>
        <mat-icon *ngIf="!dealershipHasSms(element.sms, 'Suspendu')" class="inactive-sms">close</mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="delay">
      <th mat-header-cell *matHeaderCellDef > Retard </th>
      <td mat-cell *matCellDef="let element" class="sms-row">
        <mat-icon *ngIf="dealershipHasSms(element.sms, 'Retard')" class="active-sms">check</mat-icon>
        <mat-icon *ngIf="!dealershipHasSms(element.sms, 'Retard')" class="inactive-sms">close</mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="finished">
      <th mat-header-cell *matHeaderCellDef > Terminé </th>
      <td mat-cell *matCellDef="let element" class="sms-row">
        <mat-icon *ngIf="dealershipHasSms(element.sms, 'Terminés')" class="active-sms">check</mat-icon>
        <mat-icon *ngIf="!dealershipHasSms(element.sms, 'Terminés')" class="inactive-sms">close</mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="simple-marketing">
      <th mat-header-cell *matHeaderCellDef > Marketing 1 </th>
      <td mat-cell *matCellDef="let element" class="sms-row">
        <mat-icon *ngIf="dealershipHasSms(element.sms, 'Marketing 1')" class="active-sms">check</mat-icon>
        <mat-icon *ngIf="!dealershipHasSms(element.sms, 'Marketing 1')" class="inactive-sms">close</mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="smart-marketing">
      <th mat-header-cell *matHeaderCellDef > Marketing 2 </th>
      <td mat-cell *matCellDef="let element" class="sms-row">
        <mat-icon *ngIf="dealershipHasSms(element.sms, 'Marketing 2')" class="active-sms">check</mat-icon>
        <mat-icon *ngIf="!dealershipHasSms(element.sms, 'Marketing 2')" class="inactive-sms">close</mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="thanking">
      <th mat-header-cell *matHeaderCellDef > Remerciement </th>
      <td mat-cell *matCellDef="let element" class="sms-row">
        <mat-icon *ngIf="dealershipHasSms(element.sms, 'Remerciement')" class="active-sms">check</mat-icon>
        <mat-icon *ngIf="!dealershipHasSms(element.sms, 'Remerciement')" class="inactive-sms">close</mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="softgate">
      <th mat-header-cell *matHeaderCellDef > Soft-gate </th>
      <td mat-cell *matCellDef="let element" class="sms-row">
        <mat-icon *ngIf="dealershipHasSms(element.sms, 'Soft-gate')" class="active-sms">check</mat-icon>
        <mat-icon *ngIf="!dealershipHasSms(element.sms, 'Soft-gate')" class="inactive-sms">close</mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="excel-campaign">
      <th mat-header-cell *matHeaderCellDef > Campagne Excel +€</th>
      <td mat-cell *matCellDef="let element" class="sms-row">
        <mat-icon *ngIf="dealershipHasSms(element.sms, 'Campagne Excel +€')" class="active-sms">check</mat-icon>
        <mat-icon *ngIf="!dealershipHasSms(element.sms, 'Campagne Excel +€')" class="inactive-sms">close</mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="excel-campaign-better-price">
      <th mat-header-cell *matHeaderCellDef > Campagne Excel -€ </th>
      <td mat-cell *matCellDef="let element" class="sms-row">
        <mat-icon *ngIf="dealershipHasSms(element.sms, 'Campagne Excel -€')" class="active-sms">check</mat-icon>
        <mat-icon *ngIf="!dealershipHasSms(element.sms, 'Campagne Excel -€')" class="inactive-sms">close</mat-icon>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <mat-paginator [pageSizeOptions]="getPageSizeOptions()"
                 (click)="displayPaginatorAllOption()"
                 (page)="pageChange()"
                 showFirstLastButtons
                 aria-label="Sélectionner la page">
  </mat-paginator>
</div>

