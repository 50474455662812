import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DataDoraService} from '../../utils/data-services/data-dora.service';
import {MatDialog} from '@angular/material/dialog';
import {AddBySelectDialogComponent} from './add-by-select-dialog/add-by-select-dialog.component';
import swal from 'sweetalert2';

@Component({
  selector: 'app-modification-concession-dora',
  templateUrl: './modification-concession-dora.component.html',
  styleUrls: ['./modification-concession-dora.component.scss']
})
export class ModificationConcessionDoraComponent implements OnInit {

  id = null;
  concession = null;
  projects = [];
  brands = [];

  constructor(
    private activeRoute: ActivatedRoute,
    private dataDoraService: DataDoraService,
    private route: Router,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.id = Number(this.activeRoute.snapshot.paramMap.get('id'));
    this.getData();
  }
  getData(): any {
    this.dataDoraService.getDealershipById(this.id).subscribe(res => {
      this.concession = res.concession;
      this.concession.projects.sort((a, b) => {return a > b ? 1 : -1});
      this.projects = res.projects;
      this.brands = res.brands;
    });
  }
  onOpenDialogSelect(type): any {
    const dialogRef = this.dialog.open(AddBySelectDialogComponent, {
      data: {
        type: type == 'project' ? 'Projets' : 'Marques',
        values: type == 'project' ? this.projects : this.brands,
        dealership: this.id
      },
      autoFocus: true,
      minWidth: '500px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == 'success') {
        this.getData();
      }
    });
  }
}
