import {Component, OnInit, ViewChild} from '@angular/core';
import {DataFaqService} from '../dataFaq.service';
import {MatTableDataSource} from '@angular/material/table';
import {FormBuilder} from '@angular/forms';
import swal from 'sweetalert2';
import {MatPaginator} from "@angular/material/paginator";

@Component({
  selector: 'app-gestion-faq-themes',
  templateUrl: './gestion-faq-themes.component.html',
  styleUrls: ['./gestion-faq-themes.component.scss']
})
export class GestionFaqThemesComponent implements OnInit {
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  displayedColumns: string[] = [
    'id',
    'name',
    'edit',
    'delete'
  ];
  dataSource = new MatTableDataSource<any>();
  isDataSourceExists = false;
  form: any;
  isEdit = false;
  search: any;

  constructor(
    private dataFaqService: DataFaqService,
    private formBuilder: FormBuilder,
  ) {
    this.form = this.formBuilder.group({
      id: null,
      name: '',
    });
  }

  ngOnInit() {
    this.getAllThemes();
    this.paginator.pageIndex = localStorage.getItem('pageIndexTheme') != null ? parseInt(localStorage.getItem('pageIndexTheme')) : null;
    this.paginator.pageSize = localStorage.getItem('pageSizeTheme') != null ? parseInt(localStorage.getItem('pageSizeTheme')) : null;
    this.dataSource.paginator = this.paginator;
  }
  getAllThemes(): any {
    this.dataFaqService.getAllGrandThemes().subscribe(themes => {
      this.dataSource.data = themes.records;
      if (themes.records.length > 0) {
        this.isDataSourceExists = true;
      }
      this.search = localStorage.getItem('themeFilter') != null ? localStorage.getItem('themeFilter') : '';
      this.dataSource.filter = this.search.trim().toLowerCase();
    });
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    localStorage.setItem('themeFilter', filterValue);
    this.dataSource.filter = filterValue.trim();
  }
  onSubmit(post, methode): any {
    if (methode === 'create') {
      this.dataFaqService.postGrandTheme(post).subscribe(() => {
        this.getAllThemes();
        this.form.controls.name.setValue('');
      });
    } else {
      this.dataFaqService.updateGrandTheme(post.id, post).subscribe(() => {
        this.getAllThemes();
        this.onCancelEdit();
      });
    }
  }
  onEdit(elementId, elementName): any {
    this.isEdit = true;
    this.form.controls.id.setValue(elementId);
    this.form.controls.name.setValue(elementName);
  }
  onCancelEdit(): any {
    this.isEdit = false;
    this.form.controls.id.setValue(null);
    this.form.controls.name.setValue('');
  }
  onDelete(elementId): any {
    swal.fire({
      title: 'Attention!',
      text: 'Êtes-vous sûr de vouloir supprimer?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'Annuler'
    }).then((result) => {
      if (result.value) {
        this.dataFaqService.deleteGrandTheme(elementId).subscribe(() => {
          this.getAllThemes();
        });
      }
    });
  }
  getPageSizeOptions(): number[] {
    if (this.dataSource && this.dataSource.data.length > 100 && this.dataSource.paginator) {
      return [20, 50, 100,  this.dataSource.paginator.length];
    } else {
      return [20, 50, 100];
    }
  }
  displayPaginatorAllOption() {
    const matOptions = document.getElementsByClassName('mat-option-text');
    // @ts-ignore
    for (const options of matOptions) {
      options.innerHTML = options.innerHTML == this.dataSource.data.length ? 'Tous' : options.innerHTML;
    }
  }
  pageChange() {
    localStorage.setItem('pageIndexTheme', String(this.paginator.pageIndex));
    localStorage.setItem('pageSizeTheme', String(this.paginator.pageSize));
  }
}
