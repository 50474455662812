<!-- page title area end -->
<div class="main-content-inner mt-5">
  <h1 class="text-center">Gestion des règles</h1>
  <div class="header-rules-action user-table">
    <div class="d-flex justify-content-between">
      <button type="button" id="btn-add-param" class="btn btn-dark" routerLink="/creation-regle">
        <mat-icon>add_circle</mat-icon> Ajouter une règle
      </button>
      <mat-form-field style="width: 250px">
        <mat-label>Recherche</mat-label>
        <input [(ngModel)]="search" matInput (keyup)="applyFilter($event)">
      </mat-form-field>
    </div>
  </div>
  <table class="mat-elevation-z8 user-table mt-2" mat-table [dataSource]="dataSource" style="width: 90%">
    <ng-container matColumnDef="title">
      <th class="text-center" mat-header-cell *matHeaderCellDef> Titre </th>
      <td class="text-center" mat-cell *matCellDef="let element" [ngStyle]="{'border-left': 'solid' + element.color}">
        <div>{{element.title}}</div>
        <div>
          <mat-icon  matTooltipPosition="above" matTooltip="Description Softnrg : {{element.description_softnrg}}" style="transform: scale(0.6); color: rgba(61, 117, 175, 0.6)" class="align-bottom">info</mat-icon>
          <mat-icon  matTooltipPosition="above" matTooltip="Description KIDI : {{element.description_kidi}}" style="transform: scale(0.6); color: rgba(0,0,0,0.3)" class="align-bottom">info</mat-icon>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="firstField">
      <th class="text-center" mat-header-cell *matHeaderCellDef> Critère 1 </th>
      <td class="text-center" mat-cell *matCellDef="let element"> {{element.first_field}} </td>
    </ng-container>
    <ng-container matColumnDef="firstCompOperator">
      <th class="text-center" mat-header-cell *matHeaderCellDef> Opérateur 1</th>
      <td class="text-center" mat-cell *matCellDef="let element"> {{element.first_comparison_operator}} </td>
    </ng-container>
    <ng-container matColumnDef="firstValue">
      <th class="text-center" mat-header-cell *matHeaderCellDef> Valeur 1 </th>
      <td class="text-center" mat-cell *matCellDef="let element">
        <span *ngIf="element.first_value == 1">Rendez-vous fixé</span>
        <span *ngIf="element.first_value == 2">Travaux en cours</span>
        <span *ngIf="element.first_value == 3">Travaux terminés</span>
        <span *ngIf="element.first_value == 4">Véhicule restitué</span>
        <span *ngIf="element.first_value == 5">Travaux suspendus</span>
        <span *ngIf="!['1', '2', '3', '4', '5'].includes(element.first_value)">{{element.first_value}}</span>
      </td>
    </ng-container>
    <ng-container matColumnDef="logicalOperator">
      <th class="text-center" mat-header-cell *matHeaderCellDef> Condition </th>
      <td class="text-center" mat-cell *matCellDef="let element"> {{element.logical_operator}} </td>
    </ng-container>
    <ng-container matColumnDef="secondField">
      <th class="text-center" mat-header-cell *matHeaderCellDef> Critère 2 </th>
      <td class="text-center" mat-cell *matCellDef="let element"> {{element.second_field}} </td>
    </ng-container>
    <ng-container matColumnDef="secondCompOperator">
      <th class="text-center" mat-header-cell *matHeaderCellDef> Opérateur 2 </th>
      <td class="text-center" mat-cell *matCellDef="let element"> {{element.second_comparison_operator}} </td>
    </ng-container>
    <ng-container matColumnDef="secondValue">
      <th class="text-center" mat-header-cell *matHeaderCellDef> Valeur 2 </th>
      <td class="text-center" mat-cell *matCellDef="let element">
        <span *ngIf="element.second_value == 1">Rendez-vous fixé</span>
        <span *ngIf="element.second_value == 2">Travaux en cours</span>
        <span *ngIf="element.second_value == 3">Travaux terminés</span>
        <span *ngIf="element.second_value == 4">Véhicule restitué</span>
        <span *ngIf="element.second_value == 5">Travaux suspendus</span>
        <span *ngIf="!['1', '2', '3', '4', '5'].includes(element.second_value)">{{element.second_value}}</span>
      </td>
    </ng-container>
    <ng-container matColumnDef="recurrenceStart">
      <th class="text-center" mat-header-cell *matHeaderCellDef> Différé </th>
      <td class="text-center" mat-cell *matCellDef="let element">
        <mat-icon *ngIf="element.recurrent_start == '1'">check</mat-icon>
        <mat-icon *ngIf="element.recurrent_start != '1'">close</mat-icon>
      </td>
    </ng-container>
    <ng-container matColumnDef="day">
      <th class="text-center" mat-header-cell *matHeaderCellDef> jour(s) </th>
      <td class="text-center" mat-cell *matCellDef="let element"> {{element.day}} </td>
    </ng-container>
    <ng-container matColumnDef="dateNotification">
      <th class="text-center" mat-header-cell *matHeaderCellDef> Commencement </th>
      <td class="text-center" mat-cell *matCellDef="let element"> {{element.date_notification}} </td>
    </ng-container>
    <ng-container matColumnDef="recurrence">
      <th class="text-center" mat-header-cell *matHeaderCellDef> Récurrence </th>
      <td class="text-center" mat-cell *matCellDef="let element">
        <mat-icon *ngIf="element.is_recurrent == 'true'">check</mat-icon>
        <mat-icon *ngIf="element.is_recurrent != 'true'">close</mat-icon>
      </td>
    </ng-container>
    <ng-container matColumnDef="isActive">
      <th class="text-center" mat-header-cell *matHeaderCellDef> Actif </th>
      <td class="text-center" mat-cell *matCellDef="let element">
        <mat-icon *ngIf="element.is_active == 'true'">check</mat-icon>
        <mat-icon *ngIf="element.is_active != 'true'">close</mat-icon>
      </td>
    </ng-container>
    <ng-container matColumnDef="priority">
      <th class="text-center" mat-header-cell *matHeaderCellDef> Priorité </th>
      <td class="text-center" mat-cell *matCellDef="let element"> {{element.priority}} </td>
    </ng-container>
    <ng-container matColumnDef="edit">
      <th class="text-center" mat-header-cell *matHeaderCellDef> Editer </th>
      <td class="text-center pointer-event" mat-cell *matCellDef="let element" (click)="editRule(element.id)" style="cursor: pointer; width: 50px"><mat-icon>edit</mat-icon></td>
    </ng-container>
    <ng-container matColumnDef="delete">
      <th class="text-center" mat-header-cell *matHeaderCellDef> Supprimer </th>
      <td class="text-center pointer-event" mat-cell *matCellDef="let element" (click)="onDelete(element.id)" style="cursor: pointer; color: red; width: 30px"><mat-icon>delete</mat-icon></td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <div *ngIf="!isDataSourceExists" style="display: flex;justify-content: center;padding: 25px; align-items: center">
    <span class="material-icons" style="padding: 5px">error</span> Aucun résultat trouvé.
  </div>
  <mat-paginator [pageSizeOptions]="getPageSizeOptions()"
                 (click)="displayPaginatorAllOption()"
                 (page)="pageChange()"
                 showFirstLastButtons
                 aria-label="Sélectionner la page">
  </mat-paginator>
</div>
