import { Component, OnInit } from '@angular/core';
import {DataConfigurationConcessionService} from "../../utils/data-services/data-configuration-concession.service";
import {Router} from "@angular/router";
import moment from "moment/moment";

@Component({
  selector: 'app-create-default-configuration',
  templateUrl: './create-default-configuration.component.html',
  styleUrls: ['./create-default-configuration.component.scss']
})
export class CreateDefaultConfigurationComponent implements OnInit {
  release = {
    id: null,
    date: null,
    name: null,
    typeId: null,
    currentRelease: true,
    versions: []
  };
  releaseVersion = {
    application: null,
    value: null,
    type: 'dealership_configuration',
    releaseId: null,
    actif: false
  };
  configurationArray = {
    default: null,
    filtered: null
  };
  typeArray = [];
  constructor( private dataService: DataConfigurationConcessionService, private router: Router ) { }

  ngOnInit() {
    this.getConfiguration();
    this.getType();
  }
  getConfiguration() {
    this.dataService.getConfiguration('name').subscribe(result => {
      this.configurationArray.default = result.filter((configuration) => configuration.name.includes('Version'));
      this.configurationArray.filtered = result.filter((configuration) => configuration.name.includes('Version'));
      this.filterConfiguration();
    });
  }
  getType() {
    this.dataService.getReleaseType().subscribe(result => {
      this.typeArray = result;
    });
  }
  getRelease(releaseId) {
    this.dataService.getReleaseById(releaseId).subscribe(result => {
      this.release = result;
      this.release.date = moment(this.release.date.date).format('yyyy-MM-DD');
      this.filterConfiguration();
    });
  }
  addVersion() {
    this.releaseVersion.releaseId = this.release.id;
    this.dataService.createDefaultConfiguration(this.releaseVersion).subscribe(result => {
      this.getRelease(this.release.id);
      this.filterConfiguration();
    });
  }
  deleteVersion(id) {
    this.dataService.deleteDefaultConfiguration(id).subscribe(result => {
      this.getRelease(this.release.id);
      this.filterConfiguration();
    });
  }
  onCreate() {
    this.dataService.createRelease(this.release).subscribe(result => {
      this.release.id = result;
    });
  }
  onCancel() {
    this.router.navigate(['/gestion-release']);
  }
  onOk() {
    for (const version of this.release.versions) {
      this.dataService.updateDefaultConfiguration(version, version.id).subscribe();
    }
    this.router.navigate(['/gestion-release']);
  }
  comparer(o1: any, o2: any): boolean {
    // if possible compare by object's name, and not by reference.
    return o1 && o2 ? o1 === o2 : o2 === o2;
  }
  filterConfiguration() {
    const applications = this.release.versions.map(version => version.application);
    this.configurationArray.filtered = this.configurationArray.default.filter(item => !applications.includes(item.name));
    this.releaseVersion = {
      application: null,
      value: null,
      type: 'dealership_configuration',
      releaseId: null,
      actif: false
    };
    this.configurationArray.filtered = [...this.configurationArray.filtered]
  }
}
