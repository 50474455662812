import {Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {DataFaqService} from '../dataFaq.service';
import {Router} from '@angular/router';
import swal from 'sweetalert2';
import {MatPaginator} from "@angular/material/paginator";

@Component({
  selector: 'app-gestion-faq-questions',
  templateUrl: './gestion-faq-questions.component.html',
  styleUrls: ['./gestion-faq-questions.component.scss']
})
export class GestionFaqQuestionsComponent implements OnInit {
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  displayedColumns: string[] = [
    'id',
    'question',
    'edit',
    'delete'
  ];
  dataSource = new MatTableDataSource<any>();
  isDataSourceExists = false;
  search: any;

  constructor(
    private dataFaqService: DataFaqService,
    private route: Router
  ) { }

  ngOnInit() {
    this.getQuestions();
    this.paginator.pageIndex = localStorage.getItem('pageIndexQuestion') != null ? parseInt(localStorage.getItem('pageIndexQuestion')) : null;
    this.paginator.pageSize = localStorage.getItem('pageSizeQuestion') != null ? parseInt(localStorage.getItem('pageSizeQuestion')) : null;
    this.dataSource.paginator = this.paginator;
  }
  getQuestions(): any {
    this.dataFaqService.getAllQuestions().subscribe(questions => {
      if (questions.records.length > 0) {
        this.isDataSourceExists = true;
      }
      this.dataSource.data = questions.records;
      this.search = localStorage.getItem('questionFilter') != null ? localStorage.getItem('questionFilter') : '';
      this.dataSource.filter = this.search.trim().toLowerCase();
    });
  }
  onEdit(id): any {
    this.route.navigate(['modification-faq-question/' + id]);
  }
  onDelete(id): any {
    swal.fire({
      title: 'Attention!',
      text: 'Êtes-vous sûr de vouloir supprimer?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'Annuler'
    }).then((result) => {
      if (result.value) {
        this.dataFaqService.deleteQuestion(id).subscribe(() => {
          this.getQuestions();
        });
      }
    });
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    localStorage.setItem('questionFilter', filterValue);
    this.dataSource.filter = filterValue.trim();
  }
  getPageSizeOptions(): number[] {
    if (this.dataSource && this.dataSource.data.length > 100 && this.dataSource.paginator) {
      return [20, 50, 100,  this.dataSource.paginator.length];
    } else {
      return [20, 50, 100];
    }
  }
  displayPaginatorAllOption() {
    const matOptions = document.getElementsByClassName('mat-option-text');
    // @ts-ignore
    for (const options of matOptions) {
      options.innerHTML = options.innerHTML == this.dataSource.data.length ? 'Tous' : options.innerHTML;
    }
  }
  pageChange() {
    localStorage.setItem('pageIndexQuestion', String(this.paginator.pageIndex));
    localStorage.setItem('pageSizeQuestion', String(this.paginator.pageSize));
  }
}
