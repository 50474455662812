import {Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {DataDoraService} from '../utils/data-services/data-dora.service';
import * as CryptoJS from 'crypto-js';
import {Router} from '@angular/router';

@Component({
  selector: 'app-gestion-utilisateur-dora',
  templateUrl: './gestion-utilisateur-dora.component.html',
  styleUrls: ['./gestion-utilisateur-dora.component.scss']
})
export class GestionUtilisateurDoraComponent implements OnInit {

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  secretKey = 'f0-PX&w2RWfoA*yP';
  dataSource = new MatTableDataSource();
  displayedColumns = ['name', 'login', 'password', 'rights', 'action'];
  allData = [];
  search = '';
  selectedRight = 'all';

  constructor(
    private dataDoraService: DataDoraService,
    private route: Router
  ) { }

  ngOnInit() {
    this.paginator.pageIndex = localStorage.getItem('pageIndexDealershipDora') != null ? parseInt(localStorage.getItem('pageIndexDealershipDora')) : null;
    this.paginator.pageSize = localStorage.getItem('pageSizeDealershipDora') != null ? parseInt(localStorage.getItem('pageSizeDealershipDora')) : null;
    this.dataSource.filter = this.search.trim().toLowerCase();
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.getData();
  }
  getData(): any {
    this.dataDoraService.getUsers().subscribe(res => {
      this.allData = res;
      this.dataSource.data = res;
    });
  }
  onEdit(id): any {
    this.route.navigate(['/dora/modification-utilisateur/' + id]);
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  filterDealership(): any {
    this.dataSource.data = this.allData.filter(x => this.selectedRight != 'all' ? x.rights == this.selectedRight : true);
  }
  getPageSizeOptions(): number[] {
    if (this.dataSource && this.dataSource.data.length > 100 && this.dataSource.paginator) {
      return [20, 50, 100,  this.dataSource.paginator.length];
    } else {
      return [20, 50, 100];
    }
  }
  displayPaginatorAllOption() {
    const matOptions = document.getElementsByClassName('mat-option-text');
    // @ts-ignore
    for (const options of matOptions) {
      options.innerHTML = options.innerHTML == this.dataSource.data.length ? 'Tous' : options.innerHTML;
    }
  }
  pageChange() {
    localStorage.setItem('pageIndexDealershipDora', String(this.paginator.pageIndex));
    localStorage.setItem('pageSizeDealershipDora', String(this.paginator.pageSize));
  }
  decryptAES(encryptedData: string): string | null {
    // Convert key to WordArray
    const key = CryptoJS.enc.Utf8.parse(this.secretKey);

    // Decode Base64
    const rawData = CryptoJS.enc.Base64.parse(encryptedData);
    const rawDataWords = rawData.words;

    // Extract IV (first 16 bytes)
    const iv = CryptoJS.lib.WordArray.create(rawDataWords.slice(0, 4));

    // Extract HMAC (next 32 bytes)
    const hmac = CryptoJS.lib.WordArray.create(rawDataWords.slice(4, 12));

    // Extract Ciphertext (remaining bytes)
    const ciphertext = CryptoJS.lib.WordArray.create(rawDataWords.slice(12));

    // Verify HMAC
    const calculatedHmac = CryptoJS.HmacSHA256(ciphertext, key);
    if (!CryptoJS.enc.Hex.stringify(calculatedHmac).startsWith(CryptoJS.enc.Hex.stringify(hmac))) {
      console.error("HMAC verification failed! Data may be tampered.");
      return null;
    }

    // Decrypt
    const decrypted = CryptoJS.AES.decrypt(
      { ciphertext },
      key,
      { iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 }
    );

    return decrypted.toString(CryptoJS.enc.Utf8) || null;
  }
}
