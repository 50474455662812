<h2><span>{{method}}</span> d'une communication</h2>
<div style="display: flex">
  <div class="wrapper" style="position: inherit;padding: 10px;margin: auto;">
    <form [formGroup]="form" class="form">
      <div class="form-row w-75">
        <div class="form-group">
          <label for="title">Titre:</label>
          <input type="text" class="form-control" name="title" id="title" formControlName="title">
          <small *ngIf="error.title" class="input-error" style="color: red">Champ requis</small>
        </div>
      </div>
      <div class="form-row w-75">
        <div class="form-group" *ngIf="isSubjectSelect">
          <label for="subject">Sujet:</label>
          <div class="d-flex">
            <select class="form-control" style="width: 522px;margin-right: 15px;" name="subject" id="subject" formControlName="subject">
              <option *ngFor="let subject of subjects" value="{{subject.label}}">{{subject.label}}</option>
            </select>
            <div><button (click)="onSwitchFieldSubject()" matTooltip="Ajouter un nouveau sujet" class="btn btn-light" style="height: 38px;"><mat-icon>playlist_add</mat-icon></button></div>
          </div>
          <small *ngIf="error.subject" class="input-error" style="color: red">Champ requis</small>
        </div>
        <div class="form-group" *ngIf="!isSubjectSelect">
          <label for="subject">Sujet:</label>
          <div class="d-flex">
            <input type="text" class="form-control" style="width: 522px;margin-right: 15px;" name="subject-text" id="subject-text" formControlName="subject">
            <div><button (click)="onSwitchFieldSubject()" matTooltip="Afficher la liste des sujets" class="btn btn-light" style="height: 38px;"><mat-icon>menu</mat-icon></button></div>
          </div>
          <small *ngIf="error.subject" class="input-error" style="color: red">Champ requis</small>
        </div>
      </div>
      <div class="form-row w-75">
        <div class="form-group">
          <label for="text">Contenu:</label>
          <textarea class="form-control" name="text" id="text" formControlName="text"></textarea>
        </div>
      </div>
      <div class="form-row w-75 mb-3">
        <mat-radio-group aria-label="Select an option" formControlName="target">
          <mat-radio-button value="kidi">KIDI</mat-radio-button>
          <mat-radio-button class="ml-3" value="custom-message">Message instantané</mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="form-row">
        <div class="form-group checkbox-group">
          <input type="checkbox" style="width: 15px;margin-right: 10px;" class="form-control checkbox" name="is_active" id="is_active" formControlName="is_active">
          <label for="is_active" style="margin: 0;">Active</label>
        </div>

      </div>
      <div class="w-100 d-flex">
        <div>
          <button type="button" class="btn btn-dark btn100px btn-create p-2 mt-3 mr-2" *ngIf="method == 'Création'" (click)="onSubmit(form.value)">Créer</button>
          <button type="button" class="btn btn-dark btn100px btn-create p-2 mt-3 mr-2" *ngIf="method != 'Création'" (click)="onSubmit(form.value)">Modifier</button>
        </div>
        <div>
          <button class="btn btn-light btn100px border-dark btn-create p-2 mt-3" type="button" routerLink="/gestion-communication">Annuler</button>
        </div>
      </div>
    </form>
  </div>
</div>

