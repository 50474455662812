import { Injectable } from '@angular/core';
import {HandleErrorService} from "../handleError.service";
import {environment} from "../../../environments/environment";
import {catchError} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class DataConfigurationConcessionService extends HandleErrorService {
  getDealershipConfiguration() {
    const apiConfigurationConcession = environment.globalApiUrl + 'api/dealership-configuration';
    return this.http.get<any>(apiConfigurationConcession, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  getDealershipConfigurationById(id) {
    const apiConfigurationConcession = environment.globalApiUrl + 'api/dealership-configuration-by-id/' + id;
    return this.http.get<any>(apiConfigurationConcession, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  updateDealershipConfiguration(body, id) {
    const apiConfigurationConcession = environment.globalApiUrl + 'api/dealership-configuration/update/' + id;
    return this.http.post<any>(apiConfigurationConcession, body, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  getConfiguration(sort?: string) {
    const apiConfiguration = environment.globalApiUrl + 'api/configuration/get/' + sort;
    return this.http.get<any>(apiConfiguration, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  getConfigurationById(id) {
    const apiConfiguration = environment.globalApiUrl + 'api/configuration-by-id/' + id;
    return this.http.get<any>(apiConfiguration, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  createConfiguration(body) {
    const apiConfiguration = environment.globalApiUrl + 'api/configuration/create';
    return this.http.post<any>(apiConfiguration, body, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  updateConfiguration(body, id) {
    const apiConfiguration = environment.globalApiUrl + 'api/configuration/update/' + id;
    return this.http.post<any>(apiConfiguration, body, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  deleteConfiguration(id) {
    const apiConfigurationConcession = environment.globalApiUrl + 'api/configuration/delete/' + id;
    return this.http.delete<any>(apiConfigurationConcession, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  getBmwCorrespondent() {
    const apiConfigurationConcession = environment.globalApiUrl + 'api/bmw-correspondent';
    return this.http.get<any>(apiConfigurationConcession, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  getDefaultConfigurationList() {
    const apiConfigurationConcession = environment.globalApiUrl + 'api/default-configuration-list';
    return this.http.get<any>(apiConfigurationConcession, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  getDefaultConfiguration() {
    const apiConfigurationConcession = environment.globalApiUrl + 'api/default-configuration';
    return this.http.get<any>(apiConfigurationConcession, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  getDefaultConfigurationById(id) {
    const apiConfiguration = environment.globalApiUrl + 'api/default-configuration-by-id/' + id;
    return this.http.get<any>(apiConfiguration, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  createDefaultConfiguration(body) {
    const apiConfiguration = environment.globalApiUrl + 'api/default-configuration/create';
    return this.http.post<any>(apiConfiguration, body, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  updateDefaultConfiguration(body, id) {
    const apiConfiguration = environment.globalApiUrl + 'api/default-configuration/update/' + id;
    return this.http.put<any>(apiConfiguration, body, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  deleteDefaultConfiguration(id) {
    const apiConfigurationConcession = environment.globalApiUrl + 'api/default-configuration/delete/' + id;
    return this.http.delete<any>(apiConfigurationConcession, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  getRelease() {
    const apiConfigurationConcession = environment.globalApiUrl + 'api/release';
    return this.http.get<any>(apiConfigurationConcession, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  getCurrentRelease() {
    const apiConfigurationConcession = environment.globalApiUrl + 'api/current-release';
    return this.http.get<any>(apiConfigurationConcession, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  getReleaseById(id) {
    const apiConfiguration = environment.globalApiUrl + 'api/release-by-id/' + id;
    return this.http.get<any>(apiConfiguration, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  createRelease(body) {
    const apiConfiguration = environment.globalApiUrl + 'api/release/create';
    return this.http.post<any>(apiConfiguration, body, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  updateRelease(body) {
    const apiConfiguration = environment.globalApiUrl + 'api/release/update';
    return this.http.put<any>(apiConfiguration, body, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  deleteRelease(id) {
    const apiConfigurationConcession = environment.globalApiUrl + 'api/release/delete/' + id;
    return this.http.delete<any>(apiConfigurationConcession, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  getReleaseType() {
    const apiConfigurationConcession = environment.globalApiUrl + 'api/release-type';
    return this.http.get<any>(apiConfigurationConcession, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  getConfigurationType() {
    const apiConfigurationConcession = environment.globalApiUrl + 'api/configuration-type';
    return this.http.get<any>(apiConfigurationConcession, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  getConfigurationTypeById(id) {
    const apiConfigurationConcession = environment.globalApiUrl + 'api/configuration-type-by-id/' + id;
    return this.http.get<any>(apiConfigurationConcession, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  createConfigurationType(body) {
    const apiConfiguration = environment.globalApiUrl + 'api/configuration-type/create';
    return this.http.post<any>(apiConfiguration, body, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  updateConfigurationType(body, id) {
    const apiConfiguration = environment.globalApiUrl + 'api/configuration-type/update/' + id;
    return this.http.put<any>(apiConfiguration, body, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  deleteConfigurationType(id) {
    const apiConfigurationConcession = environment.globalApiUrl + 'api/configuration-type/delete/' + id;
    return this.http.delete<any>(apiConfigurationConcession, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  getFieldType() {
    const apiConfigurationConcession = environment.globalApiUrl + 'api/field-type';
    return this.http.get<any>(apiConfigurationConcession, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  getConfigurationValue(id) {
    const apiConfigurationConcession = environment.globalApiUrl + 'api/configuration-value/' + id;
    return this.http.get<any>(apiConfigurationConcession, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  createConfigurationValue(body) {
    const apiConfiguration = environment.globalApiUrl + 'api/configuration-value/create';
    return this.http.post<any>(apiConfiguration, body, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  updateConfigurationValue(body, id) {
    const apiConfiguration = environment.globalApiUrl + 'api/configuration-value/update/' + id;
    return this.http.put<any>(apiConfiguration, body, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  deleteConfigurationValue(id) {
    const apiConfiguration = environment.globalApiUrl + 'api/configuration-value/delete/' + id;
    return this.http.delete<any>(apiConfiguration, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
}
