<h2>Ajouter un abonnement</h2>
<div class="wrapper">
  <form>
    <div class="form-group col-md-3" >
      <label for="subscription">Abonnement</label>
      <select [(ngModel)]="subscription.idAboSMS" class="form-control" name="subscription" id="subscription">
        <option *ngFor="let subscription of subscriptions" [value]="subscription.id">
          <div *ngIf="subscription.monthlyCost !== 0">Abonnement à {{subscription.monthlyCost}} € ({{subscription.smsCost}} € par SMS)</div>
          <div *ngIf="subscription.monthlyCost === 0">Acquisition ({{subscription.smsCost}} € par SMS)</div>
        </option>
      </select>
    </div>
    <div class="form-group col-md-3">
      <label for="startDate">Date de début :</label>
      <input [(ngModel)]="subscription.startDate" class="form-control" id="startDate" name="startDate" type="date">
    </div>
    <div class="form-group col-md-3">
      <label for="endDate">Date de fin :</label>
      <div *ngIf="!endDate" class="d-flex">
        <mat-icon (click)="endDate = true" class="mr-1 mt-1 pointer">edit</mat-icon>
        <input disabled value="Pas de date de fin">
      </div>
      <div *ngIf="endDate" class="d-flex">
        <mat-icon (click)="endDate = false" class="mr-1 mt-2 pointer">cancel</mat-icon>
        <input [(ngModel)]="subscription.endDate" class="form-control" id="endDate" name="endDate"  type="date">
      </div>
    </div>
    <div class="btn-group mt-3 mb-5">
      <div>
        <button (click)="onAssign()" class="btn btn100px btn-dark p-2 mr-2" [disabled]="!subscription.idAboSMS || !subscription.startDate || subscription.startDate > subscription.endDate">Ajouter</button>
      </div>

      <div>
        <button (click)="onCancel()" class="btn btn-light btn100px border-dark p-2">Annuler</button>
      </div>
    </div>
  </form>
</div>
