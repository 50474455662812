<h1 class="text-center mt-2">Gestion des configuration</h1>
<div class="user-table mt-4">
  <div class="d-flex justify-content-between align-items-baseline">
    <div>
      <mat-form-field class="view-filter mr-2 w-auto" appearance="fill">
        <mat-label>Recherche</mat-label>
        <input [(ngModel)]="search" matInput placeholder="Nom" (keyup)="applyFilter($event)"/>
      </mat-form-field>
      <mat-form-field class="view-filter mr-2 w-auto" appearance="fill">
        <mat-label>Section</mat-label>
        <mat-select [(ngModel)]="selectedSection" (click)="focusSearch('section')" (selectionChange)="filterDealershipSection(selectedSection)">
          <mat-option value="all">Tous</mat-option>
          <input id="select-search-section" class="form-control select-input custom-search-input" (keyup)="filterSection($event)" type="text" placeholder="Recherche">
          <mat-option *ngFor="let section of sectionArray.filtered"  [value]="section.name">
            {{section.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <button id="btn-add-concession" class="mr-3 btn btn-dark" (click)="onCreate()">
      <mat-icon>add_circle</mat-icon> Configuration
    </button>
  </div>
</div>
<div class="example-container">
  <table mat-table [dataSource]="dataSource" class="user-table mat-elevation-z8" matSort (matSortChange)="announceSortChange($event)"  matSortActive="type" matSortDirection="asc">
    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Section </th>
      <td mat-cell *matCellDef="let element"> {{element.type}} </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Nom </th>
      <td mat-cell *matCellDef="let element"> {{element.name}} </td>
    </ng-container>

    <ng-container matColumnDef="field">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Type de champ </th>
      <td mat-cell *matCellDef="let element"> {{element.field}} </td>
    </ng-container>

    <ng-container matColumnDef="order">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Ordre </th>
      <td mat-cell *matCellDef="let element"> {{element.order}} </td>
    </ng-container>

    <ng-container matColumnDef="displayForm">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Visible dans le formulaire </th>
      <td mat-cell *matCellDef="let element">
        <mat-icon *ngIf="element.displayForm">check</mat-icon>
        <mat-icon *ngIf="!element.displayForm">cancel</mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="update">
      <th mat-header-cell *matHeaderCellDef style="width: 50px"></th>
      <td mat-cell *matCellDef="let element"> <mat-icon class="pointer" (click)="onUpdate(element.id)">edit</mat-icon> </td>
    </ng-container>

    <ng-container matColumnDef="delete">
      <th mat-header-cell *matHeaderCellDef style="width: 50px"></th>
      <td mat-cell *matCellDef="let element"> <mat-icon class="pointer" *ngIf="!element.locked" (click)="onDelete(element.id)">delete</mat-icon> </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <mat-paginator [pageSizeOptions]="getPageSizeOptions()"
                 (click)="displayPaginatorAllOption()"
                 (page)="pageChange()"
                 showFirstLastButtons
                 aria-label="Sélectionner la page">
  </mat-paginator>
</div>
