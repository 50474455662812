import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material';
import {MatDialogRef} from '@angular/material/dialog';
import {DataDoraService} from '../../../utils/data-services/data-dora.service';
import swal from 'sweetalert2';

@Component({
  selector: 'app-user-dora-add-by-select-dialog',
  templateUrl: './user-dora-add-by-select-dialog.component.html',
  styleUrls: ['./user-dora-add-by-select-dialog.component.scss']
})
export class UserDoraAddBySelectDialogComponent implements OnInit {
  selectedItem = null;
  items = [];
  inputSearch = '';
  isLoading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<UserDoraAddBySelectDialogComponent>,
    private dataDoraService: DataDoraService,
  ) { }

  ngOnInit() {
    this.items = this.data.values;
  }
  onFilterSearch(event): any {
    event.stopPropagation();
    const regexp = new RegExp(this.inputSearch, 'gi');
    this.items = this.data.values.filter(x => regexp.test(x.name));
  }
  onAddItem(): any {
    this.isLoading = true;
    this.dataDoraService.addUserProject(this.data.user, this.data.rights, this.selectedItem).subscribe(res => {
      this.isLoading = false;
      this.dialogRef.close('success');
    }, error => {
      this.isLoading = false;
      swal.fire({
        title: 'Erreur',
        text: 'Un problème est survenu lors de l\'insertion.',
        type: 'error',
        confirmButtonText: 'Ok'
      });
    });
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
}
