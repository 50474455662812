<div class="home-bgd-img"><img src="assets/Imgs/computer-supprt.jpg" class="w-100" alt="image d'arrière plan"></div>
<div class="list-group">
  <div class="login-form-container mt-6">
    <div class="titre">
      <h4 class="mb-3">Portail Soft-Gate</h4>
      <p>Veuillez saisir le code à 6 chiffres<span *ngIf="sentByMail"> reçu par Email</span>.</p>
    </div>
    <form [formGroup]="form2fa" (ngSubmit)="onSubmit()" style="margin-top: 50px">
      <div class="form-group">
        <div class="input-code-container mb-5">
          <input type="text" matInput autocomplete="off" [formControl]="code2fa" class="hidden-input" maxlength="6" id="code">
          <div class="code-container">
            <div class="number selected" id="1"></div>
            <div class="number" id="2"></div>
            <div class="number" id="3"></div>
            <div class="number" id="4"></div>
            <div class="number" id="5"></div>
            <div class="number" id="6"></div>
          </div>
        </div>
      </div>
      <div class="mb-4 options-2fa">
        <a class="w-100 text-center pointer" (click)="sendByEmail()"><small>Envoyer le code par email</small></a>
        <div *ngIf="otpUrl != 'null'">
          <div class="w-100 text-center"><small>ou</small></div>
          <a (click)="isQrOpen = !isQrOpen" class="w-100 text-center pointer"><small>Afficher le QR code de configuration du compte</small></a>
        </div>
      </div>
      <div *ngIf="isQrOpen" class="w-100 d-flex justify-content-center mb-3">
        <ngx-qrcode *ngIf="otpUrl" [value]="otpUrl" qrc-class="qr-code"></ngx-qrcode>
      </div>
      <div class="btn-group row">
        <button type="button" (click)="goBack()" class="btn btn100px border-dark btn-light mr-2 p-2">Retour</button>
        <button type="submit" [disabled]="code2fa.value == ''" class="btn btn100px btn-dark p-2">Envoyer</button>
      </div>
    </form>
  </div>
</div>
<!--<app-spinner *ngIf="isSubmited"></app-spinner>-->
