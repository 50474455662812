<h1 class="text-center mt-2">Gestion des utilisateurs</h1>
<div class="d-flex justify-content-between align-items-center pannel-top mt-5" id="btn-add">
  <div class="btn1">
    <button type="button" id="btn-add-param" class="btn btn-dark" routerLink="/creation-utilisateur">
      <mat-icon>add_circle</mat-icon>
      Ajouter un utilisateur
    </button>
  </div>
  <div class="d-flex align-items-baseline">
    <div style="width: 280px;" class="mr-3">
      <mat-select id="concessions" class="form-control" [value]="selectedDealership" style="text-align: left">
        <input type="text" class="form-control" (keydown)="concessionFilter($event.target); $event.stopPropagation()"
               placeholder="Rechercher">
        <mat-option value="null" (click)="onSelectAllConcession()">Toutes les concessions</mat-option>
        <mat-option *ngFor="let concession of concessionsFiltered"
                    (click)="onSelectConcessionById(concession.concession_id)"
                    value="{{concession.concession_id}}">{{concession.nom_concession}}</mat-option>
      </mat-select>
    </div>
    <mat-form-field style="width: 250px">
      <mat-label>Cherchez un utilisateur</mat-label>
      <input [(ngModel)]="search" matInput id="search-user" (keyup)="applyFilter($event)">
    </mat-form-field>
  </div>
</div>

<div class="con3">
  <div>
    <table mat-table [dataSource]="dataSource" class="table mat-elevation-z8 user-table">
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef style="width: 100px; vertical-align: inherit; text-align: center"> Id </th>
        <td mat-cell *matCellDef="let user"> {{user.id}} </td>
      </ng-container>
      <ng-container matColumnDef="lastname">
        <th mat-header-cell *matHeaderCellDef style="vertical-align: inherit; text-align: center"> Nom </th>
        <td mat-cell *matCellDef="let user"> {{user.lastname}} </td>
      </ng-container>
      <ng-container matColumnDef="username">
        <th mat-header-cell *matHeaderCellDef style="vertical-align: inherit; text-align: center"> Prenom </th>
        <td mat-cell *matCellDef="let user"> {{user.username}} </td>
      </ng-container>
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef style="vertical-align: inherit; text-align: center"> Email </th>
        <td mat-cell *matCellDef="let user"> {{user.email}} </td>
      </ng-container>
      <ng-container matColumnDef="roles">
        <th mat-header-cell *matHeaderCellDef style="vertical-align: inherit; text-align: center; width: 170px;"> Rôles </th>
        <td mat-cell *matCellDef="let user"> {{user.rolesString}} </td>
      </ng-container>
      <ng-container matColumnDef="edit">
        <th mat-header-cell *matHeaderCellDef style="width: 175px;text-align: center;vertical-align: inherit;"> Modifier/Supprimer</th>
        <td mat-cell *matCellDef="let user" class="btn-edit">
          <button class="btn btn-round-40 m-auto btn-dark" type="button" [routerLink]="['/modification-utilisateur', user.id]">
            <mat-icon>edit</mat-icon>
          </button>
          <button class="btn btn-round-40 m-auto btn-dark" type="button" (click)="onDelete(user.id)" *ngIf="user.email != toNotDelete">
            <mat-icon>delete</mat-icon>
          </button>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator [pageSizeOptions]="getPageSizeOptions()"
                   (click)="displayPaginatorAllOption()"
                   (page)="pageChange()"
                   showFirstLastButtons
                   aria-label="Sélectionner la page">
    </mat-paginator>
  </div>
</div>
