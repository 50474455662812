<div class="home-bgd-img"><img src="assets/Imgs/computer-supprt.jpg" class="w-100" alt="image d'arrière plan"></div>
<div class="list-group">
  <div class="login-form-container mt-6">
    <h3 class="titre">Connexion</h3>
    <form [formGroup]="loginForm" (ngSubmit)="onSubmit(loginForm.value)" style="margin-top: 50px">
      <div class="form-group">
        <label for="loginUsername">Email</label>
        <input type="email" class="form-control" id="loginUsername" placeholder="Inserez votre email"
               name="nom" ngModel required pattern="^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$"
               formControlName="email">
      </div>

      <div class="form-group position-relative">
        <label for="loginPassword">Mot de passe</label>
        <input [type]="isPwdVisible ? 'text' : 'password'" class="form-control" id="loginPassword" placeholder="Inserez votre mot de passe"
               name="password" ngModel required formControlName="password">
          <mat-icon *ngIf="isPwdVisible" (click)="onViewPassword()" class="pointer show-pswd">visibility</mat-icon>
          <mat-icon *ngIf="!isPwdVisible" (click)="onViewPassword()"  class="pointer show-pswd">visibility_off</mat-icon>
      </div>
      <div class="form-group form-check p-2">
        <small><a [routerLink]="['/forgot-password']">Mot de passe oublié ou première connexion ? cliquez ici.</a></small>
      </div>
      <div>
        <small style="color: red" *ngIf="error.err && error.code == 401">Email ou mot de passe invalide</small>
      </div>
      <div class="btn-group row">
        <button type="submit" [disabled]="loginForm.invalid" class="btn btn100px btn-dark p-2 mr-2">Connexion</button>
        <button type="button" (click)="Annuler()" class="btn btn100px border-dark btn-light p-2">Retour</button>
      </div>
    </form>
  </div>
</div>
<app-spinner *ngIf="isSubmited"></app-spinner>
