import {Component, OnInit, Pipe, PipeTransform, ViewChild} from '@angular/core';
import {DataConcessionService} from '../utils/data-services/dataConcession.service';
import {Router} from '@angular/router';
import {DataCreationConcessionService} from '../utils/data-services/dataCreation-concession.service';
import {DataContactService} from '../utils/data-services/dataContact.service';
import {MatTableDataSource} from '@angular/material/table';
import {DataFonctionService} from '../utils/data-services/data-fonction.service';
import {FormBuilder, FormControl} from '@angular/forms';
import {DatePipe} from '@angular/common';
import writeXlsxFile from 'write-excel-file';
import {AppComponent} from '../app.component';
import swal from 'sweetalert2';
import {MatPaginator} from '@angular/material/paginator';

@Pipe({ name: 'tooltipList' })
export class TooltipListPipe implements PipeTransform {

  transform(lines: string[]): string {
    let list = '';

    lines.forEach(line => {
      list += '• ' + line + '\n';
    });

    return list;
  }
}
@Component({
  selector: 'app-gestion-contact',
  templateUrl: './gestion-contact.component.html',
  styleUrls: ['./gestion-contact.component.scss']
})
export class GestionContactComponent implements OnInit {

  variable: any;
  /* Variable list for the Textarea components */
  sort = null;
  nom: any;
  ville: any;
  rue: any;
  tel: any;
  email: any;
  pays: any;
  dataSource: any;
  originalDataSource: any;
  fonctionsList = [];
  groupsList = [];
  dealershipList = [];
  fonctionSelected = [];
  fonctionId: any;
  filterFormGroup;
  filter: any;
  search = '';
  fonctionFormControl = new FormControl('');
  groupeFormControl = new FormControl('');
  concessionFormControl = new FormControl('');
  formeFormControl = new FormControl('');
  public filteredFonction = [];
  public filteredGroup = [];
  public filteredDealership = [];
  public filterValues = {
    nom: '',
    fonction: [],
    groupe: [],
    concessions: []
  };
  // todo : variable à créer au début de la classe
  exportCollumnNameFr = [
    'Nom',
    'Prénom',
    'Fonction',
    'Téléphone fixe',
    'Téléphone portable',
    'E-mail',
    'Concessions'
  ];

  // tslint:disable-next-line:max-line-length
  constructor(
    public appComponent: AppComponent,
    private fb: FormBuilder,
    private datePipe: DatePipe,
    private dataConcessionService: DataConcessionService,
    private dataFonctionService: DataFonctionService,
    private route: Router,
    private dataService: DataCreationConcessionService,
    private  dataContactService: DataContactService
  ) {
    this.filterFormGroup = this.fb.group({});
  }
  displayedColumns: string[] = ['nom', 'prenom', 'fonction', 'telephone_fixe', 'telephone_portable', 'mail', 'commentaire', 'concession', 'modifier', 'supprimer'];

  // @ts-ignore
  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngOnInit() {
    this.appComponent.isLoading = true;
    this.search = localStorage.getItem('contactFilter') != null ? localStorage.getItem('contactFilter') : null;
    this.fonctionFormControl.setValue(JSON.parse(localStorage.getItem('filterGestionContactFunction')));
    this.groupeFormControl.setValue(JSON.parse(localStorage.getItem('filterGestionContactGroup')));
    this.concessionFormControl.setValue(JSON.parse(localStorage.getItem('filterGestionContactDealership')));
    this.getContacts();
    this.getFonctions();
    this.getGroups();
    this.getDealerships();
  }
  // applyFilter() {
  //   if (this.filterValues.nom === '' && this.filterValues.fonction.length === 0 && this.filterValues.groupe.length === 0 && this.filterValues.concessions.length === 0) {
  //     localStorage.setItem('contactFilter', filterValue);
  //     this.dataSource.data = this.originalDataSource;
  //   }
  // }
  getContacts() {
    this.dataContactService.getContact().subscribe(data => {
      for (const contact of data) {
        if (contact.concessions) {
          contact.concessions = contact.concessions.split(';');
        }
        if (contact.groupe) {
          contact.groupe = contact.groupe.split(';');
        }
      }
      this.dataSource = new MatTableDataSource(data);
      this.originalDataSource = data;
      this.contactFilter();
      // this.nomFormControl.valueChanges.subscribe((nom) => {
      //   this.filterValues.nom = nom;
      //   this.dataSource.filter = JSON.stringify(this.filterValues);
      // });
      // this.fonctionFormControl.valueChanges.subscribe((fonction) => {
      //   this.filterValues.fonction = fonction;
      //   this.dataSource.filter = JSON.stringify(this.filterValues);
      // });
      // this.groupeFormControl.valueChanges.subscribe((groupe) => {
      //   this.filterValues.groupe = groupe;
      //   this.dataSource.filter = JSON.stringify(this.filterValues);
      // });
      // this.concessionFormControl.valueChanges.subscribe((concessions) => {
      //   this.filterValues.concessions = concessions;
      //   this.dataSource.filter = JSON.stringify(this.filterValues);
      // });
      // this.dataSource.filterPredicate = this.tableFilter();
      this.paginator.pageIndex = localStorage.getItem('pageIndexContact') != null ? parseInt(localStorage.getItem('pageIndexContact')) : null;
      this.paginator.pageSize = localStorage.getItem('pageSizeContact') != null ? parseInt(localStorage.getItem('pageSizeContact')) : null;
      this.dataSource.paginator = this.paginator;
      this.appComponent.isLoading = false;
    });
  }
  getFonctions() {
    this.dataFonctionService.getFonction().subscribe(data => {
      for (const fonction of data) {
        this.fonctionsList.push(fonction.valeur);
      }
      this.filteredFonction = this.fonctionsList.slice();
    });
  }
  getGroups() {
    this.dataContactService.getGroup().subscribe(data => {
      for (const group of data) {
        this.groupsList.push(group.valeur);
      }
      this.filteredGroup = this.groupsList.slice();
    });
  }
  getDealerships() {
    this.dataContactService.getDealership().subscribe(data => {
      for (const dealership of data) {
        this.dealershipList.push(dealership.nom_concession);
      }
      this.filteredDealership = this.dealershipList.slice();
    });
    // this.getBrand();
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    localStorage.setItem('contactFilter', this.search);
    this.dataSource.filter = filterValue.trim();
  }
  contactFilter() {
    this.dataSource.data = this.originalDataSource;
    const functionSearch = this.fonctionFormControl.value && this.fonctionFormControl.value.length > 0;
    const groupSearch = this.groupeFormControl.value && this.groupeFormControl.value.length > 0;
    const dealershipSearch = this.concessionFormControl.value && this.concessionFormControl.value.length > 0;
    localStorage.setItem('filterGestionContactFunction', JSON.stringify(this.fonctionFormControl.value));
    localStorage.setItem('filterGestionContactGroup', JSON.stringify(this.groupeFormControl.value));
    localStorage.setItem('filterGestionContactDealership', JSON.stringify(this.concessionFormControl.value));
    if (functionSearch) {
      this.dataSource.data = this.dataSource.data.filter(contact => this.fonctionFormControl.value.includes(contact.fonction));
    }
    if (groupSearch) {
      let dataSetGroup = [];
      for (const group of this.groupeFormControl.value) {
        dataSetGroup = [...dataSetGroup, ...this.dataSource.data.filter(contact => contact.groupe ? contact.groupe.includes(group) : false)];
      }
      this.dataSource.data = dataSetGroup;
    }
    if (dealershipSearch) {
      let dataSetDealership = [];
      for (const dealership of this.concessionFormControl.value) {
        dataSetDealership = [...dataSetDealership, ...this.dataSource.data.filter(contact => contact.concessions ? contact.concessions.includes(dealership) : false)];
      }
      this.dataSource.data = dataSetDealership;
    }
    if (!dealershipSearch && !groupSearch && !functionSearch) { this.dataSource.data = this.originalDataSource; }
    this.dataSource.filter = this.search.trim();
  }
  onClickComment(comment) {
    swal.fire({
      title: 'Commentaire',
      html: comment,
      confirmButtonText: 'Ok'
    });
  }
  sortName() {
    if (this.sort == 'nameAcs') {
      this.sort = 'nameDesc';
      this.dataSource.data = this.dataSource.data.sort((a, b) => {
        return a.nom < b.nom ? 1 : -1;
      });
    } else {
      this.sort = 'nameAcs';
      this.dataSource.data = this.dataSource.data.sort((a, b) => {
        return a.nom > b.nom ? 1 : -1;
      });
    }
  }
  deleteContact(id: number) {
    swal.fire({
      title: 'Attention!',
      text: 'Vous êtes sur le point de supprimer un contact, êtes-vous sûr de vouloir continuer?',
      type: 'warning',
      confirmButtonText: 'Ok',
      showCancelButton: true,
      cancelButtonText: 'Annuler'
    }).then((result) => {
      if (result.value) {
        this.dataContactService.deleteContact(id).subscribe(res => {
          this.getContacts();
          swal.fire({
            title: 'Contact supprimée!',
            text: 'Le contact a bien été supprimée.',
            type: 'success',
            confirmButtonText: 'Ok'
          });
        }, err => {
          swal.fire({
            title: 'Oops...!',
            text: 'Une erreur est surnevue lors de la suppression..',
            type: 'error',
            confirmButtonText: 'Ok'
          });
        });
      }
    });
  }
  SelectChangeHandlerCreer() {
    this.route.navigate(['../creation-contact']);
  }
  SelectChangeHandlerModifier(contactId) {
    this.route.navigate(['../modification-contact/' + contactId]);
  }
  async exportExcel(): Promise<any> {
    const fileName = 'Export_Contacts_' + this.datePipe.transform(new Date(), 'yyyyMMdd') + '.xlsx';

    const HEADER_ROW = [];
    const ARRAY_ROW = [];
    const dataFinal = [];

    // création de la ligne de header
    for (const headerName of this.exportCollumnNameFr) {
      HEADER_ROW.push({
        value: headerName,
        fontWeight: 'bold'
      });
    }
    // création des lignes de données
    for (const dataContact of this.dataSource.filteredData) {
      ARRAY_ROW.push([
        // Name
        {
          type: String,
          value: dataContact.nom
        },
        // Firstname
        {
          type: String,
          value: dataContact.prenom
        },
        // Function
        {
          type: String,
          value: dataContact.fonction
        },
        // Phone fixe
        {
          type: String,
          value: dataContact.telephone_fixe
        },
        // Phone mobile
        {
          type: String,
          value: dataContact.telephone_portable
        },
        // Mail
        {
          type: String,
          value: dataContact.mail
        },
        // Concessions
        {
          type: String,
          value: dataContact.concessions ? dataContact.concessions.join() : null
        }
      ]);
    }

    // création de l'object final (header + lignes de données)
    dataFinal.push(HEADER_ROW);
    for (const dataRow of ARRAY_ROW) {
      dataFinal.push(dataRow);
    }

    // téléchargement du fichier
    await writeXlsxFile(dataFinal, {
      fileName
    });
  }
  getPageSizeOptions(): number[] {
    if (this.dataSource && this.dataSource.data.length > 100 && this.dataSource.paginator) {
      return [20, 50, 100,  this.dataSource.paginator.length];
    } else {
      return [20, 50, 100];
    }
  }
  displayPaginatorAllOption() {
    const matOptions = document.getElementsByClassName('mat-option-text');
    // @ts-ignore
    for (const options of matOptions) {
      options.innerHTML = options.innerHTML == this.dataSource.data.length ? 'Tous' : options.innerHTML;
    }
  }
  pageChange() {
    localStorage.setItem('pageIndexContact', String(this.paginator.pageIndex));
    localStorage.setItem('pageSizeContact', String(this.paginator.pageSize));
  }
}
