<h2>Création Configuration</h2>
<div class="wrapper">
  <form>
    <div class="form-row mt-3">
      <div class="form-group col-md-3">
        <label for="nom">Nom :</label>
        <input [(ngModel)]="configuration.name" class="form-control" id="nom" name="nom" required>
      </div>
      <div class="form-group col-md-3 offset-md-1">
        <label for="field">Type de champ :</label>
        <select [(ngModel)]="configuration.field" class="form-control" id="field" name="field" required>
          <option *ngFor="let type of fieldTypeArray" [value]="type">{{type}}</option>
        </select>
      </div>
    </div>

    <div class="form-row mt-4">
      <div class="form-group col-md-3">
        <label for="type">Section :</label>
        <select [(ngModel)]="configuration.type" class="form-control" id="type" name="type" required>
          <option *ngFor="let type of typeArray" [value]="type.id">{{type.name}}</option>
        </select>
      </div>
      <div class="form-group col-md-3 offset-md-1">
        <label for="nom">Ordre :</label>
        <input [(ngModel)]="configuration.order" class="form-control" id="order" name="order" type="number" required>
      </div>
    </div>
    <div class="form-row mt-4">
      <div class="form-group col-md-3 d-flex align-items-center">
        <label>Affichage formulaire :</label>
        <mat-checkbox [(ngModel)]="configuration.displayForm" [checked]="configuration.displayForm" class="ml-2"></mat-checkbox>
      </div>
    </div>
    <div class="btn-group mt-3 mb-5">
      <div>
        <button (click)="onCreate()" class="btn btn100px btn-dark p-2 mr-2" [disabled]="!configuration.name || !configuration.field || !configuration.type || configuration.order == null">Créer</button>
      </div>

      <div>
        <button (click)="onCancel()" class="btn btn-light btn100px border-dark p-2">Annuler</button>
      </div>
    </div>
  </form>
</div>
