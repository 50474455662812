import {Component, HostListener, Injectable, OnInit} from '@angular/core';
import {DataConcessionService} from './utils/data-services/dataConcession.service';
import {Router} from '@angular/router';
import {LogoutService} from './utils/logout.service';
import { Pipe } from '@angular/core';
import jwt_decode from 'jwt-decode';
import swal from "sweetalert2";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit  {
  isConnected = false;
  isLoading = false;
  year = new Date().getFullYear();
  interval: any;
  version: any = '4.5.0';
  constructor(
    private dataConcessionService: DataConcessionService,
    private route: Router,
    private logoutService: LogoutService
  ) {}

  ngOnInit() {
    if (sessionStorage.getItem('token') !== null && sessionStorage.getItem('isConnected') !== null) {
      this.logoutService.isConnected = true;
      this.isConnected = this.logoutService.isConnected;
    }
    this.interval = setInterval(() => {
      if (this.isConnected) {
        const token = sessionStorage.getItem('token');
        const jwtPayload: any = jwt_decode(token);
        if (new Date(jwtPayload.exp * 1000) <= new Date()) {
          swal.fire({
            title: 'Erreur',
            text: `Code Erreur : [401] - Token de sécurité expiré`,
            type: 'error',
            confirmButtonText: 'Ok'
          }).then(() => {
             {
              LogoutService.prototype.onDisconnect();
              this.route.navigate(['../']);
              document.location.reload();
              clearInterval(this.interval);
            }
          });
        }
      }
    }, 60000);
  }
  stopPropagation(event): any {
    event.stopPropagation();
  }
  onDisconnect() {
    this.logoutService.onDisconnect();
    this.isConnected = this.logoutService.isConnected;

  }
}

// tslint:disable-next-line:use-pipe-transform-interface
@Pipe({
  name: 'phone'
})
export class PhonePipe {
  transform(rawNum) {
    rawNum = rawNum.charAt(0) != 0 ? '0' + rawNum : '' + rawNum;

    let newStr = '';
    let i = 0;

    for (; i < Math.floor(rawNum.length / 2) - 1; i++) {
      newStr = newStr + rawNum.substr(i * 2, 2) + ' ';
    }

    return newStr + rawNum.substr(i * 2);
  }
}
