
    <div mat-dialog-content>
      <p>Maintenance des scénarios</p>
      <mat-form-field appearance="fill" class="w-100">
        <mat-label>Scénario</mat-label>
        <mat-select [(ngModel)]="data.scenario">
          <mat-option *ngFor="let scenario of scenarioArray" [value]="scenario.id" >{{scenario.brand}} : {{scenario.label}} </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div mat-dialog-actions class="d-flex justify-content-end">
      <button *ngIf="!isConfirmed" [disabled]="!data.scenario" mat-button (click)="confirm()">Lancer le scénario</button>
      <button *ngIf="isConfirmed" [disabled]="!data.scenario" mat-button  [mat-dialog-close]="data" style="color: red">Confirmer</button>
      <button mat-button [mat-dialog-close]="false">Annuler</button>
    </div>
  