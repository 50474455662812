<h1 class="text-center mt-2">Suivi des versions en concession</h1>
<div class="user-table">
  <div class="container-row top-field d-flex justify-content-start mr-5 mt-4">
    <mat-form-field class="view-filter mr-2 w-auto" style="height: 45px">
      <mat-label>Groupe</mat-label>
      <mat-select [(ngModel)]="selectedGroup" (click)="focusSearch('section')" (selectionChange)="filterDealershipConfiguration()"  appearance="fill" [compareWith]="comparer">
        <mat-option value="all">Tous</mat-option>
        <input id="select-search-section" class="form-control select-input custom-search-input" (keyup)="filterGroup($event)" type="text" placeholder="Recherche">
        <mat-option *ngFor="let group of groupsArray.filtered"  [value]="group.groupe_id">
          {{group.nom}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="view-filter mr-2 w-auto" style="height: 45px">
      <mat-label>Type</mat-label>
      <mat-select [(ngModel)]="selectedType" (selectionChange)="filterDealershipConfiguration()"  appearance="fill" [compareWith]="comparer">
        <mat-option value="all">Tous</mat-option>
        <mat-option *ngFor="let type of typesArray"  [value]="type.id">
          {{type.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="view-filter mr-2 w-auto" style="height: 45px">
      <mat-label>Actif</mat-label>
      <mat-select [(ngModel)]="activeDealership" (selectionChange)="filterDealershipConfiguration()"  appearance="fill" multiple>
        <mat-option [value]="true">Oui</mat-option>
        <mat-option [value]="false">Non</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Cherchez une concession</mat-label>
      <input [(ngModel)]="search" matInput (keyup)="applyFilter($event)">
    </mat-form-field>
  </div>
  <div class="pb-3">
    <div *ngFor="let display of displayReleases">
      <div *ngFor="let release of releaseArray">
        <div *ngIf="display.name == release.type && display.value == true">
          <fieldset  class="mat-form-field mb-2">
            <legend class="font-weight-bold">Release {{ release.type }}</legend>
            <div class="d-flex">
              <div *ngFor="let config of objectToValues(release['versions']); let index = index"
                   class="m-auto d-flex flex-column default-value">
                <span>{{ camelCaseToTitleCase(objectToKey(release['versions'])[index]) }}</span>
                <span>{{ config[0] }}</span>
                <span>{{ countTotal(release.type, objectToKey(release['versions'])[index], config[0]) }}</span>
              </div>
            </div>
          </fieldset>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="example-container mt-4">
  <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="user-table mat-elevation-z8" matSort (matSortChange)="announceSortChange($event)"  matSortActive="concessionId" matSortDirection="asc">
    <ng-container matColumnDef="concessionId">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center"> ID </th>
      <td mat-cell *matCellDef="let element" class="text-center">{{element.concessionId}}</td>
    </ng-container>
    <ng-container matColumnDef="nomConcession">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center"> Nom </th>
      <td mat-cell *matCellDef="let element" class="text-center">{{element.nomConcession}}</td>
    </ng-container>
    <ng-container matColumnDef="{{column}}" *ngFor="let column of displayedConfiguration">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center"> {{camelCaseToTitleCase(column)}} </th>
      <td mat-cell *matCellDef="let element" class="text-center p-2" [ngClass]="{'no-product' : isUpToDate(element, column) == 'norelease', 'uptodate' : isUpToDate(element, column) == 'uptodate', 'outdated' : isUpToDate(element, column) == 'outdated', 'updated' : isUpToDate(element, column) == 'updated', 'orange' : isUpToDate(element, column) == 'orange', 'yellow' : isUpToDate(element, column) == 'yellow'}">{{element[column]}}</td>
    </ng-container>
    <ng-container matColumnDef="update">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element" class="text-center pointer"><mat-icon (click)="onUpdate(element.concessionId)">edit</mat-icon></td>
    </ng-container>
    <ng-container matColumnDef="expand">
      <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
      <td mat-cell *matCellDef="let element">
        <button mat-icon-button aria-label="expand row" (click)="(expandedElement = expandedElement === element ? null : element); $event.stopPropagation()">
          <mat-icon *ngIf="expandedElement !== element">keyboard_arrow_down</mat-icon>
          <mat-icon *ngIf="expandedElement === element">keyboard_arrow_up</mat-icon>
        </button>
      </td>
    </ng-container>
    <ng-container matColumnDef="expandedDetail">
<!--      <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>-->
      <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns?.length">
        <div class="example-element-detail flex-column" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
          <div *ngFor="let configurationType of configurationTypeArray">
            <div *ngIf="configurationType.name !== 'Version Applications BMW'" class="ml-2 font-weight-bolder">{{configurationType.name}}</div>
            <table class="mt-2">
              <thead class="row h-auto m-0">
                <th class="col text-center" *ngFor="let configuration of filterConfiguration(expandedConfiguration, configurationType.name)">{{configuration.name}}</th>
              </thead>
              <tbody>
                <tr class="row h-auto m-0">
                  <td class="col text-center" *ngFor="let configuration of filterConfiguration(expandedConfiguration, configurationType.name)">
                    <span *ngIf="configuration.name != 'DMS' && configuration.name != 'Editeur DMS'">{{element[configuration.name]}}</span>
                    <span *ngIf="configuration.name == 'DMS' || configuration.name == 'Editeur DMS'">{{displayDmsEditor(element, configuration.name)}}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let element; columns: displayedColumns;"
        class="example-element-row pointer"
        [class.example-expanded-row]="expandedElement === element"
        (click)="expandedElement = expandedElement === element ? null : element"
    >
    </tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
  </table>
  <mat-paginator [pageSizeOptions]="getPageSizeOptions()"
                 (click)="displayPaginatorAllOption()"
                 (page)="pageChange()"
                 showFirstLastButtons
                 aria-label="Sélectionner la page">
  </mat-paginator>
</div>
