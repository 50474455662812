import {Component, OnInit, ViewChild} from '@angular/core';
import {DataProduitConcessionServiceService} from "../utils/data-services/data-produit-concession-service.service";
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";

@Component({
  selector: 'app-gestion-concession-produit',
  templateUrl: './gestion-concession-produit.component.html',
  styleUrls: ['./gestion-concession-produit.component.scss']
})
export class GestionConcessionProduitComponent implements OnInit {

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  dealershipByProduct: any;
  dataSource: any;
  displayedColumns: string[] = ['id', 'name', 'town'];
  search: any;
  constructor(private dataService: DataProduitConcessionServiceService) { }

  ngOnInit() {
    this.getDealershipByProduct();
    this.paginator.pageIndex = localStorage.getItem('pageIndexProductDealership') != null ? parseInt(localStorage.getItem('pageIndexProductDealership')) : null;
    this.paginator.pageSize = localStorage.getItem('pageSizeProductDealership') != null ? parseInt(localStorage.getItem('pageSizeProductDealership')) : null;
    this.dataSource.paginator = this.paginator;
  }
  getDealershipByProduct() {
    this.dataService.getDealershipByProduct().subscribe(result => {
      this.dealershipByProduct = result;
      this.dataSource = result[0].dealership;
      this.search = localStorage.getItem('dealershipProductFilter') != null ? localStorage.getItem('dealershipProductFilter') : '';
      this.dataSource.filter = this.search.trim().toLowerCase();
    });
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    localStorage.setItem('dealershipProductFilter', filterValue);
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  arrayToDataSource(array) {
    this.dataSource = new MatTableDataSource(array);
  }
  getPageSizeOptions(): number[] {
    if (this.dataSource && this.dataSource.data.length > 100 && this.dataSource.paginator) {
      return [20, 50, 100,  this.dataSource.paginator.length];
    } else {
      return [20, 50, 100];
    }
  }
  displayPaginatorAllOption() {
    const matOptions = document.getElementsByClassName('mat-option-text');
    // @ts-ignore
    for (const options of matOptions) {
      options.innerHTML = options.innerHTML == this.dataSource.data.length ? 'Tous' : options.innerHTML;
    }
  }
  pageChange() {
    localStorage.setItem('pageIndexProductDealership', String(this.paginator.pageIndex));
    localStorage.setItem('pageSizeProductDealership', String(this.paginator.pageSize));
  }
}
