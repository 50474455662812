import {Component, OnInit, ViewChild} from '@angular/core';
import {DataCampaignDiscountsService} from '../utils/data-services/data-campaign-discounts.service';
import {MatTableDataSource} from '@angular/material/table';
import {Router} from '@angular/router';
import swal from 'sweetalert2';
import moment from 'moment';
import 'moment/locale/fr';
import {MatPaginator} from "@angular/material/paginator";
moment.locale('fr');

@Component({
  selector: 'app-gestion-campaign-discounts',
  templateUrl: './gestion-campaign-discounts.component.html',
  styleUrls: ['./gestion-campaign-discounts.component.scss']
})
export class GestionCampaignDiscountsComponent implements OnInit {

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  dataSource = new MatTableDataSource();

  constructor( private dataService: DataCampaignDiscountsService, private route: Router ) {}
  displayedColumns: string[] = ['name', 'limit', 'count_actual_app', 'count_actual_site', 'start_date', 'end_date', 'message', 'requirement', 'active', 'brand', 'debug', 'modifier'];
  search: any;

  ngOnInit() {
    this.dataService.getCampaignDiscounts().subscribe(campaignDiscounts => {
      this.dataSource.data = campaignDiscounts;
      this.search = localStorage.getItem('campaignFilter') != null ? localStorage.getItem('campaignFilter') : '';
      this.dataSource.filter = this.search.trim().toLowerCase();
      this.paginator.pageIndex = localStorage.getItem('pageIndexCampaign') != null ? parseInt(localStorage.getItem('pageIndexCampaign')) : null;
      this.paginator.pageSize = localStorage.getItem('pageSizeCampaign') != null ? parseInt(localStorage.getItem('pageSizeCampaign')) : null;
      this.dataSource.paginator = this.paginator;
    });
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    localStorage.setItem('campaignFilter', filterValue);
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  frenchDate(date: any) {
    return moment(date).format('L');
  }
  onClickMessage(message) {
    swal.fire({
      title: 'Message',
      text: message,
      confirmButtonText: 'Ok'
    });
  }
  SelectChangeHandlerCreer() {
    this.route.navigate(['../creation-campagne-sms']);
  }
  SelectChangeHandlerModifier(campaignDiscountsId) {
    this.route.navigate(['../modification-campagne-sms/' + campaignDiscountsId]);
  }
  getPageSizeOptions(): number[] {
    if (this.dataSource && this.dataSource.data.length > 100 && this.dataSource.paginator) {
      return [20, 50, 100,  this.dataSource.paginator.length];
    } else {
      return [20, 50, 100];
    }
  }
  displayPaginatorAllOption() {
    const matOptions = document.getElementsByClassName('mat-option-text');
    // @ts-ignore
    for (const options of matOptions) {
      options.innerHTML = options.innerHTML == this.dataSource.data.length ? 'Tous' : options.innerHTML;
    }
  }
  pageChange() {
    localStorage.setItem('pageIndexCampaign', String(this.paginator.pageIndex));
    localStorage.setItem('pageSizeCampaign', String(this.paginator.pageSize));
  }
}
