import {Component, OnInit, ViewChild} from '@angular/core';
import {DataConfigurationConcessionService} from "../utils/data-services/data-configuration-concession.service";
import {MatTableDataSource} from "@angular/material/table";
import {Router} from "@angular/router";
import {animate, state, style, transition, trigger} from "@angular/animations";
import moment from "moment";
import {MatPaginator} from "@angular/material/paginator";

@Component({
  selector: 'app-gestion-configuration-concession',
  templateUrl: './gestion-configuration-concession.component.html',
  styleUrls: ['./gestion-configuration-concession.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class GestionConfigurationConcessionComponent implements OnInit {
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  dataSource = new MatTableDataSource();
  constructor(public dataService: DataConfigurationConcessionService, private route: Router) {  }
  displayedColumns: string[] = ['concessionId', 'nomConcession', 'Version ISPI Admin Client', 'Version IPS', 'Version KAI', 'Version DCOM Core', 'Version DCOM ISPA 4', 'Version DCOM Aftersales', 'Version DCOM Appointment', 'Version DCOM Sales', 'Version APAS', 'Version soft admin', 'update', 'expand'];
  expandedElement: any | null;
  configurationArray = [];
  displayedConfiguration = ['Version ISPI Admin Client', 'Version IPS', 'Version KAI', 'Version DCOM Core', 'Version DCOM ISPA 4', 'Version DCOM Aftersales', 'Version DCOM Appointment', 'Version DCOM Sales', 'Version APAS', 'Version soft admin'];
  expandedConfiguration = [];
  releaseArray = [];
  configurationTypeArray = [];
  ngOnInit() {
    this.getDealershipConfiguration();
    this.getConfiguration();
    this.getCurrentRelease();
    this.getConfigurationType();
    this.dataSource.paginator = this.paginator;
  }
  getDealershipConfiguration(): void {
    this.dataService.getDealershipConfiguration().subscribe(response => {
      this.dataSource.data = Object.values(response);
    });
  }
  getCurrentRelease(): void {
    this.dataService.getCurrentRelease().subscribe(response => {
      this.releaseArray = response;
    });
  }
  getConfiguration(): void {
    this.dataService.getConfiguration().subscribe(response => {
      this.configurationArray = response;
      for (const configuration of response) {
        if (!configuration.name.includes('Version')) {
          this.expandedConfiguration.push(configuration);
        }
      }
    });
  }
  getConfigurationType() {
    this.dataService.getConfigurationType().subscribe(response => {
      this.configurationTypeArray = response;
    });
  }
  onUpdate(idConcession) {
    this.route.navigate(['../gestion-configuration-concession/' + idConcession]);
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  objectToArray(object: object): any {
    let array = [];
    let i = 0;
    for (const value of Object.values(object)) {
      array[Object.keys(object)[i]] = value;
      i++;
    }
    return array;
  }
  objectToValues(object) {
    return Object.values(object);
  }
  objectToKey(object) {
    return Object.keys(object);
  }
  camelCaseToTitleCase(text: string): string {
    const result = text.replace('Version', '');
    return result.charAt(0).toUpperCase() + result.slice(1);
  }
  filterConfiguration(array, text) {
    return array.filter(conf => conf.type == text);
  }
  isUpToDate(element, column) {
    let upToDate = 'norelease';
    for (const release of this.releaseArray) {
      if ((element.dealershipType === release.type || release.type === 'soft-nrg') && (Object.keys(release.versions).includes(column) && element[column])) {
        if (element[column] === release.versions[column]) {
          upToDate = 'uptodate';
        } else {
          upToDate = 'outdated';
        }
      }
    }
    return upToDate;
  }
}
