import {Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {DataCampaignDiscountsService} from "../utils/data-services/data-campaign-discounts.service";
import {Router} from "@angular/router";
import moment from "moment/moment";
import swal from "sweetalert2";
import {DataBackupConcessionService} from "../utils/data-services/data-backup-concession.service";
import {MatPaginator} from "@angular/material/paginator";

@Component({
  selector: 'app-gestion-backup-concession',
  templateUrl: './gestion-backup-concession.component.html',
  styleUrls: ['./gestion-backup-concession.component.scss']
})
export class GestionBackupConcessionComponent implements OnInit {

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  dataSource = new MatTableDataSource();

  constructor( private dataService: DataBackupConcessionService, private route: Router ) {}
  displayedColumns: string[] = ['id', 'concession', 'project', 'modifier'];
  search: any;

  ngOnInit() {
    this.dataService.getBackupConcession().subscribe(backupConcession => {
      this.dataSource.data = backupConcession;
      this.search = localStorage.getItem('backupDealershipFilter') != null ? localStorage.getItem('backupDealershipFilter') : '';
      this.dataSource.filter = this.search.trim().toLowerCase();
      this.paginator.pageIndex = localStorage.getItem('pageIndexBackupDealership') != null ? parseInt(localStorage.getItem('pageIndexBackupDealership')) : null;
      this.paginator.pageSize = localStorage.getItem('pageSizeBackupDealership') != null ? parseInt(localStorage.getItem('pageSizeBackupDealership')) : null;
      this.dataSource.paginator = this.paginator;
    });
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    localStorage.setItem('backupDealershipFilter', filterValue);
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  SelectChangeHandlerCreer() {
    this.route.navigate(['../create-backup-concession']);
  }
  SelectChangeHandlerModifier(backupConcessionId) {
    this.route.navigate(['../update-backup-concession/' + backupConcessionId]);
  }
  getPageSizeOptions(): number[] {
    if (this.dataSource && this.dataSource.data.length > 100 && this.dataSource.paginator) {
      return [20, 50, 100,  this.dataSource.paginator.length];
    } else {
      return [20, 50, 100];
    }
  }
  displayPaginatorAllOption() {
    const matOptions = document.getElementsByClassName('mat-option-text');
    // @ts-ignore
    for (const options of matOptions) {
      options.innerHTML = options.innerHTML == this.dataSource.data.length ? 'Tous' : options.innerHTML;
    }
  }
  pageChange() {
    localStorage.setItem('pageIndexBackupDealership', String(this.paginator.pageIndex));
    localStorage.setItem('pageSizeBackupDealership', String(this.paginator.pageSize));
  }
}
