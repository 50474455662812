import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DataDoraService} from '../../utils/data-services/data-dora.service';
import {MatDialog} from '@angular/material/dialog';
import {AddBySelectDialogComponent} from '../../gestion-concession-dora/modification-concession-dora/add-by-select-dialog/add-by-select-dialog.component';
import {UserDoraAddBySelectDialogComponent} from './user-dora-add-by-select-dialog/user-dora-add-by-select-dialog.component';
import swal from 'sweetalert2';

@Component({
  selector: 'app-modification-utilisateur-dora',
  templateUrl: './modification-utilisateur-dora.component.html',
  styleUrls: ['./modification-utilisateur-dora.component.scss']
})
export class ModificationUtilisateurDoraComponent implements OnInit {

  isLoading = false;
  id = null;
  data = null;

  loginForm = '';
  nameForm = '';
  rightsForm = '';

  constructor(
    private activeRoute: ActivatedRoute,
    private dataDoraService: DataDoraService,
    private route: Router,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.id = Number(this.activeRoute.snapshot.paramMap.get('id'));
    this.getData();
  }
  getData(): any {
    this.dataDoraService.getUserById(this.id).subscribe(res => {
      this.data = res;
      this.data.user.projects.sort((a, b) => {return a.name > b.name ? 1 : -1});
      this.loginForm = this.data.user.login;
      this.nameForm = this.data.user.name;
      this.rightsForm = this.data.user.rights;
    });
  }
  updateUser(): any {
    this.isLoading = true;
    const body = {
      login: this.loginForm,
      name: this.nameForm,
      rights: this.rightsForm,
    };
    this.dataDoraService.updateUser(this.id, body).subscribe(res => {
      this.isLoading = false;
      swal.fire({
        title: 'Succès',
        text: 'L\'utilisateur a bien été modifié.',
        type: 'success',
        confirmButtonText: 'Ok'
      });
    }, error => {
      this.isLoading = false;
      swal.fire({
        title: 'Erreur',
        text: 'Un problème est survenu lors de l\'insertion.',
        type: 'error',
        confirmButtonText: 'Ok'
      });
    });
  }
  onRemoveProject(project): any {
    swal.fire({
      title: 'Attention!',
      text: 'Êtes-vous sûr de vouloir retirer le projet "' + project.name + '" de cet utilisateur ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'Annuler'
    }).then((result) => {
      if (result.value) {
        this.isLoading = true;
        this.dataDoraService.removeUserProject(this.id, project.id).subscribe(() => {
          this.isLoading = false;
          this.getData();
        });
      }
    });
  }
  onOpenDialogSelect(): any {
    const dialogRef = this.dialog.open(UserDoraAddBySelectDialogComponent, {
      data: {
        values: this.data.projects,
        user: this.id,
        rights: this.data.user.rights
      },
      autoFocus: true,
      minWidth: '500px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == 'success') {
        this.getData();
      }
    });
  }
}
