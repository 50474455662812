import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {DataUtilisateurService} from '../utils/data-services/dataUtilisateurService';
import {MatTableDataSource} from '@angular/material/table';
import swal from 'sweetalert2';
import {Router} from '@angular/router';
import {DataConsultationService} from '../utils/data-services/dataConsultation.service';
import {MatPaginator} from '@angular/material/paginator';

@Component({
  selector: 'app-gestion-utilisateur',
  templateUrl: './gestion-utilisateur.component.html',
  styleUrls: ['./gestion-utilisateur.component.scss']
})
export class GestionUtilisateurComponent implements OnInit {
  users: any;
  dataSource: any;
  concessions: any;
  concessionsFiltered: any;
  displayedColumns: string[] = ['id', 'lastname', 'username', 'email', 'roles', 'edit'];
  toNotDelete = 'psms@psms.com';
  search: any;
  selectedDealership = null;

  // @ts-ignore
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private dataUtilisateurService: DataUtilisateurService,
    private dataConsultationService: DataConsultationService,
    private route: Router
  ) { }

  ngOnInit() {
    this.dataUtilisateurService.getUser().subscribe(data => {
      this.dataUtilisateurService.getRoles().subscribe(rolesDB => {
        data.records = data.records.filter(user => !user.roles.includes('SYSTEM'));
        this.users = data.records.sort(function sortFunction(a, b): any {
          return a.lastname > b.lastname ? 1 : -1;
        });
        for (const user of this.users) {
          user.roles = JSON.parse(user.roles);
          let i = 0;
          user.rolesString = '';
          for (const role of user.roles) {
            i++;
            const currentRole = rolesDB.records.filter(x => x.name == role);
            if (currentRole.length != 0) {
              user.rolesString += currentRole[0].label;
              if (i < user.roles.length) {
                user.rolesString += ', ';
              }
            }
          }
        }
        this.dataSource = new MatTableDataSource(this.users);
        this.search = localStorage.getItem('userFilter') != null ? localStorage.getItem('userFilter') : '';
        this.dataSource.filter = this.search.trim().toLowerCase();
        this.selectedDealership = localStorage.getItem('filterUserDealership') != null ? localStorage.getItem('filterUserDealership') : null;
        if (this.selectedDealership != null && this.selectedDealership != 'null') {this.onSelectConcessionById(); } else {this.onSelectAllConcession(); }
        this.paginator.pageIndex = localStorage.getItem('pageIndexUser') != null ? parseInt(localStorage.getItem('pageIndexUser')) : null;
        this.paginator.pageSize = localStorage.getItem('pageSizeUser') != null ? parseInt(localStorage.getItem('pageSizeUser')) : null;
        this.dataSource.paginator = this.paginator;
      });
    });
    this.dataConsultationService.getConcession().subscribe(dataConcession => {
      this.concessions = dataConcession.records.sort(function sortFunction(a, b): any {
        return a.nom_concession > b.nom_concession ? 1 : -1;
      });
      this.concessionsFiltered = this.concessions;
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    localStorage.setItem('userFilter', filterValue);
    this.dataSource.filter = filterValue.trim();
  }

  onSelectConcessionById(): void {
    localStorage.setItem('filterUserDealership', this.selectedDealership);
    this.dataSource.data = [];
    // @ts-ignore
    document.getElementById('search-user').value = '';
    this.dataUtilisateurService.getUserConcessiions(this.selectedDealership).subscribe(result => {
      const userConcessionList = [];
      for (const user of result.records) {
        const filteredUser = this.users.find(x => x.id === user.user_id);
        if (filteredUser) {userConcessionList.push(filteredUser); }
        this.dataSource = new MatTableDataSource(userConcessionList);
        this.dataSource.paginator = this.paginator;
      }
    });
  }
  onSelectAllConcession(): void {
    localStorage.setItem('filterUserDealership', this.selectedDealership);
    // @ts-ignore
    document.getElementById('search-user').value = '';
    this.dataSource = new MatTableDataSource(this.users);
    this.dataSource.paginator = this.paginator;
  }
  concessionFilter(target): void {
    const regExpString = '(.+)?' + target.value.toLowerCase() + '(.+)?';
    const regExpFind = new RegExp(regExpString, 'gi');
    this.concessionsFiltered = this.concessions.filter(x => regExpFind.test(x.nom_concession));
  }
  onDelete(id) {
    swal.fire({
      title: 'Attention!',
      text: 'Êtes-vous sûr de vouloir supprimer?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'Annuler'
    }).then((result) => {
      if (result.value) {
        this.dataUtilisateurService.deleteUser(id).subscribe(data => {
          if (data.statusCode === 200) {
            swal.fire('L\'utilisateur a bien été supprimé!', '', 'success').then(res => {
              window.location.reload();
            });
          } else {
            swal.fire({
              title: 'Erreur',
              text: 'Un problème est survenu lors de la suppression.',
              type: 'error',
              confirmButtonText: 'Ok'
            });
          }
        });
      }
    });
  }
  getPageSizeOptions(): number[] {
    if (this.dataSource && this.dataSource.data.length > 100 && this.dataSource.paginator) {
      return [20, 50, 100,  this.dataSource.paginator.length];
    } else {
      return [20, 50, 100];
    }
  }
  displayPaginatorAllOption() {
    const matOptions = document.getElementsByClassName('mat-option-text');
    // @ts-ignore
    for (const options of matOptions) {
      options.innerHTML = options.innerHTML == this.dataSource.data.length ? 'Tous' : options.innerHTML;
    }
  }
  pageChange() {
    localStorage.setItem('pageIndexUser', String(this.paginator.pageIndex));
    localStorage.setItem('pageSizeUser', String(this.paginator.pageSize));
  }
}
