import {Component, OnInit, Pipe, PipeTransform, ViewChild} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {DataOperationService} from "../utils/data-services/data-operation.service";
import {ActivatedRoute, Router} from "@angular/router";
import {DataConcessionService} from "../utils/data-services/dataConcession.service";
import {DataProduitConcessionServiceService} from "../utils/data-services/data-produit-concession-service.service";
import writeXlsxFile from "write-excel-file";
import {DatePipe} from "@angular/common";
import {DataProduitService} from "../utils/data-services/dataProduit.service";
import {FormControl} from "@angular/forms";
import {DataCreationConcessionService} from "../utils/data-services/dataCreation-concession.service";
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
@Component({
  selector: 'app-gestion-produit-concession',
  templateUrl: './gestion-produit-concession.component.html',
  styleUrls: ['./gestion-produit-concession.component.scss']
})
export class GestionProduitConcessionComponent implements OnInit {
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  dataSource = new MatTableDataSource();
  productArray = [];
  brandArray: any;
  dealershipDontHaveProductArray = [];
  constructor(private dataService: DataProduitConcessionServiceService, private datePipe: DatePipe, private activeRoute: ActivatedRoute, private dataServiceProduit: DataProduitService, private  dataBrandService: DataCreationConcessionService) { }
  exportCollumnNameFr = ['ID', 'Nom', 'Ville'];
  displayedColumns: string[] = ['id', 'name', 'town'];
  productFormControl = new FormControl('');
  brandFormControl = new FormControl('');
  activeFormControl = new FormControl('');
  typeFormControl = new FormControl('');
  originalDataSource = [];
  showOwnProduct = true;
  public filteredProduct = [];
  search: any;

  ngOnInit() {
    this.typeFormControl.setValue('commercial');
    this.getProductByDealership();
    this.getProduct(this.typeFormControl.value);
    this.getBrands();
    this.paginator.pageIndex = localStorage.getItem('pageIndexDealershipProduct') != null ? parseInt(localStorage.getItem('pageIndexDealershipProduct')) : null;
    this.paginator.pageSize = localStorage.getItem('pageSizeDealershipProduct') != null ? parseInt(localStorage.getItem('pageSizeDealershipProduct')) : null;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  changeProductType() {
    this.getProduct(this.typeFormControl.value);
    this.filterByProduct();
  }
  getProduct(type: string) {
    this.exportCollumnNameFr = ['ID', 'Nom', 'Ville'];
    this.displayedColumns = ['id', 'name', 'town'];
    if (type === 'commercial') {
      this.dataServiceProduit.getCommercialProduct().subscribe(result => {
        this.productArray = result.records;
        for (const product of this.productArray) {
          this.displayedColumns.push(product.nom_produit);
          this.exportCollumnNameFr.push(product.nom_produit);
        }
      });
    } else if (type === 'technical') {
      this.dataServiceProduit.getTechnicalProduct().subscribe(result => {
        this.productArray = result.records;
        for (const product of this.productArray) {
          this.displayedColumns.push(product.nom_produit);
          this.exportCollumnNameFr.push(product.nom_produit);
        }
      });
    }
  }
  getBrands() {
    this.dataBrandService.getBrands().subscribe(result => {
      this.brandArray = result.records;
    });
  }
  filterByProduct() {
    let data = this.originalDataSource;
    data = this.activeFormControl.value.length > 0 ? data.filter(dealership => dealership.isActive === this.activeFormControl.value[0]) : data;
    data = this.productFormControl.value.length > 0 ? data.filter(dealership => {
      let isInclude = true;
      for (const product of this.productFormControl.value) {
        if (this.showOwnProduct) {
          // @ts-ignore
          if (!dealership.products.includes(product)) {
            isInclude = false;
          }
        } else {
          // @ts-ignore
          if (dealership.products.includes(product)) {
            isInclude = false;
          }
        }
      }
      return isInclude;
    }) : data;
    data = this.brandFormControl.value.length > 0 ? data.filter(dealership => {
      let isInclude = true;
      for (const brand of this.brandFormControl.value) {
        // @ts-ignore
        if (!dealership.brand.includes(brand)) {
          isInclude = false;
        }
      }
      return isInclude;
    }) : data;
    this.dataSource.data = data;
  }
  getProductByDealership() {
    this.dataService.getProductByDealership().subscribe(result => {
      this.dataSource.data = result;
      this.originalDataSource = result;
      this.search = localStorage.getItem('produitConcessionFilter') != null ? localStorage.getItem('produitConcessionFilter') : '';
      this.dataSource.filter = this.search.trim().toLowerCase();
    });
  }
  changeOwnProduct() {
    this.showOwnProduct = !this.showOwnProduct;
    this.filterByProduct();
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    localStorage.setItem('produitConcessionFilter', filterValue);
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  dealershipHasProduct(products, product) {
    return products.includes(product);
  }
  async exportExcel(): Promise<any> {
    const fileName = 'Export_Produits_' + this.datePipe.transform(new Date(), 'yyyyMMdd') + '.xlsx';

    const HEADER_ROW = [];
    const ARRAY_ROW = [];
    const dataFinal = [];

    // création de la ligne de header
    for (const headerName of this.exportCollumnNameFr) {
      HEADER_ROW.push({
        value: headerName,
        fontWeight: 'bold'
      });
    }
    // création des lignes de données
    let i = 0;
    for (const dataProduct of this.dataSource.filteredData) {
      ARRAY_ROW.push([
        // ID
        {
          type: Number,
          // @ts-ignore
          value: dataProduct.id
        },
        // Name
        {
          type: String,
          // @ts-ignore
          value: dataProduct.name
        },
        // Ville
        {
          type: String,
          // @ts-ignore
          value: dataProduct.town
        }
      ]);
      for (const product of this.productArray) {
        ARRAY_ROW[i].push({
          type: String,
          // @ts-ignore
          value: this.dealershipHasProduct(dataProduct.products, product.nom_produit) ? 'oui' : 'non'
        });
      }
      i++;
    }

    // création de l'object final (header + lignes de données)
    dataFinal.push(HEADER_ROW);
    for (const dataRow of ARRAY_ROW) {
      dataFinal.push(dataRow);
    }

    // téléchargement du fichier
    await writeXlsxFile(dataFinal, {
      fileName
    });
  }
  getPageSizeOptions(): number[] {
    if (this.dataSource && this.dataSource.data.length > 100 && this.dataSource.paginator) {
      return [20, 50, 100,  this.dataSource.paginator.length];
    } else {
      return [20, 50, 100];
    }
  }
  displayPaginatorAllOption() {
    const matOptions = document.getElementsByClassName('mat-option-text');
    // @ts-ignore
    for (const options of matOptions) {
      options.innerHTML = options.innerHTML == this.dataSource.data.length ? 'Tous' : options.innerHTML;
    }
  }
  pageChange() {
    localStorage.setItem('pageIndexDealershipProduct', String(this.paginator.pageIndex));
    localStorage.setItem('pageSizeDealershipProduct', String(this.paginator.pageSize));
  }
}
