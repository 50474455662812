<h1 class="text-center mt-2">Gestion des campagnes SMS</h1>
<div class="user-table mt-4">
  <div class="container-row d-flex justify-content-between top-field m-3">
    <div class="btn1">
      <button (click)="SelectChangeHandlerCreer()" id="btn-add-concession" class="mr-5 btn btn-dark">
        <mat-icon>add_circle</mat-icon> Campagne SMS
      </button>
    </div>
    <mat-form-field>
      <mat-label>Rechercher</mat-label>
      <input [(ngModel)]="search" matInput (keyup)="applyFilter($event)">
    </mat-form-field>
  </div>
</div>

<div class="example-container">
  <table mat-table [dataSource]="dataSource" class="user-table mat-elevation-z8 mb-3">

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> Nom </th>
      <td mat-cell *matCellDef="let element"> {{element.name}} </td>
    </ng-container>


    <ng-container matColumnDef="limit">
      <th mat-header-cell *matHeaderCellDef> Limite </th>
      <td mat-cell *matCellDef="let element">
        <div *ngIf="element.limite !== 0">{{element.limite}}</div>
        <div *ngIf="element.limite === 0"><i class="fa-solid fa-infinity"></i></div>

      </td>
    </ng-container>

    <ng-container matColumnDef="count_actual_app">
      <th mat-header-cell *matHeaderCellDef>Rendez-vous sur l'APP </th>
      <td mat-cell *matCellDef="let element"> {{element.count_actual_app}} </td>
    </ng-container>

    <ng-container matColumnDef="count_actual_site">
      <th mat-header-cell *matHeaderCellDef> Rendez-vous sur le SIFE </th>
      <td mat-cell *matCellDef="let element"> {{element.count_actual_site}} </td>
    </ng-container>

    <ng-container matColumnDef="start_date">
      <th mat-header-cell *matHeaderCellDef> Date de début </th>
      <td mat-cell *matCellDef="let element"> {{frenchDate(element.start_date.date)}} </td>
    </ng-container>

    <ng-container matColumnDef="end_date">
      <th mat-header-cell *matHeaderCellDef > Date de fin </th>
      <td mat-cell *matCellDef="let element"> {{frenchDate(element.end_date.date)}} </td>
    </ng-container>

    <ng-container matColumnDef="message">
      <th mat-header-cell *matHeaderCellDef> Message </th>
      <td mat-cell *matCellDef="let element"><mat-icon (click)="onClickMessage(element.message)" class="concession-icon pointer"> message</mat-icon></td>
    </ng-container>

    <ng-container matColumnDef="requirement">
      <th mat-header-cell *matHeaderCellDef> Condition </th>
      <td mat-cell *matCellDef="let element">{{element.requirement_operator}} {{element.requirement}} </td>
    </ng-container>

    <ng-container matColumnDef="active">
      <th mat-header-cell *matHeaderCellDef > Actif </th>
      <td mat-cell *matCellDef="let element">
        <mat-icon *ngIf="element.active">check</mat-icon>
        <mat-icon *ngIf="!element.active">close</mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="brand">
      <th mat-header-cell *matHeaderCellDef > Marque </th>
      <td mat-cell *matCellDef="let element"> {{element.brand}} </td>
    </ng-container>

    <ng-container matColumnDef="debug">
      <th mat-header-cell *matHeaderCellDef > Mode débug </th>
      <td mat-cell *matCellDef="let element">
        <mat-icon *ngIf="element.debug">check</mat-icon>
        <mat-icon *ngIf="!element.debug">close</mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="modifier">
      <th mat-header-cell *matHeaderCellDef> Modifier </th>
      <td mat-cell *matCellDef="let element">
        <button (click)="SelectChangeHandlerModifier(element.id)" class="m-2 btn btn-round-40 btn-dark">
          <mat-icon>edit</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <mat-paginator [pageSizeOptions]="getPageSizeOptions()"
                 (click)="displayPaginatorAllOption()"
                 (page)="pageChange()"
                 showFirstLastButtons
                 aria-label="Sélectionner la page">
  </mat-paginator>
</div>
