import {Component, Inject, OnInit} from '@angular/core';
import {DataMaintenancesService} from '../utils/data-services/dataMaintenances.service';
import swal from 'sweetalert2';
import {DataSnapshotScenarioService} from "../utils/data-services/data-snapshot-scenario.service";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {any} from "codelyzer/util/function";

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss']
})
export class MaintenanceComponent implements OnInit {

  isLoading = false;
  titleLoader = '';

  constructor(
    private maintenanceService: DataMaintenancesService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
  }
  openScenario() {
    const dialogRef = this.dialog.open(MaintenanceScenarioComponent, {width: '800px', data: any});
    dialogRef.afterClosed().subscribe(result => {
      this.maintenanceService.verifyScenarioPngo(result.scenario).subscribe(res => {
        console.log('success', res);
      });
    });
  }
  onClickButton(action): void {
    this.isLoading = true;
    switch (action) {
      case 'syncUcar':
        this.titleLoader = 'UCAR - synchronisation des véhicules';
        this.preventMissClick('Synchronisation des véhicules', () => {
          this.maintenanceService.syncUcar().subscribe(res => {this.showSuccessMsg();}, error => {this.showErrorMsg(error);});
        });
        break;
      case 'anonymizationPngo':
        this.titleLoader = 'PNGO - Anonymisation des informations';
        this.preventMissClick('Anonymisation des informations', () => {
          this.maintenanceService.anonymizationPngo().subscribe(res => {this.showSuccessMsg();}, error => {this.showErrorMsg(error);});
        });
        break;
      case 'fixFirstDatePngo':
        this.titleLoader = 'PNGO - Correction des concessions pour le 1er créneau';
        this.preventMissClick('Correction des concessions pour le 1er créneau', () => {
          this.maintenanceService.fixFirstDatePngo().subscribe(res => {this.showSuccessMsg();}, error => {this.showErrorMsg(error);});
        });
        break;
      case 'ipsMonitoringPngo':
        this.titleLoader = 'PNGO - Gestion du monitoring IPS';
        this.preventMissClick('Gestion du monitoring IPS', () => {
          this.maintenanceService.ipsMonitoringPngo().subscribe(res => {this.showSuccessMsg();}, error => {this.showErrorMsg(error);});
        });
        break;
    }
  }
  preventMissClick(taskName, callback) {
    swal.fire({
      title: 'Attention!',
      text: 'Êtes-vous sûr de vouloir effectuer la tâche suivante : ' + taskName + ' ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'Annuler'
    }).then((result) => {
      if (result.value) {
        callback();
      }
    });
  }
  showSuccessMsg() {
    this.isLoading = false;
    swal.fire({
      title: 'Succès',
      text: 'La tâche a bien été effectuée.',
      type: 'success',
      confirmButtonText: 'Ok'
    });
  }
  showErrorMsg(error) {
    this.isLoading = false;
    const errorMsg = error.message ? error.message : error;
    swal.fire({
      title: 'Erreur!',
      text: 'Une erreur est survenue : ' + errorMsg,
      type: 'error',
      confirmButtonText: 'Ok'
    });
  }
  loadInBackground() {
    this.isLoading = false;
  }
}
@Component({
  selector: 'app-add-form-brand',
  template: `
    <div mat-dialog-content>
      <p>Maintenance des scénarios</p>
      <mat-form-field appearance="fill" class="w-100">
        <mat-label>Scénario</mat-label>
        <mat-select [(ngModel)]="data.scenario">
          <mat-option *ngFor="let scenario of scenarioArray" [value]="scenario.id" >{{scenario.brand}} : {{scenario.label}} </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div mat-dialog-actions class="d-flex justify-content-end">
      <button *ngIf="!isConfirmed" [disabled]="!data.scenario" mat-button (click)="confirm()">Lancer le scénario</button>
      <button *ngIf="isConfirmed" [disabled]="!data.scenario" mat-button  [mat-dialog-close]="data" style="color: red">Confirmer</button>
      <button mat-button [mat-dialog-close]="false">Annuler</button>
    </div>
  `
})
export class MaintenanceScenarioComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<MaintenanceScenarioComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private scenarioService: DataSnapshotScenarioService
  ) {}
  scenarioArray = [];
  isConfirmed = false;
  ngOnInit(): void {
    this.getScenarios();
  }
  getScenarios() {
    this.scenarioService.getSnapshotScenario().subscribe(response => {
      this.scenarioArray = response;
    });
  }
  confirm(): void {
    this.isConfirmed = true;
  }
}
