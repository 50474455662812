import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import {catchError} from "rxjs/operators";
import {HandleErrorService} from "../handleError.service";

@Injectable({
  providedIn: 'root'
})
export class DataDoraService extends HandleErrorService {

  /////////////////////////
  ////// DEALERSHIP ///////
  /////////////////////////

  getDealerships() {
    const url = environment.globalApiUrl + 'api/dora/concessions';
    return this.http.get<any>(url, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  getDealershipById(id) {
    const url = environment.globalApiUrl + 'api/dora/concession/' + id;
    return this.http.get<any>(url, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  addDealershipProject(dealershipId, projectId) {
    const url = environment.globalApiUrl + 'api/dora/add-dealership-project';
    const body = {dealershipId, projectId};
    return this.http.post<any>(url, body, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  addDealershipBrand(dealershipId, brandId) {
    const url = environment.globalApiUrl + 'api/dora/add-dealership-brand';
    const body = {dealershipId, brandId};
    return this.http.post<any>(url, body, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  /////////////////////////
  ///////// USERS /////////
  /////////////////////////

  getUsers() {
    const url = environment.globalApiUrl + 'api/dora/users';
    return this.http.get<any>(url, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  getUserById(id) {
    const url = environment.globalApiUrl + 'api/dora/user/' + id;
    return this.http.get<any>(url, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  updateUser(userId, body) {
    const url = environment.globalApiUrl + 'api/dora/user/' + userId;
    return this.http.put<any>(url, body, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  addUserProject(userId, rights, projectId) {
    const url = environment.globalApiUrl + 'api/dora/add-user-project';
    const body = {userId, rights, projectId};
    return this.http.post<any>(url, body, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  removeUserProject(userId, projectId) {
    const url = environment.globalApiUrl + 'api/dora/remove-user-project';
    const body = {userId, projectId};
    return this.http.post<any>(url, body, environment.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
}
