import {Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {DataReceptionOptionsMappingService} from "../utils/data-services/data-reception-options-mapping.service";
import {Router} from "@angular/router";
import {DataRentDealershipsAgenciesService} from "../utils/data-services/data-rent-dealerships-agencies.service";
import {MatPaginator} from "@angular/material/paginator";

@Component({
  selector: 'app-gestion-rent-dealerships-agencies',
  templateUrl: './gestion-rent-dealerships-agencies.component.html',
  styleUrls: ['./gestion-rent-dealerships-agencies.component.scss']
})
export class GestionRentDealershipsAgenciesComponent implements OnInit {
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  dataSource = new MatTableDataSource();

  constructor( private dataService: DataRentDealershipsAgenciesService, private route: Router ) {}
  displayedColumns: string[] = ['id', 'concession', 'marque', 'ucar_agency_id', 'typologie_client', 'modifier'];
  search: any;

  ngOnInit() {
    this.dataService.getRentDealershipAgency().subscribe(rentDealershipAgencies => {
      this.dataSource.data = rentDealershipAgencies;
      this.search = localStorage.getItem('rentFilter') != null ? localStorage.getItem('rentFilter') : '';
      this.dataSource.filter = this.search.trim().toLowerCase();
      this.paginator.pageIndex = localStorage.getItem('pageIndexRent') != null ? parseInt(localStorage.getItem('pageIndexRent')) : null;
      this.paginator.pageSize = localStorage.getItem('pageSizeRent') != null ? parseInt(localStorage.getItem('pageSizeRent')) : null;
      this.dataSource.paginator = this.paginator;
    });
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    localStorage.setItem('rentFilter', filterValue);
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  SelectChangeHandlerCreer() {
    this.route.navigate(['../create-rent']);
  }
  SelectChangeHandlerModifier(rentId) {
    this.route.navigate(['../update-rent/' + rentId]);
  }
  getPageSizeOptions(): number[] {
    if (this.dataSource && this.dataSource.data.length > 100 && this.dataSource.paginator) {
      return [20, 50, 100,  this.dataSource.paginator.length];
    } else {
      return [20, 50, 100];
    }
  }
  displayPaginatorAllOption() {
    const matOptions = document.getElementsByClassName('mat-option-text');
    // @ts-ignore
    for (const options of matOptions) {
      options.innerHTML = options.innerHTML == this.dataSource.data.length ? 'Tous' : options.innerHTML;
    }
  }
  pageChange() {
    localStorage.setItem('pageIndexRent', String(this.paginator.pageIndex));
    localStorage.setItem('pageSizeRent', String(this.paginator.pageSize));
  }
}
