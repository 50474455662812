<div class="page-content">
  <h2 class="text-center mt-2">{{ id }} - {{ concession?.name }} - {{ concession?.city }}</h2>

  <div class="mt-4 p-3 d-flex flex-wrap justify-content-center">
    <div class="list-container p-3 m-3">
      <div class="mb-4"><strong>Liste des projets associés</strong></div>
      <ul>
        <li *ngFor="let project of this.concession?.projects"> {{ project }}</li>
      </ul>
      <div class="mt-4"><button (click)="onOpenDialogSelect('project')" [disabled]="projects.length == 0" type="button" class="btn btn-dark">Ajouter un projet</button></div>
    </div>
    <div class="list-container p-3 m-3">
      <div class="mb-4"><strong>Liste des marques associés</strong></div>
      <ul>
        <li *ngFor="let brand of this.concession?.brands"> {{ brand }}</li>
      </ul>
      <div class="mt-4"><button (click)="onOpenDialogSelect('brand')" [disabled]="brands.length == 0" type="button" class="btn btn-dark">Ajouter une marque</button></div>
    </div>
  </div>
</div>
