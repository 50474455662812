import {Component, OnInit, ViewChild} from '@angular/core';
import {DataSmsSubscriptionService} from '../utils/data-services/data-sms-subscription.service';
import {MatTableDataSource} from '@angular/material/table';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {DialogContactDuplicateDialog} from '../gestion-contact/creation-contact/creation-contact.component';
import {MatDialog} from '@angular/material/dialog';
import {
  AssignDealershipSubscriptionComponent
} from './assign-dealership-subscription/assign-dealership-subscription.component';
import {Router} from '@angular/router';
import moment from 'moment';
import {FormControl} from "@angular/forms";
import {MatPaginator} from "@angular/material/paginator";

@Component({
  selector: 'app-gestion-sms-subscription',
  templateUrl: './gestion-sms-subscription.component.html',
  styleUrls: ['./gestion-sms-subscription.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class GestionSmsSubscriptionComponent implements OnInit {
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  constructor(public dialog: MatDialog, private dataService: DataSmsSubscriptionService, private route: Router) { }
  dataSource: any = new MatTableDataSource();
  originalDataSource: any;
  subscriptionTypeArray = [
    'Abonnement',
    'Acquisition',
    'Aucun abonnement'
  ];
  subscriptionTypeFormControl = new FormControl('');
  subscriptionPriceArray: any;
  subscriptionPriceFormControl = new FormControl('');
  activeFormControl = new FormControl('');
  columnsToDisplay: string[] = ['id', 'dealership', 'town', 'subscriptionType', 'monthlyCost', 'smsCost', 'startDate', 'endDate', 'stop', 'assign', 'expand'];
  // columnsToDisplay: string[] = ['id', 'dealership', 'town', 'subscriptionType', 'monthlyCost', 'smsCost', 'startDate', 'endDate', 'expand'];
  expandedElement: any | null;
  currentElementSms: any;
  isLoading = true;
  search: any;

  ngOnInit() {
    this.getSubscriptions();
    this.getSubscriptionsPrice();
    this.paginator.pageIndex = localStorage.getItem('pageIndexSmsSubscription') != null ? parseInt(localStorage.getItem('pageIndexSmsSubscription')) : null;
    this.paginator.pageSize = localStorage.getItem('pageSizeSmsSubscription') != null ? parseInt(localStorage.getItem('pageSizeSmsSubscription')) : null;
    this.dataSource.paginator = this.paginator;
  }
  isDateToday(date) {
    return moment(date).format('yyyy-MM-DD') === moment(new Date()).format('yyyy-MM-DD');
  }
  getSubscriptions() {
    this.isLoading = true;
    this.dataService.getSubscriptions().subscribe(result => {
      for (const subscription of result) {
        if (!subscription.currentSubscription) {
          subscription.subscriptionType = 'Aucun abonnement';
        } else {
          subscription.subscriptionType = subscription.currentSubscription.smsSubscription[0].monthlyCost === 0 ? 'Acquisition' : 'Abonnement';
        }
      }
      this.dataSource = new MatTableDataSource(result);
      this.originalDataSource = result;
      this.isLoading = false;
      this.search = localStorage.getItem('smsSubscriptionFilter') != null ? localStorage.getItem('smsSubscriptionFilter') : '';
      this.dataSource.filter = this.search.trim().toLowerCase();
    });
  }
  getSubscriptionsPrice() {
    this.dataService.getSubscriptionsSms().subscribe(result => {
      this.subscriptionPriceArray = result;
    });
  }
  filterBySubscriptionType() {
    let data = this.originalDataSource;
    data = this.activeFormControl.value.length > 0 ? data.filter(dealership => dealership.isActive === this.activeFormControl.value[0]) : data;
    data = this.subscriptionTypeFormControl.value.length > 0 ? data.filter(dealership => {
      let isInclude = true;
      for (const subType of this.subscriptionTypeFormControl.value) {
        if (subType === 'Acquisition') {
          if (dealership.currentSubscription === null) {
            isInclude = false;
          } else if (dealership.currentSubscription.smsSubscription[0].monthlyCost !== 0) {
            isInclude = false;
          }
        } else if (subType === 'Abonnement') {
          if (dealership.currentSubscription === null) {
            isInclude = false;
          } else if (dealership.currentSubscription.smsSubscription[0].monthlyCost === 0) {
            isInclude = false;
          }
        } else if (subType === 'Aucun abonnement') {
          if (dealership.currentSubscription !== null) {
            isInclude = false;
          }
        }
      }
      return isInclude;
    }) : data;
    data = this.subscriptionPriceFormControl.value.length > 0 ? data.filter(dealership => {
      let isInclude = true;
      for (const subPrice of this.subscriptionPriceFormControl.value) {
        if (dealership.currentSubscription === null) {
          isInclude = false;
        } else if (dealership.currentSubscription.smsSubscription[0].monthlyCost !== subPrice.monthlyCost || dealership.currentSubscription.smsSubscription[0].smsCost !== subPrice.smsCost) {
          isInclude = false;
        }
      }
      return isInclude;
    }) : data;
    this.dataSource.data = data;
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    localStorage.setItem('smsSubscriptionFilter', filterValue);
    this.dataSource.filter = filterValue.trim();
  }
  objectToArray(object: object): any {
    return Object.values(object);
  }
  onAddSubscription(idDealership): void {
    this.route.navigate(['../add-subscription/' + idDealership]);
  }
  onUpdateSubscription(idDealership): void {
    this.route.navigate(['../update-sms-subscription/' + idDealership]);
  }
  onStopSubscription(id) {
    this.isLoading = true;
    this.dataService.stopSubscription({id}).subscribe(() => {
      this.getSubscriptions();
    });
  }
  onActiveSubscription(id) {
    this.isLoading = true;
    this.dataService.activeSubscription({id}).subscribe(() => {
      this.getSubscriptions();
    });
  }
  deleteSubscription(id) {
    this.isLoading = true;
    this.dataService.deleteSubscription(id).subscribe(() => {
      this.getSubscriptions();
    });
  }
  stopPropagation(event): any {
    event.stopPropagation();
  }
  subscriptionIsActive(element, subscriptionId): boolean {
    if (element.currentSubscription) {
      return element.currentSubscription.id === subscriptionId;
    } else {
      return false;
    }
  }
  getPageSizeOptions(): number[] {
    if (this.dataSource && this.dataSource.data.length > 100 && this.dataSource.paginator) {
      return [20, 50, 100,  this.dataSource.paginator.length];
    } else {
      return [20, 50, 100];
    }
  }
  displayPaginatorAllOption() {
    const matOptions = document.getElementsByClassName('mat-option-text');
    // @ts-ignore
    for (const options of matOptions) {
      options.innerHTML = options.innerHTML == this.dataSource.data.length ? 'Tous' : options.innerHTML;
    }
  }
  pageChange() {
    localStorage.setItem('pageIndexSmsSubscription', String(this.paginator.pageIndex));
    localStorage.setItem('pageSizeSmsSubscription', String(this.paginator.pageSize));
  }
}
