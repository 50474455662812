import {AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {DataRgpdDemandsService} from '../utils/data-services/dataRgpdDemands.service';

import swal from 'sweetalert2';
import {DataConcessionService} from '../utils/data-services/dataConcession.service';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {animate, state, style, transition, trigger} from "@angular/animations";
import {DatePipe} from "@angular/common";

@Component({
  selector: 'app-gestion-demandes-rgpd',
  templateUrl: './gestion-demandes-rgpd.component.html',
  styleUrls: ['./gestion-demandes-rgpd.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class GestionDemandesRgpdComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = ['open', 'created_at', 'type', 'concession', 'object', 'has_phone', 'sender', 'treated'];
  dataSource = new MatTableDataSource();
  storedDateSource = null;
  isUntreatedDemandOnly = false;
  expandedElement: any;
  concessions = [];
  concessionsFilterData = [];
  currentConcession = -1;
  nbUntreatedDemand = 0;
  comment = '';
  commentList = [];
  isCommentOpen = false;

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  constructor(
    private supportService: DataRgpdDemandsService,
    private dataConcessionService: DataConcessionService,
    private matPaginator: MatPaginator,
    private datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.getRgpdDemands();
    this.dataConcessionService.getConcession().subscribe(data => {
      this.concessions = data.records;
      this.concessionsFilterData = data.records;
    });
  }

  ngAfterViewInit(): any {
    this.matPaginatorTranslate();
    this.paginator.pageIndex = localStorage.getItem('pageIndexRgpd') != null ? parseInt(localStorage.getItem('pageIndexRgpd')) : null;
    this.paginator.pageSize = localStorage.getItem('pageSizeRgpd') != null ? parseInt(localStorage.getItem('pageSizeRgpd')) : null;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  getRgpdDemands(elementId?): any {
    this.supportService.requestGetRgpdDemands().subscribe(res => {
      this.dataSource.data = res.records;
      this.storedDateSource = res.records;
      this.nbUntreatedDemand = res.records.filter(x => x.status != 2).length;
      const concession = sessionStorage.getItem('supportDemandsConcessionFilter');
      if (this.isUntreatedDemandOnly) {
        // @ts-ignore
        this.dataSource.data = this.dataSource.data.filter(x => x.status != 2);
      }
      if (concession) {
        this.onSelectConcession(concession);
        this.currentConcession = parseInt(concession);
      }
      if (elementId) {
        setTimeout(() => {
          document.getElementById('row-demand-' + elementId).click();
        }, 200);
      }
    });
  }
  getDataSource(): any {
    return this.dataSource;
  }
  getConcessionName(id): any {
    const concession = this.concessions.filter(x => x.concession_id == id)
    if (concession.length > 0) {
     return concession[0].nom_concession;
    }
  }
  onSelectConcession(value): any {
    sessionStorage.setItem('supportDemandsConcessionFilter', value);
    if (value < 0) {
      this.dataSource.data = this.storedDateSource;
    } else {
      this.dataSource.data = this.storedDateSource.filter(x => x.id_concession == value);
    }
  }
  onFilterConcession(event): any {
    const value = event.target.value;
    const regexp = new RegExp(value, 'gi');
    if (value != '') {
      const concessionArray = [];
      for (const concession of this.concessions) {
        if (regexp.test(concession.nom_concession)) {
          concessionArray.push(concession);
        }
      }
      this.concessionsFilterData = concessionArray;
    } else {
      this.concessionsFilterData = this.concessions;
    }
  }
  onToggleDemands(): any {
    this.isUntreatedDemandOnly = !this.isUntreatedDemandOnly;
    if (this.isUntreatedDemandOnly) {
      this.storedDateSource = this.dataSource.data;
      // @ts-ignore
      this.dataSource.data = this.dataSource.data.filter(x => x.status != 2);
    } else {
      this.dataSource.data = this.storedDateSource;
    }
  }
  onOpenRow(demandId): void {
    // @ts-ignore
    const element = this.dataSource.data.filter(x => x.id == demandId);
    if (element.length > 0) {
      // @ts-ignore
      if (element[0].comments != null) {
        // @ts-ignore
        this.commentList = JSON.parse(element[0].comments);
        this.commentList.sort((a, b) => {
          return a.created_at > b.created_at ? 1 : -1;
        });
      } else {
        this.commentList = [];
      }
    }
    document.querySelectorAll('.chevron-table').forEach(e => {
      // @ts-ignore
      e.style.transform = 'rotate(0deg)';
    });
    if (this.expandedElement != null) {
      document.getElementById('chevron-' + demandId).style.transform = 'rotate(180deg)';
    } else {
      document.getElementById('chevron-' + demandId).style.transform = 'rotate(0deg)';
    }
  }
  onTreatDemand(element, status): any {
    const dateNow = this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss');
    let body = null;
    switch (status) {
      case 0:
        body = {
          updated_at: dateNow,
          status: 0,
        };
        break;
      case 1:
        body = {
          updated_at: dateNow,
          status: 1,
        };
        break;
      case 2:
        body = {
          updated_at: dateNow,
          status: 2,
          treated_at: dateNow,
          treated_by: sessionStorage.getItem('User')
        };
        break;
    }
    this.supportService.requestTreatRgpdDemands(body, element.id).subscribe(res => {
      this.getRgpdDemands(element.id);
      swal.fire('La demande à bien été traitée !', '', 'success');
    }, err => {
      swal.fire('Un problème est survenu lors du traitement de la demande !', '', 'error');
    });
  }
  postComment(element): any {
    const dateNow = this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss');
    const commentList = this.commentList;
    const comment = {
      created_at: dateNow,
      comment: this.comment
    };
    commentList.push(comment);
    const body = {
      comments: JSON.stringify(commentList),
      updated_at: dateNow,
    };
    this.supportService.requestTreatRgpdDemands(body, element.id).subscribe(res => {
      this.getRgpdDemands(element.id);
      this.commentList = commentList;
      this.isCommentOpen = false;
      this.comment = '';
      swal.fire('La demande à bien été traitée !', '', 'success');
    }, err => {
      swal.fire('Un problème est survenu lors du traitement de la demande !', '', 'error');
    });
  }
  matPaginatorTranslate(): any {
    // Translate matPaginator to fr-FR
    this.matPaginator._intl.itemsPerPageLabel = 'Demandes par page';
    this.matPaginator._intl.firstPageLabel = 'Première page';
    this.matPaginator._intl.lastPageLabel = 'Dernière page';
    this.matPaginator._intl.nextPageLabel = 'Page suivante';
    this.matPaginator._intl.previousPageLabel = 'Page précédente';
    this.matPaginator._intl.getRangeLabel = (page: number, pageSize: number, length: number) => {
      if (length === 0 || pageSize === 0) {
        return '0 de ' + length;
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      // If the start index exceeds the list length, do not try and fix the end index to the end.
      const endIndex = startIndex < length ?
        Math.min(startIndex + pageSize, length) :
        startIndex + pageSize;
      return startIndex + 1 + ' - ' + endIndex + ' Sur ' + length;
    };
  }
  applyFilter(event: Event): void {
    this.dataSource.filter = (event.target as HTMLInputElement).value.trim();
  }
  focusSearch(type: string): void {
    setTimeout(() => {
      if (document.getElementById('select-search-' + type) != null) {
        // @ts-ignore
        document.getElementById('select-search-' + type).focus();
      }
    });
  }
  getPageSizeOptions(): number[] {
    if (this.dataSource && this.dataSource.data.length > 100 && this.dataSource.paginator) {
      return [20, 50, 100,  this.dataSource.paginator.length];
    } else {
      return [20, 50, 100];
    }
  }
  displayPaginatorAllOption() {
    const matOptions = document.getElementsByClassName('mat-option-text');
    // @ts-ignore
    for (const options of matOptions) {
      options.innerHTML = options.innerHTML == this.dataSource.data.length ? 'Tous' : options.innerHTML;
    }
  }
  pageChange() {
    localStorage.setItem('pageIndexRgpd', String(this.paginator.pageIndex));
    localStorage.setItem('pageSizeRgpd', String(this.paginator.pageSize));
  }
}
