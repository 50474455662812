import {Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {DataDoraService} from '../utils/data-services/data-dora.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-gestion-concession-dora',
  templateUrl: './gestion-concession-dora.component.html',
  styleUrls: ['./gestion-concession-dora.component.scss']
})
export class GestionConcessionDoraComponent implements OnInit {

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  dataSource = new MatTableDataSource();
  allDealership = [];
  groups = [];
  filteredGroups = [];
  brands = [];
  displayedColumns = ['id', 'name', 'group', 'city', 'codeSite', 'active', 'action'];
  search = '';
  selectedGroup = 'all';
  selectedBrand = 'all';
  selectedActive = 'all';
  inputSearchGroup = '';

  constructor(
    private dataDoraService: DataDoraService,
    private route: Router
  ) { }

  ngOnInit() {
    this.search = localStorage.getItem('doraDealershipFilter') != null ? localStorage.getItem('doraDealershipFilter') : '';
    this.paginator.pageIndex = localStorage.getItem('pageIndexDealershipDora') != null ? parseInt(localStorage.getItem('pageIndexDealershipDora')) : null;
    this.paginator.pageSize = localStorage.getItem('pageSizeDealershipDora') != null ? parseInt(localStorage.getItem('pageSizeDealershipDora')) : null;
    this.dataSource.filter = this.search.trim().toLowerCase();
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.getData();
  }
  getData(): any {
    this.dataDoraService.getDealerships().subscribe(res => {
      this.allDealership = res.concessions;
      this.groups = res.groups;
      this.filteredGroups = res.groups;
      this.brands = res.brands;
      this.dataSource.data = res.concessions;
    });
  }
  onEdit(id): any {
    this.route.navigate(['/dora/modification-concession/' + id]);
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    localStorage.setItem('doraDealershipFilter', filterValue);
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  filterData(): any {
    let filteredData = this.allDealership;

    if (this.selectedGroup != 'all') {
      filteredData = filteredData.filter(x => x.group == this.selectedGroup);
    }
    if (this.selectedBrand != 'all') {
      filteredData = filteredData.filter(x => x.brands.includes(this.selectedBrand));
    }
    if (this.selectedActive != 'all') {
      filteredData = filteredData.filter(x => x.active == this.selectedActive);
    }
    this.dataSource.data = filteredData;
  }
  onFilterSearchGroup(event): any {
    event.stopPropagation();
    const regexp = new RegExp(this.inputSearchGroup, 'gi');
    this.filteredGroups = this.groups.filter(x => regexp.test(x));
  }
  getPageSizeOptions(): number[] {
    if (this.dataSource && this.dataSource.data.length > 100 && this.dataSource.paginator) {
      return [20, 50, 100,  this.dataSource.paginator.length];
    } else {
      return [20, 50, 100];
    }
  }
  displayPaginatorAllOption() {
    const matOptions = document.getElementsByClassName('mat-option-text');
    // @ts-ignore
    for (const options of matOptions) {
      options.innerHTML = options.innerHTML == this.dataSource.data.length ? 'Tous' : options.innerHTML;
    }
  }
  pageChange() {
    localStorage.setItem('pageIndexDealershipDora', String(this.paginator.pageIndex));
    localStorage.setItem('pageSizeDealershipDora', String(this.paginator.pageSize));
  }
}
