import {Component, OnInit, ViewChild} from '@angular/core';
import {DataFormationService} from '../utils/data-services/dataFormation.service';
import {MatTableDataSource} from '@angular/material';
import swal from "sweetalert2";
import {Router} from '@angular/router';
import {environment} from '../../environments/environment';
import {MatPaginator} from "@angular/material/paginator";

@Component({
  selector: 'app-gestion-formation',
  templateUrl: './gestion-formation.component.html',
  styleUrls: ['./gestion-formation.component.scss']
})
export class GestionFormationComponent implements OnInit {
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  displayedColumns: string[] = [
    'type',
    'title',
    'isActive',
    'edit',
    'delete'
  ];
  isDataSourceExists = false;
  dataSource = new MatTableDataSource<any>();
  urlSoftgate = environment.softgateFrontUrl;
  tokenBooklet = environment.tokenBooklet;
  search: any;

  constructor(
    private formationService: DataFormationService,
    private route: Router
  ) { }

  ngOnInit() {
    this.formationService.getFormation().subscribe(formations => {
      this.dataSource.data = formations;
      this.isDataSourceExists = formations.length !== 0;
      this.search = localStorage.getItem('formationFilter') != null ? localStorage.getItem('formationFilter') : '';
      this.dataSource.filter = this.search.trim().toLowerCase();
      this.paginator.pageIndex = localStorage.getItem('pageIndexFormation') != null ? parseInt(localStorage.getItem('pageIndexFormation')) : null;
      this.paginator.pageSize = localStorage.getItem('pageSizeFormation') != null ? parseInt(localStorage.getItem('pageSizeFormation')) : null;
      this.dataSource.paginator = this.paginator;
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    localStorage.setItem('formationFilter', filterValue);
    this.dataSource.filter = filterValue.trim();
  }
  editFormation(id) {
    this.route.navigate(['/modification-formation/' + id]);
  }
  onDelete(id) {
    swal.fire({
      title: 'Attention!',
      text: 'Êtes-vous sûr de vouloir supprimer?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'Annuler'
    }).then((result) => {
      if (result.value) {
        this.formationService.deleteFormation(id).subscribe(data => {
            swal.fire('La formation a bien été supprimée!', '', 'success').then(res => {
              window.location.reload();
            });
        }, error => {
          swal.fire({
            title: 'Erreur',
            text: 'Un problème est survenu lors de la suppression.',
            type: 'error',
            confirmButtonText: 'Ok'
          });
        });
      }
    });
  }
  copyToClipboard(url): void {
    navigator.clipboard.writeText(url);
  }
  getPageSizeOptions(): number[] {
    if (this.dataSource && this.dataSource.data.length > 100 && this.dataSource.paginator) {
      return [20, 50, 100,  this.dataSource.paginator.length];
    } else {
      return [20, 50, 100];
    }
  }
  displayPaginatorAllOption() {
    const matOptions = document.getElementsByClassName('mat-option-text');
    // @ts-ignore
    for (const options of matOptions) {
      options.innerHTML = options.innerHTML == this.dataSource.data.length ? 'Tous' : options.innerHTML;
    }
  }
  pageChange() {
    localStorage.setItem('pageIndexFormation', String(this.paginator.pageIndex));
    localStorage.setItem('pageSizeFormation', String(this.paginator.pageSize));
  }
}
