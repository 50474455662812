<h1 class="text-center mt-2">Gestion des concessions</h1>
<div>
  <div class="ml-4">
    <button (click)="SelectChangeHandlerCreer()" id="btn-add-concession" class="mr-5 btn btn-dark">
      <mat-icon>add_circle</mat-icon> Concession
    </button>
  </div>
  <div class="mt-5 mx-4 d-flex justify-content-between align-items-start">
    <div class="d-flex">
      <mat-form-field class="view-filter mr-2 w-auto" style="height: 45px">
      <mat-label>Groupe</mat-label>
      <mat-select [(ngModel)]="selectedGroup" (click)="focusSearch('section')" (selectionChange)="filterDealership()"  appearance="fill" [compareWith]="comparer">
        <mat-option value="all">Tous</mat-option>
        <input id="select-search-section" class="form-control select-input custom-search-input" (keyup)="filterGroup($event)" type="text" placeholder="Recherche">
        <mat-option *ngFor="let group of groupsArray.filtered"  [value]="group.groupe_id">
          {{group.nom}}
        </mat-option>
      </mat-select>
    </mat-form-field>
      <mat-form-field class="view-filter mr-2 w-auto" style="height: 45px">
        <mat-label>Type</mat-label>
        <mat-select [(ngModel)]="selectedType" (selectionChange)="filterDealership()"  appearance="fill" [compareWith]="comparer">
          <mat-option value="all">Tous</mat-option>
          <mat-option *ngFor="let type of typesArray"  [value]="type.id">
            {{type.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="view-filter mr-2 w-auto" style="height: 45px">
        <mat-label>Combine</mat-label>
        <mat-select [(ngModel)]="combineDealership" (selectionChange)="filterDealership()"  appearance="fill" multiple>
          <mat-option value="master">Oui (mères)</mat-option>
          <mat-option value="child">Oui (enfants)</mat-option>
          <mat-option value="not">Non</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="view-filter mr-2 w-auto" style="height: 45px">
        <mat-label>Actif</mat-label>
        <mat-select [(ngModel)]="activeDealership" (selectionChange)="filterDealership()"  appearance="fill" multiple>
          <mat-option [value]="true">Oui</mat-option>
          <mat-option [value]="false">Non</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <mat-form-field>
      <mat-label>Cherchez une concession</mat-label>
      <input [(ngModel)]="search" matInput (keyup)="applyFilter($event)">
    </mat-form-field>
  </div>
</div>

<div class="example-container mx-4 mt-3">
  <div class="d-flex justify-content-between align-items-end pb-4">
    <div class="d-flex">
      <div>Certification utilisateurs non-effectuée (dépassée) : <span class="text-danger font-weight-bold">{{ this.certification.undone }}</span></div>
      <div class="mx-2">|</div>
      <div>non-effectuée (en cours) : <span class="text-warning font-weight-bold">{{ this.certification.waiting }}</span></div>
      <div class="mx-2">|</div>
      <div>effectuée : <span class="text-success font-weight-bold">{{ this.certification.done }}</span></div>
    </div>
    <div>
      <mat-form-field class="view-filter w-auto" style="height: 45px">
        <mat-label>Statut certification</mat-label>
        <mat-select #certificationFilterValue [value]="selectedCertification" (selectionChange)="filterCertification(certificationFilterValue.value)"  appearance="fill" >
          <mat-option value="all">Tous</mat-option>
          <mat-option value="undone">Dépassée</mat-option>
          <mat-option value="waiting">En cours</mat-option>
          <mat-option value="done">Effectuée</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

    <ng-container matColumnDef="concession_id">
      <th mat-header-cell *matHeaderCellDef style="width: 50px" class="pointer" (click)="sortId()"> ID </th>
      <td mat-cell *matCellDef="let element"> {{element.concession_id}} </td>
    </ng-container>

    <ng-container matColumnDef="certification">
      <th mat-header-cell *matHeaderCellDef style="width: 30px"> </th>
      <td mat-cell *matCellDef="let element">
        <mat-icon matTooltip="Utilisateurs certifiés depuis moins d'un an" *ngIf="element.usersCertified === 1" style="font-size: 17px; color: green;">check_circle_outline</mat-icon>
        <mat-icon matTooltip="Certification des utilisateurs à effetcuer par le responsable IAM de la concession" *ngIf="element.usersCertified === 0 && element.last_user_certification_at" style="font-size: 17px; color: orange;">report</mat-icon>
        <mat-icon matTooltip="Aucune date de dernière certification des utilisateurs enregistrée" *ngIf="element.usersCertified === 0 && !element.last_user_certification_at" style="font-size: 17px; color: orange;">report</mat-icon>
        <mat-icon matTooltip="Certification des utilisateurs non effectuée, utilisateurs désativés" *ngIf="element.usersCertified === -1" style="font-size: 17px; color: red;">highlight_off</mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="nom_concession">
      <th mat-header-cell *matHeaderCellDef class="pointer" (click)="sortName()"> Nom </th>
      <td mat-cell *matCellDef="let element">
        {{element.nom_concession}}
      </td>
    </ng-container>

    <ng-container matColumnDef="raison_sociale">
      <th mat-header-cell *matHeaderCellDef  style="width: 150px"> Raison sociale </th>
      <td mat-cell *matCellDef="let element"> {{element.raison_sociale}} </td>
    </ng-container>

    <ng-container matColumnDef="group">
      <th mat-header-cell *matHeaderCellDef style="width: 150px"> Groupe </th>
      <td mat-cell *matCellDef="let element"> {{element.group}} </td>
    </ng-container>

    <ng-container matColumnDef="ville">
      <th mat-header-cell *matHeaderCellDef> Ville </th>
      <td mat-cell *matCellDef="let element"> {{element.ville}} </td>
    </ng-container>

    <ng-container matColumnDef="ville_principale">
      <th mat-header-cell *matHeaderCellDef> Ville Principale </th>
      <td mat-cell *matCellDef="let element"> {{element.ville_principale}} </td>
    </ng-container>

    <ng-container matColumnDef="code_ej">
      <th mat-header-cell *matHeaderCellDef style="width: 50px"> Code EJ/ Outlet </th>
      <td mat-cell *matCellDef="let element">
        <span *ngIf="element.code_ej">{{element.code_ej+'_'+element.outlet}}</span>
        <span *ngIf="!element.code_ej">-</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="code_site">
      <th mat-header-cell *matHeaderCellDef style="width: 50px"> Code Site </th>
      <td mat-cell *matCellDef="let element"> {{element.code_site}} </td>
    </ng-container>

    <ng-container matColumnDef="is_active">
      <th mat-header-cell *matHeaderCellDef style="width: 50px">Active</th>
      <td mat-cell *matCellDef="let element">
        <mat-icon *ngIf="element.is_active" class="text-success active-sms">check</mat-icon>
        <mat-icon *ngIf="!element.is_active" class="text-danger inactive-sms">close</mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="is_combine">
      <th mat-header-cell *matHeaderCellDef style="width: 50px">Combine</th>
      <td mat-cell *matCellDef="let element">
        <mat-icon *ngIf="element.is_combine && element.is_combine_master">supervisor_account</mat-icon>
        <mat-icon *ngIf="element.is_combine && !element.is_combine_master">child_care</mat-icon>
        <mat-icon *ngIf="!element.is_combine" class="text-danger inactive-sms">close</mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="modifier">
      <th mat-header-cell *matHeaderCellDef> Modifier </th>
      <td mat-cell *matCellDef="let element">
        <button (click)="SelectChangeHandlerModifier(element.concession_id)" class="m-2 btn btn-round-40 btn-dark">
          <mat-icon>edit</mat-icon>
        </button>
      </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <mat-paginator [pageSizeOptions]="getPageSizeOptions()" (click)="displayPaginatorAllOption()" (page)="pageChange()" showFirstLastButtons aria-label="Sélectionner la page"></mat-paginator>
</div>
